import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, debounceTime, map, of, take } from 'rxjs';
import { formatPaginationMetadata } from 'src/app/core/helper/formatPaginationMetadata.helper';
import {
  EntityContact,
  EntityContactFormArraysTypes,
} from 'src/app/core/types/EntityContact.type';
import ResponseData from 'src/app/core/types/shared/Response.type';
import { environment } from 'src/environments/environment';
import {
  formatEntityContactData,
  formatEntityContactServicesData,
} from '../helper/formatEntityContactData.helper';

@Injectable()
export class EntityContactService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly baseApiUrl = environment.baseApiUrl;

  getEntityContacts(
    pageNumber: number = 1,
    entityId: string
  ): Observable<ResponseData<EntityContact>> {
    return this.http
      .get<
        ResponseData<EntityContact>
      >(`${this.baseApiUrl}entity-contacts/all/${entityId}`)
      .pipe(
        take(1),
        map(({ data, links, meta }) => ({
          data: data.map((entityContact: unknown) =>
            formatEntityContactData(entityContact)
          ),
          links,
          meta: formatPaginationMetadata(meta),
        }))
      );
  }

  getEntityContactById(
    entityContactId: number | string
  ): Observable<EntityContact> {
    return this._fillEntityContactById(entityContactId).pipe(
      take(1),
      map(({ data }) => formatEntityContactData(data))
    );
  }

  addEntityContact(entityContact: unknown): Observable<unknown> {
    return this.http
      .post<any>(`${this.baseApiUrl}entity-contacts`, entityContact)
      .pipe(debounceTime(300), take(1));
  }

  updateEntityContact(entityContact: any): Observable<unknown> {
    return this.http
      .put<any>(
        `${this.baseApiUrl}entity-contacts/${entityContact?.id}`,
        entityContact
      )
      .pipe(debounceTime(300), take(1));
  }

  deleteEntityContact(entityContactId: number): Observable<unknown> {
    return this.http
      .delete<any>(`${this.baseApiUrl}entity-contacts/${entityContactId}`)
      .pipe(debounceTime(300), take(1));
  }

  addEntityContactService(
    entityContactServiceType: EntityContactFormArraysTypes,
    entityContactService: unknown
  ): Observable<unknown> {
    if (!entityContactServiceType) return of();

    return this.http
      .post<any>(
        `${this.baseApiUrl}entity-contacts/${entityContactServiceType}`,
        entityContactService
      )
      .pipe(debounceTime(300), take(1));
  }

  getEntityContactServices(entityContactId: string | number): Observable<any> {
    return this._fillEntityContactById(entityContactId).pipe(
      map(({ data }) => {
        const { emails, phones, sms } = data;

        return {
          emails: emails?.map(email =>
            formatEntityContactServicesData('emails', email)
          ),
          phones: phones?.map(phone =>
            formatEntityContactServicesData('phones', phone)
          ),
          sms: sms?.map(sms => formatEntityContactServicesData('sms', sms)),
        };
      })
    );
  }

  deleteEntityContactService(
    entityContactServiceType: EntityContactFormArraysTypes,
    entityContacServicetId: number
  ): Observable<unknown> {
    return this.http
      .delete<any>(
        `${this.baseApiUrl}entity-contacts/${entityContactServiceType}/${entityContacServicetId}`
      )
      .pipe(debounceTime(300), take(1));
  }

  updateEntityContactService(
    entityContactServiceType: EntityContactFormArraysTypes,
    entityContactService: any
  ): Observable<unknown> {
    if (!entityContactServiceType) return of();

    return this.http
      .put<any>(
        `${this.baseApiUrl}entity-contacts/${entityContactServiceType}/${entityContactService?.id}`,
        entityContactService
      )
      .pipe(debounceTime(300), take(1));
  }

  getExistingContacts(): Observable<ResponseData<EntityContact>['data']> {
    return this.http
      .get<{
        data: ResponseData<EntityContact>['data'];
      }>(`${this.baseApiUrl}entity-contacts`)
      .pipe(
        take(1),
        map(({ data }) => {
          return data.map((entityContact: unknown) =>
            formatEntityContactData(entityContact)
          );
        })
      );
  }

  addExistingContact(existingContact: {
    gr_entity_contact_id: number;
    gr_entity_id: string;
  }): Observable<unknown> {
    return this.http
      .post<any>(`${this.baseApiUrl}entity-contacts/existing`, existingContact)
      .pipe(debounceTime(300), take(1));
  }

  private _fillEntityContactById(
    entityContactId: number | string
  ): Observable<{ data: EntityContact }> {
    if (!entityContactId) throw new Error('Empty entity contact id');

    return this.http.get<{ data: EntityContact }>(
      `${this.baseApiUrl}entity-contacts/${entityContactId}`
    );
  }
}
