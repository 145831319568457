export function formatedDate(inputDateString: string): string {
  const inputDate: Date = new Date(inputDateString);

  if (isNaN(inputDate.getTime())) {
    return '';
  }

  const day: number = inputDate.getUTCDate();
  const month: number = inputDate.getUTCMonth() + 1;
  const year: number = inputDate.getUTCFullYear();
  const formattedDate: string = `${month}/${day}/${year}`;

  return formattedDate;
}
