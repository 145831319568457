import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/auth/authenticate.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  public settingsButton: boolean = false;
  public securityButton: boolean = false;
  public reportButton: boolean = false;
  public openProfile: boolean = false;
  public firstName: string = '';
  public lastName: string = '';
  activeNavItem: string = '';

  constructor(
    protected authenticateService: AuthenticateService,
    private router: Router
  ) {}

  public navigationValues = [
    {
      value: 'Dashboard',
      icon: 'M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25',
    },
    {
      value: 'Entities',
      icon: 'M19 21V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V21M19 21L21 21M19 21H14M5 21L3 21M5 21H10M9 6.99998H10M9 11H10M14 6.99998H15M14 11H15M10 21V16C10 15.4477 10.4477 15 11 15H13C13.5523 15 14 15.4477 14 16V21M10 21H14',
    },
    // {
    //   value: 'Messages',
    //   icon: 'M8 12H8.01M12 12H12.01M16 12H16.01M21 12C21 16.4183 16.9706 20 12 20C10.4607 20 9.01172 19.6565 7.74467 19.0511L3 20L4.39499 16.28C3.51156 15.0423 3 13.5743 3 12C3 7.58172 7.02944 4 12 4C16.9706 4 21 7.58172 21 12Z',
    // },
    // {
    //   value: 'Tasks',
    //   icon: 'M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H15M9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5M12 12H15M12 16H15M9 12H9.01M9 16H9.01',
    // },
    {
      value: 'Reports',
      icon: 'M11 3.05469C6.50005 3.55213 3 7.3672 3 11.9998C3 16.9703 7.02944 20.9998 12 20.9998C16.6326 20.9998 20.4476 17.4997 20.9451 12.9998H11V3.05469Z',
      icon_2:
        'M20.4878 8.99976H15V3.51206C17.5572 4.41589 19.5839 6.4426 20.4878 8.99976Z',
    },
    {
      value: 'Billing',
      icon: 'M3 10H21M7 15H8M12 15H13M6 19H18C19.6569 19 21 17.6569 21 16V8C21 6.34315 19.6569 5 18 5H6C4.34315 5 3 6.34315 3 8V16C3 17.6569 4.34315 19 6 19Z',
    },
  ];

  public settingsNavigationValues = [
    'General',
    'Custom Fields',
    'Entity Types',
    'Services',
  ];

  public securityNavigationValues = [
    'Users',
    'Roles & Privileges',
    'IP Firewall',
    // 'Shared Uploads',
    // 'Resources',
    'Root User',
  ];

  reportsNavigationValues = [
    'Services',
    // 'Service Notifications',
    // 'Time Tracker',
  ];

  ngOnInit(): void {
    this.authenticateService.getUserData().subscribe({
      next: response => {
        this.firstName = response.first_name;
        this.lastName = response.last_name;
      },
      error: error => {},
    });
  }

  public logoutUser() {
    this.authenticateService.logout().subscribe({
      next: () => {
        localStorage.removeItem('token');
        this.router.navigate(['/login']);
      },
      error: error => {},
    });
  }

  setActiveNavItem(navItem: string) {
    this.activeNavItem = navItem;
  }
}
