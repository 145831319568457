import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/components/shared/toaster/toaster-item/toaster.service';
import { PERIODICITY } from 'src/app/core/enums/Periodicity.enum';
import { EntityService } from 'src/app/core/types/EntityService.type';
import { Periodicity } from 'src/app/core/types/shared/Periodicity.type';
import { ServicesService } from '../add-entity-sevice/data-access/entity-service.service';

@Component({
  selector: 'app-entity-service-billings-and-fees',
  templateUrl: './entity-service-billings-and-fees.component.html',
})
export class EntityServiceBillingsAndFeesComponent implements OnInit {
  private readonly _servicesService: ServicesService = inject(ServicesService);
  private readonly _router: Router = inject(Router);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);
  private readonly _toasterService: ToasterService = inject(ToasterService);

  billingAndFeesForm!: FormGroup;
  isEntityServiceLoaded: boolean = false;
  periodicities: Periodicity[] = Object.values(PERIODICITY);

  ngOnInit(): void {
    this._initBillingAndFeesForm();

    this._getEntityService().subscribe({
      next: entityService => {
        this._fillBillingAndFeesForm(entityService);
      },
    });
  }

  editBillingAndFees() {
    const billingAndFeesData = this.billingAndFeesForm.value;

    this._servicesService.updateEntityService(billingAndFeesData).subscribe({
      next: () => {
        this._toasterService.show(
          'success',
          'Billing And Fees Updated',
          [],
          5000
        );
      },
    });
  }

  private _getEntityService() {
    const entityServiceId: number = +(this._router.url.split('/')?.at(-2) ?? 0);

    return this._servicesService
      .getEntityServiceById(entityServiceId)
      .pipe(takeUntilDestroyed(this._destroyRef));
  }

  private _initBillingAndFeesForm() {
    this.billingAndFeesForm = new FormGroup({
      id: new FormControl(),
      fee: new FormControl(null, Validators.required),
      description: new FormControl(),
      disclosure: new FormControl(),
      billingPeriodicity: new FormControl(),
    });
  }

  private _fillBillingAndFeesForm(entityService: EntityService) {
    Object.keys(this.billingAndFeesForm.value).forEach(key => {
      this.billingAndFeesForm
        ?.get(key)
        ?.setValue(entityService[key as keyof EntityService]);
    });

    this.isEntityServiceLoaded = true;
  }

  get serviceDetailsControls(): {
    description: FormControl;
    disclosure: FormControl;
    fee: FormControl;
  } {
    const descriptionControl: FormControl = this.billingAndFeesForm.get(
      'description'
    ) as FormControl;
    const disclosureControl: FormControl = this.billingAndFeesForm.get(
      'disclosure'
    ) as FormControl;
    const feeControl: FormControl = this.billingAndFeesForm.get(
      'fee'
    ) as FormControl;

    return {
      description: descriptionControl,
      disclosure: disclosureControl,
      fee: feeControl,
    };
  }

  get billingPeriodicityControl(): FormControl {
    return this.billingAndFeesForm.get('billingPeriodicity') as FormControl;
  }
}
