import { EntityService, Service } from 'src/app/core/types/EntityService.type';

export function formatEntityServieData({
  name,
  service_id,
  entity_id,
  service_periodicity,
  billing_periodicity,
  start_date,
  end_date,
  service_logs,
  default_disclosure,
  default_fee,
  task_order,
}: any): Partial<EntityService> {
  return {
    serviceId: service_id,
    entityId: entity_id,
    servicePeriodicity: service_periodicity,
    billingPeriodicity: billing_periodicity,
    startDate: start_date || '--',
    endDate: end_date || '--',
    serviceLogs: service_logs,
    defaultDisclosure: default_disclosure,
    defaultFee: default_fee,
    taskOrder: task_order,
    serviceName: name,
  };
}

export function formatServieData({
  created_at,
  updated_at,
  updated_by,
  default_disclosure,
  default_fee,
  default_fee_type,
  description,
  id,
  name,
  status,
  task_order,
  tasks,
}: any): Service {
  return {
    createdAt: created_at,
    updatedAt: updated_at,
    updatedBy: updated_by,
    defaultDisclosure: default_disclosure,
    defaultFee: default_fee,
    defaultFeeType: default_fee_type,
    description: description,
    id,
    name,
    status,
    taskOrder: task_order,
    tasks,
  };
}
