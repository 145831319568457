import { inject } from '@angular/core';
import { BehaviorSubject, Observable, finalize, forkJoin, of } from 'rxjs';
import { MAX_FILE_SIZE } from 'src/app/core/enums/Files.enum';
import { MODAL_STATUS } from 'src/app/core/enums/Modal.enum';
import { generateDownloadURL } from 'src/app/core/helper/downloadFile.helper';
import { getBase64, isImageFileValid } from 'src/app/core/helper/files.helper';
import { ModalStatus } from 'src/app/core/types/Modal.type';
import { EntitiesService } from 'src/app/structure/entities/entities.service';
import { PAGE_TYPE } from '../enums/PageType.enum';
import { FileRecord } from '../types/FileRecord.type';
import { PageType } from '../types/PageType';

export class FileManagement {
  protected readonly _entitiesService: EntitiesService =
    inject(EntitiesService);

  uploadModalStatus: ModalStatus = MODAL_STATUS.CLOSE;
  headerTitles: string[] = [
    'DOCUMENT NAME',
    'VISIBLE TO CLIENTS',
    'ATTACH TO EMAILS',
  ];
  files!: any[];
  hasLoading: boolean = false;
  uploadModalTitle!: string;
  deleteModalStatus: ModalStatus = MODAL_STATUS.CLOSE;
  fileToDelete!: number | null;
  deleteModalTitle!: string;
  protected readonly MAX_FILE_SIZE: number = MAX_FILE_SIZE;
  protected _selectedItem!: FileRecord | null;
  pageType: PageType = PAGE_TYPE.PROFILE;

  onFileSelected(event: any): void {
    const files: any = event.target.files;
    const filePayload$: BehaviorSubject<any> = new BehaviorSubject([]);

    const observables = Object.values<File>(files).map((file: File) => {
      const { size: fileSize, type, name: fileName } = file;

      if (!isImageFileValid(file, this.MAX_FILE_SIZE, false)) return of();

      return new Observable(observer => {
        const handleFileAddAndPreview: Function = (result: any) => {
          const fileType =
            fileName.includes('qbw') || fileName.includes('QBW') ? 'qbw' : type;
          const fileData = {
            name: fileName,
            type: fileType,
            size: fileSize,
            content: result,
          };

          const currentFiles = filePayload$.getValue() || [];
          filePayload$.next([...currentFiles, fileData]);

          observer.next();
          observer.complete();
        };

        getBase64(file, handleFileAddAndPreview);
      });
    });

    forkJoin(observables)
      .pipe(
        finalize(() => {
          this.hasLoading = true;

          this._completeUploadAndRefresh(
            this._uploadFiles(filePayload$.getValue())
          );
        })
      )
      .subscribe();

    const inputFileElement = event.target as HTMLInputElement;
    inputFileElement.value = '';
  }

  updateFileStatus(event: {
    file: any;
    status: boolean;
    toggleType: 'visible' | 'attach';
  }): void {
    const statusKey =
      event.toggleType === 'visible' ? 'visibleToClient' : 'attachByDefault';

    this._completeUploadAndRefresh(
      this._entitiesService.updateFileData(this.pageType, event.file.id, {
        [statusKey]: event.status ? 1 : 0,
      })
    );
  }

  deleteFile(): void {
    if (!this.fileToDelete) return;

    this.hasLoading = true;
    this._completeUploadAndRefresh(
      this._entitiesService.deleteUploadedFile(this.fileToDelete)
    );
  }

  downloadFile(fileInfo: Parameters<typeof generateDownloadURL>[0]): void {
    generateDownloadURL(fileInfo);
  }

  openDeleteModal({
    fileId,
    fileName,
  }: {
    fileId: number;
    fileName: string;
  }): void {
    this.deleteModalStatus = MODAL_STATUS.OPEN;
    this.fileToDelete = fileId;
    this.deleteModalTitle = 'Delete ' + fileName;
  }

  cancel(event: boolean, isDelete: boolean = false): void {
    if (!event) return;

    if (isDelete) {
      this.fileToDelete = null;
      this.deleteModalStatus = MODAL_STATUS.CLOSE;
    } else {
      this.uploadModalTitle = '';
      this.uploadModalStatus = MODAL_STATUS.CLOSE;
    }
  }

  openUploadModel(selectedItem: any): void {
    this._selectedItem = {
      property_id: selectedItem.id,
      zone_id: selectedItem.zone_id || selectedItem.zoneId,
      uploader_id:
        (selectedItem.uploader_id || selectedItem.uploaderId) &&
        String(selectedItem.uploader_id || selectedItem.uploaderId),
      shared_upload: selectedItem.shared_upload ?? selectedItem.sharedUpload,
      property_entity: selectedItem.property_entity,
      month: selectedItem.month,
      quarter: selectedItem.quarter,
      year: selectedItem.year,
    };

    this.files = selectedItem.gr_uploads;
    this.uploadModalTitle = selectedItem.name;
    this.uploadModalStatus = MODAL_STATUS.OPEN;
  }

  protected _uploadFiles(files: File[]): Observable<any> {
    if (!this._selectedItem) return of();

    return this._entitiesService.uploadFiles(this.pageType, {
      files,
      ...this._selectedItem,
    });
  }

  protected _completeUploadAndRefresh(observable$: Observable<any>): void {
    return;
  }
}
