import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appStatus]',
})
export class StatusDirective implements OnInit {
  private _elementRef: ElementRef = inject(ElementRef);
  private _renderer: Renderer2 = inject(Renderer2);

  @Input() appStatus!: string;

  ngOnInit() {
    this.updateBackgroundColor();
  }

  private updateBackgroundColor() {
    if (
      !this.appStatus ||
      (this.appStatus !== 'active' && String(this.appStatus) !== 'inactive')
    )
      return;

    const colorClass =
      this.appStatus === 'active' ? 'bg-green-100' : 'bg-red-100';

    this._renderer.addClass(this._elementRef.nativeElement, colorClass);
  }
}
