import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { AuthenticateService } from 'src/app/auth/authenticate.service';
import { MODAL_STATUS } from 'src/app/core/enums/Modal.enum';
import {
  EntityNote,
  EntityNoteAction,
} from 'src/app/core/types/EntityNote.type';
import { ModalStatus } from 'src/app/core/types/Modal.type';
import { EntityNotesService } from './data-access/entity-notes.service';

@Component({
  selector: 'entity-notes',
  templateUrl: './entity-notes.component.html',
  styleUrls: ['./entity-notes.component.css'],
})
export class EntityNotesComponent implements OnInit {
  private readonly _router: Router = inject(Router);
  private readonly _entityNotesService: EntityNotesService =
    inject(EntityNotesService);
  private readonly _authenticateService: AuthenticateService =
    inject(AuthenticateService);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);

  entityNotes!: EntityNote[];
  entityNotesFormGroup!: FormGroup;
  noteModalStatus: ModalStatus = MODAL_STATUS.CLOSE;
  private readonly _entityId: string = this._router.url.split('/')[3];
  connectedUser: any;
  isEditMode: boolean = false;
  selectedEntityNote!: EntityNote;
  deleteEntityNoteModalStatus: ModalStatus = MODAL_STATUS.CLOSE;
  filteredEntityNotes!: EntityNote[];
  searchKeys = [
    { key: 'content' },
    { key: 'added_by.first_name' },
    { key: 'added_by.last_name' },
  ];

  ngOnInit(): void {
    this._initEntityNotesFormGroup();
    this._getEntityNotes().subscribe({
      next: entityNotes => {
        this.entityNotes = entityNotes;
        this.filteredEntityNotes = entityNotes;
      },
    });
    this._getConnectedUser().subscribe({
      next: connectedUser => {
        this.connectedUser = connectedUser;
      },
    });
  }

  openNoteModal() {
    this.noteModalStatus = MODAL_STATUS.OPEN;
  }

  saveNote() {
    const { content } = this.entityNotesFormGroup.value;

    let payload = {
      content,
      gr_entity_id: this._entityId,
    };
    let ection$: Observable<unknown>;

    if (this.isEditMode) {
      ection$ = this._entityNotesService.editEntityNote({
        noteId: this.selectedEntityNote.id,
        ...payload,
      });
    } else {
      ection$ = this._entityNotesService.addEntityNote(payload);
    }

    ection$.pipe(switchMap(() => this._getEntityNotes())).subscribe({
      next: entityNotes => {
        this.entityNotes = entityNotes;
        this.filteredEntityNotes = entityNotes;
        this._cleanAddNoteModal();
        this.isEditMode = false;
      },
    });
  }

  cancel(isDeleteMode: boolean = false) {
    if (isDeleteMode) {
      this.deleteEntityNoteModalStatus = MODAL_STATUS.CLOSE;
    }
    this.noteModalStatus = MODAL_STATUS.CLOSE;
  }

  handleActions(action: EntityNoteAction) {
    if (!action) return;

    this.selectedEntityNote = action.data;

    if (action.name === 'delete') {
      this._openDeleteEntityNoteModal();
    }

    if (action.name === 'edit') {
      this.isEditMode = true;
      this.entityNotesFormGroup
        .get('content')
        ?.setValue(this.selectedEntityNote.content);
      this.openNoteModal();
    }
  }

  deleteEntityNote() {
    this._entityNotesService
      .deleteEntityNote(this.selectedEntityNote.id)
      .pipe(switchMap(() => this._getEntityNotes()))
      .subscribe({
        next: entityNotes => {
          this.entityNotes = entityNotes;
          this.filteredEntityNotes = entityNotes;
          this._cleanAddNoteModal(true);
        },
      });
  }

  setFilteredEntityNotes(filteredEntityNotes: EntityNote[]) {
    this.filteredEntityNotes = filteredEntityNotes;
  }

  private _initEntityNotesFormGroup() {
    this.entityNotesFormGroup = new FormGroup({
      content: new FormControl(null, Validators.required),
    });
  }

  private _cleanAddNoteModal(isDeleteMode: boolean = false) {
    this.cancel(isDeleteMode);
    this.entityNotesFormGroup.reset();
  }

  private _getEntityNotes() {
    return this._entityNotesService
      .getEntityNotes(this._entityId)
      .pipe(takeUntilDestroyed(this._destroyRef));
  }

  private _getConnectedUser() {
    return this._authenticateService
      .getUserData()
      .pipe(takeUntilDestroyed(this._destroyRef));
  }

  private _openDeleteEntityNoteModal() {
    this.deleteEntityNoteModalStatus = MODAL_STATUS.OPEN;
  }
}
