import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { map, switchMap, tap } from 'rxjs';
import {
  EntityService,
  ServiceTask,
} from 'src/app/core/types/EntityService.type';
import { EntitiesService } from '../../../entities.service';
import { ServicesService } from '../add-entity-sevice/data-access/entity-service.service';
import { getSelectedServiceTasks } from '../add-entity-sevice/helper/getSelectedServiceTasks';

@Component({
  selector: 'app-entity-service-settings',
  templateUrl: './entity-service-settings.component.html',
})
export class EntityServiceSettingsComponent implements OnInit {
  private readonly _servicesService: ServicesService = inject(ServicesService);
  private readonly _entitiesService: EntitiesService = inject(EntitiesService);
  private readonly _router: Router = inject(Router);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);

  columnsToDisplay = [{ key: 'name', value: 'Name' }];
  serviceTasks!: ServiceTask[];
  entityService!: Partial<EntityService> | undefined;
  stopEntityServiceModalOpen: boolean = false;
  endDateForm!: FormGroup;
  isNonActiveEntityService: boolean = false;
  deleteEntityServiceModalOpen: boolean = false;
  addEntityServiceTaskModalOpen: boolean = false;
  selectedEntityServiceTaskId!: number | null;
  entityServiceTasksForm!: FormGroup;
  tasks!: ServiceTask[];

  ngOnInit(): void {
    this._getEntityService().subscribe();
  }

  onDeleteEntityServiceTask(entityServiceTask: ServiceTask) {
    this.deleteEntityServiceModalOpen = true;
    this.selectedEntityServiceTaskId = entityServiceTask.id;
  }

  deleteEntityServiceTask(event: boolean) {
    if (!this.selectedEntityServiceTaskId) return;

    this._entitiesService
      .deleteEntityServiceTask(
        this.selectedEntityServiceTaskId,
        this.entityService?.id
      )
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        switchMap(() => {
          return this._getEntityService();
        })
      )
      .subscribe({
        next: entityService => {
          this.selectedEntityServiceTaskId = null;
          this.deleteEntityServiceModalOpen = false;
          this.entityService = entityService;
          this.serviceTasks = entityService.tasks;
        },
      });
  }

  onStopEntityService() {
    this._initServicesForm();
    this.stopEntityServiceModalOpen = true;
  }

  stopEntityService() {
    const endDate: Date = this.endDateFormControl.value;

    return this._entitiesService
      .stopEntityService(this.entityService?.id ?? 0, { end_date: endDate })

      .pipe(
        takeUntilDestroyed(this._destroyRef),
        switchMap(() => {
          return this._getEntityService();
        })
      )
      .subscribe({
        next: entityService => {
          this.entityService = entityService;
          this.serviceTasks = entityService.tasks;
          this.isNonActiveEntityService = true;
          this.stopEntityServiceModalOpen = false;
        },
      });
  }

  onAddEntityServiceTask(entityService: Partial<EntityService>) {
    if (!entityService.serviceId) return;

    this._initServiceTasksForm();
    this._getServiceById(entityService.serviceId)
      .pipe(
        map(service => {
          const selectedServiceIds = new Set(
            this.serviceTasks.map(serviceTask => serviceTask.id)
          );
          return service.tasks.filter(task => !selectedServiceIds.has(task.id));
        }),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(tasks => {
        this.tasks = tasks;
        this.tasksArrayForm.clear();

        tasks?.forEach((task: any) => {
          this.tasksArrayForm.push(
            new FormGroup({
              [task?.id]: new FormControl(false),
            })
          );
        });
        this.addEntityServiceTaskModalOpen = true;
      });
  }

  addEntityServiceTask() {
    if (!this.entityService?.id) return;

    return this._servicesService
      .addEntityServiceTask(this.entityService.id, {
        tasks: this.selectedServiceTasks,
      })
      .pipe(
        switchMap(this._getEntityService.bind(this)),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(() => {
        this.tasksArrayForm.clear();
        this.addEntityServiceTaskModalOpen = false;
      });
  }

  cancelAddServiceTask() {
    this.tasks = [];
    this.tasksArrayForm.clear();
    this.addEntityServiceTaskModalOpen = false;
  }

  private _getEntityService() {
    const entityServiceId: number = +(this._router.url.split('/')?.at(-2) ?? 0);

    return this._servicesService.getEntityServiceById(entityServiceId).pipe(
      tap(entityService => {
        if (entityService.endDate) {
          this.isNonActiveEntityService = true;
        }

        this.entityService = entityService;
        this.serviceTasks = entityService.tasks.sort((a, b) => b.id - a.id);
      }),
      takeUntilDestroyed(this._destroyRef)
    );
  }

  private _initServicesForm() {
    this.endDateForm = new FormGroup({
      endDate: new FormControl(null, Validators.required),
    });
  }

  private _initServiceTasksForm() {
    this.entityServiceTasksForm = new FormGroup({
      tasks: new FormArray([]),
    });
  }

  private _getServiceById(serviceId: number) {
    return this._servicesService.getServiceById(serviceId);
  }

  get endDateFormControl(): FormControl {
    return this.endDateForm.get('endDate') as FormControl;
  }

  get tasksArrayForm() {
    return this.entityServiceTasksForm.get('tasks') as FormArray;
  }

  get selectedServiceTasks(): number[] {
    return getSelectedServiceTasks.call(this, 'tasksArrayForm');
  }
}
