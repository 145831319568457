<div class="h-full relative">
  <page-header pageHeader="Entities"></page-header>
  <div class="flex justify-between">
    <tabs
      class="flex-1 pr-8 mt-2"
      (onTabSelect)="switchTab($event)"
      [tabs]="tabs"></tabs>
    <app-icon-button
      iconClass="icon-plus"
      title="Add Entity"
      (click)="isModalOpen = !isModalOpen"></app-icon-button>
  </div>
  <router-outlet></router-outlet>
</div>

<modal *ngIf="isModalOpen" [modalAreaWidth]="'550px'">
  <div>
    <form
      [formGroup]="entityForm"
      class="space-y-6 divide-y divide-gray-200"
      (submit)="createEntity()">
      <div class="space-y-6 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <div>
            <h1 class="text-lg font-medium leading-6 text-gray-900 pb-4">
              Create Entity
            </h1>
          </div>
          <div class="space-y-6 sm:space-y-3">
            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-3">
              <label
                for="name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >Entity Name</label
              >
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  formControlName="entity_name"
                  name="entity_name"
                  id="entity_name"
                  class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blueDS-500 focus:ring-blueDS-500 sm:text-sm" />
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-3">
              <label
                for="type"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >Entity Type</label
              >
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <select
                  id="type"
                  formControlName="gr_entity_type_id"
                  name="gr_entity_type_id"
                  class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-blueDS-500 focus:ring-blueDS-500 sm:text-sm">
                  <option
                    *ngFor="let entity of entityTypes"
                    [value]="entity.id">
                    {{ entity.name }}
                  </option>
                </select>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-3">
              <label
                for="status"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >Status</label
              >
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <fieldset class="mt-4">
                  <legend class="sr-only">Notification method</legend>
                  <div
                    class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    <div class="flex items-center">
                      <input
                        id="email"
                        formControlName="bpm_status"
                        name="bpm_status"
                        type="radio"
                        value="active"
                        checked
                        class="h-4 w-4 border-gray-300 text-blueDS-600 focus:ring-blueDS-500" />
                      <label
                        for="email"
                        class="ml-3 block text-sm font-medium text-gray-700"
                        >Active</label
                      >
                    </div>

                    <div class="flex items-center">
                      <input
                        id="sms"
                        formControlName="bpm_status"
                        name="bpm_status"
                        type="radio"
                        value="inactive"
                        class="h-4 w-4 border-gray-300 text-blueDS-600 focus:ring-blueDS-500" />
                      <label
                        for="sms"
                        class="ml-3 block text-sm font-medium text-gray-700"
                        >Inactive</label
                      >
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <button
            type="button"
            class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
            (click)="isModalOpen = false">
            Cancel
          </button>
          <button
            type="submit"
            [disabled]="entityForm.invalid"
            [class.cursor-not-allowed]="entityForm.invalid"
            class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blueDS-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blueDS-700 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2">
            Create Entity
          </button>
        </div>
      </div>
    </form>
  </div>
</modal>
