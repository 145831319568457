import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, switchMap } from 'rxjs';
import { StepsBarStep } from 'src/app/core/types/Stepper.type';
import { ServicesService } from './add-entity-sevice/data-access/entity-service.service';
import { getSelectedServiceTasks } from './add-entity-sevice/helper/getSelectedServiceTasks';

@Component({
  selector: 'entity-services',
  templateUrl: './entity-services.component.html',
})
export class EntityServicesComponent implements OnInit {
  private readonly _router: Router = inject(Router);
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly _servicesService: ServicesService = inject(ServicesService);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);

  public tabs = ['Ongoing', 'History'];
  public editServiceTabs = [
    'Service Log',
    'Service Settings',
    'Billings And Fees',
    'Service Properties',
  ];
  isAddServiceModalOpen!: boolean;
  counter: number = 1;
  steps: StepsBarStep[] = [
    { stepNumber: 1, title: 'Select', status: 'current' },
    { stepNumber: 2, title: 'Settings', status: 'next' },
    { stepNumber: 3, title: 'Frequency', status: 'next' },
    { stepNumber: 4, title: 'Details', status: 'next' },
    { stepNumber: 5, title: 'Date', status: 'next' },
  ];
  entityServiceForm!: FormGroup;
  private readonly _entityId: string = this._router.url.split('/')[3];
  private _selectedServiceId!: string;
  selectedServiceName!: any;

  ngOnInit(): void {
    this._initServicesForm();
    this._router.events
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(event => event instanceof NavigationEnd),
        switchMap((event: any) => {
          this._selectedServiceId = event.urlAfterRedirects.split('/').at(5);
          return this._servicesService.entityServices$;
        }),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(entityServices => {
        if (!entityServices) return;

        this.selectedServiceName = entityServices.find(
          entityService => entityService.id === +this._selectedServiceId
        )?.serviceName;
      });
  }

  public backToOngoingServices() {
    this._router.navigate([
      this._router.url.split('Services')[0] + 'Services/Ongoing',
    ]);
  }

  public switchTab(event: any) {
    this._router.navigate([event], { relativeTo: this._activatedRoute });
  }

  public switchServiceTab(event: any) {
    const baseUrl = this._router.url.split('/').slice(0, -1).join('/');
    this._router.navigate([baseUrl + '/' + event], {
      relativeTo: this._activatedRoute,
    });
  }

  addService() {
    this.isAddServiceModalOpen = true;
  }

  saveService(event: any) {
    if (event) {
      this._servicesService
        .saveService(this.formatedServiceData)
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe({
          next: () => {
            this._getEntityServices().subscribe();
            this._resetEntityServiceForm();
          },
        });
    }
  }

  cancel(event: any) {
    if (event) {
      this._resetEntityServiceForm();
    }
  }

  private _initServicesForm() {
    this.entityServiceForm = new FormGroup({
      service: new FormControl(null, Validators.required),
      description: new FormControl(null),
      disclosure: new FormControl(null),
      fee: new FormControl(null, [
        Validators.required,
        Validators.min(0),
        Validators.max(999999.99),
      ]),
      servicePeriodicity: new FormControl(null, Validators.required),
      billingPeriodicity: new FormControl(null, Validators.required),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      tasks: new FormArray([]),
    });
  }

  private _resetEntityServiceForm() {
    this.entityServiceForm.reset();
    this.isAddServiceModalOpen = false;
  }

  private _getEntityServices() {
    return this._servicesService
      .getAllEntityServices(this._entityId)
      .pipe(takeUntilDestroyed(this._destroyRef));
  }

  get isRouteServiceEdit() {
    const urlSegment = this._router.url
      .split('/')
      .pop()
      ?.replaceAll('%20', ' ');
    return urlSegment !== undefined
      ? this.editServiceTabs.includes(urlSegment)
      : false;
  }

  get formatedServiceData() {
    const {
      billingPeriodicity,
      servicePeriodicity,
      startDate,
      description,
      disclosure,
      fee,
      service,
    } = this.entityServiceForm.value;
    const entityId: string = this._router.url.split('/')[3];
    const formattedPeriodicity: (periodicity: string) => string = (
      periodicity: string
    ) => periodicity.toUpperCase().replaceAll(' ', '_');

    return {
      billing_periodicity: formattedPeriodicity(billingPeriodicity),
      service_periodicity: formattedPeriodicity(servicePeriodicity),
      start_date: startDate,
      description,
      disclosure,
      fee,
      service,
      tasks: this.selectedServiceTasks,
      entity: entityId,
    };
  }

  get tasksArrayForm() {
    return this.entityServiceForm.get('tasks') as FormArray;
  }

  get selectedServiceTasks(): number[] {
    return getSelectedServiceTasks.call(this, 'tasksArrayForm');
  }
}
