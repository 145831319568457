import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getYears } from 'src/app/core/helper/years.helper';
import { Month } from 'src/app/core/types/shared/Month.type';
import { Quarter } from 'src/app/core/types/shared/Quarter.type';

@Component({
  selector: 'app-date-period-selector',
  templateUrl: './date-period-selector.component.html',
})
export class DatePeriodSelectorComponent {
  @Output() periodSelected = new EventEmitter<boolean>();

  @Input() formGroup!: FormGroup;
  @Input() isMonthly!: boolean;
  @Input() isYearly: boolean = false;
  @Input() monthOrQuarter!: Month[] | Quarter[];
  @Input() flexDirection: 'row' | 'col' = 'col';

  onPeriodSelect() {
    this.periodSelected.emit(true);
  }

  get years(): number[] {
    return getYears();
  }
}
