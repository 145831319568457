import { NgFor, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { generateDownloadURL } from 'src/app/core/helper/downloadFile.helper';
import { SharedComponentsModule } from '../../components.module';
import { LoadingComponent } from '../loading/loading.component';
import { SwitchComponet } from '../switch/switch.component';

@Component({
  standalone: true,
  selector: 'upload-files',
  templateUrl: './upload-files.component.html',
  imports: [
    NgFor,
    NgIf,
    SharedComponentsModule,
    SwitchComponet,
    LoadingComponent,
  ],
})
export class UploadFilesComponent {
  @ViewChild('fileUpload') fileUpload!: ElementRef<HTMLInputElement>;

  @Output() uploadFiles: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();
  @Output() updateFileStatus: EventEmitter<{
    file: any;
    status: boolean;
    toggleType: 'visible' | 'attach';
  }> = new EventEmitter();
  @Output() deleteFile: EventEmitter<{ fileId: number; fileName: string }> =
    new EventEmitter();
  @Output() downloadFile: EventEmitter<
    Parameters<typeof generateDownloadURL>[0]
  > = new EventEmitter<{ url: string; fileName: string }>();

  @Input() headerTitles!: string[];
  @Input() files!: any[];
  @Input() title!: string;
  @Input() hasLoading: boolean = false;

  downloadURL!: string;

  onFileSelected(event: any): void {
    this.uploadFiles.emit(event);
  }

  openFileInput(): void {
    this.fileUpload.nativeElement.click();
  }

  onCancel() {
    this.cancel.emit(true);
  }

  toggleStatus(
    event: boolean,
    file: any,
    status: boolean,
    toggleType: 'visible' | 'attach'
  ) {
    if (!event) return;

    status = !status;
    file[toggleType === 'attach' ? 'attach_by_default' : 'visible_to_client'] =
      status;

    this.updateFileStatus.emit({ file, status, toggleType });
  }

  deleteUploadedFile(file: any) {
    this.deleteFile.emit({
      fileId: file.id,
      fileName: file.original_file_name,
    });
  }

  downloadUploadedFile(url: string, fileName: string) {
    this.downloadFile.emit({ url, fileName });
  }
}
