import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { EntityService } from 'src/app/core/types/EntityService.type';
import { ServicesService } from '../add-entity-sevice/data-access/entity-service.service';
import { filterEntityService } from '../add-entity-sevice/helper/filterEntityService.helper';

@Component({
  selector: 'app-entity-services-history',
  templateUrl: './entity-services-history.component.html',
})
export class EntityServicesHistoryComponent implements OnInit {
  private readonly _servicesService: ServicesService = inject(ServicesService);
  private readonly _router: Router = inject(Router);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);

  public urlNav: string = '';
  public columnsToDisplay = [
    { key: 'serviceName', value: 'Service Name' },
    { key: 'startDate', value: 'Start Date' },
    { key: 'endDate', value: 'End Date' },
  ];
  entityId: string = this._router.url.split('/')[3];
  entityServices!: Partial<EntityService>[];

  ngOnInit(): void {
    this.urlNav = this._router.url.split('/').slice(0, -1).join('/');

    this._servicesService.entityServices$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: entityServices => {
          if (!entityServices) return;

          this.entityServices = filterEntityService(
            entityServices,
            'ongoing',
            false
          );
        },
      });
  }

  public onSelectEditRow(entityService: any) {
    this._router.navigate([
      this.urlNav + '/' + entityService?.id + '/Service Log',
    ]);
  }
}
