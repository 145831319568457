import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FieldGroup } from 'src/app/core/types/FieldGroup';
import { PropertyGroup } from '../../types/PropertyGroup';

@Component({
  standalone: true,
  selector: 'app-copy-group',
  templateUrl: './copy-group.component.html',
  styleUrl: './copy-group.component.scss',
  imports: [NgFor, FormsModule, NgSelectModule],
})
export class CopyGroupComponent {
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() copy: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() selectedFieldGroupIdChange: EventEmitter<number> =
    new EventEmitter<number>(false);

  @Input() customFieldGroups!: PropertyGroup[];
  @Input() selectedFieldGroupInfo!: FieldGroup;
  @Input() title!: string;

  selectedFieldGroupId!: number;

  closeCopyModal(): void {
    this.close.emit(true);
  }

  copyFieldGroup(): void {
    this.copy.emit(true);
  }

  onFieldGroupSelected(selectedFieldGroupId: number): void {
    this.selectedFieldGroupId = selectedFieldGroupId;
    this.selectedFieldGroupIdChange.emit(this.selectedFieldGroupId);
  }
}
