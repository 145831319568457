import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { search } from 'src/app/core/helper/search.helper';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements AfterViewInit, OnDestroy {
  @Input()
  public originalData: any[] = [];
  @Input()
  public filteredData: any[] = [];
  @Input()
  public columnsToDisplay: any[] = [];

  @Output()
  public emitFilteredData: EventEmitter<any[]> = new EventEmitter();

  public searchValueChanged: Subject<any> = new Subject();

  constructor() {}

  ngAfterViewInit(): void {
    this.searchValueChanged.subscribe(keyword => this.searchData(keyword));
  }

  ngOnDestroy(): void {
    this.searchValueChanged.unsubscribe();
  }

  private searchData(keyword: any) {
    const searchResults: any[] = search(
      keyword,
      this.originalData,
      this.columnsToDisplay
    );

    this.emitFilteredData.emit(searchResults);
  }
}
