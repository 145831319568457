import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { StepComponent } from 'src/app/components/shared/stepper/step/step.component';
import { StepperComponent } from 'src/app/components/shared/stepper/stepper.component';
import { StepsBarComponent } from 'src/app/components/shared/stepper/steps-bar/steps-bar.component';
import { PERIODICITY } from 'src/app/core/enums/Periodicity.enum';
import {
  EntityService,
  Service,
  ServiceTask,
} from 'src/app/core/types/EntityService.type';
import { StepsBarStep } from 'src/app/core/types/Stepper.type';
import { Periodicity } from 'src/app/core/types/shared/Periodicity.type';
import { ServicesService } from './data-access/entity-service.service';
import { PeriodicityFormComponent } from './forms/periodicity-form/periodicity-form.component';
import { ServiceDetailsFormComponent } from './forms/service-details-form/service-details-form.component';
import { ServiceStartDateFormComponent } from './forms/service-start-date-form/service-start-date-form.component';
import { ServiceTasksFormComponent } from './forms/service-tasks-form/service-tasks-form.component';

@Component({
  standalone: true,
  selector: 'app-add-entity-service',
  templateUrl: './add-entity-sevice.component.html',
  imports: [
    StepperComponent,
    StepComponent,
    StepsBarComponent,
    ReactiveFormsModule,
    ServiceTasksFormComponent,
    PeriodicityFormComponent,
    ServiceDetailsFormComponent,
    ServiceStartDateFormComponent,
    CommonModule,
  ],
})
export class AddEntityServiceComponent implements OnInit {
  private readonly _servicesService: ServicesService = inject(ServicesService);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);
  private readonly _router: Router = inject(Router);

  @Output() emitCancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() save: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() formGroup!: FormGroup;
  @Input() tasksArrayForm!: FormArray;

  steps: StepsBarStep[] = [
    { stepNumber: 1, title: 'Select', status: 'current' },
    { stepNumber: 2, title: 'Settings', status: 'next' },
    { stepNumber: 3, title: 'Frequency', status: 'next' },
    { stepNumber: 4, title: 'Details', status: 'next' },
    { stepNumber: 5, title: 'Date', status: 'next' },
  ];
  counter: number = 1;
  services!: Partial<EntityService>[];
  service!: Partial<Service>;
  tasks!: ServiceTask[];
  periodicities: Periodicity[] = Object.values(PERIODICITY);
  private _entityId!: string;

  ngOnInit(): void {
    this._entityId = this._router.url.split('/').at(-3) ?? '';

    this._getAllServices().subscribe({
      next: services => {
        this.services = services;
      },
    });

    this.formGroup
      .get('service')
      ?.valueChanges.pipe(
        switchMap((serviceId: number) => {
          if (!serviceId) return of();

          return this._getServiceById(serviceId);
        })
      )
      .subscribe({
        next: (service: any) => {
          this.service = service;
          this.tasks = service.tasks;

          this.tasksArrayForm.clear();

          service.tasks?.forEach((task: any) => {
            this.tasksArrayForm.push(
              new FormGroup({
                [task?.id]: new FormControl(true),
              })
            );
          });

          Object.keys(this.serviceDetails).forEach(key => {
            this.formGroup
              .get(key)
              ?.setValue((this.serviceDetails as any)[key]);
          });
        },
      });
  }

  nextStep(stepper: StepperComponent) {
    stepper.nextStep();
    this.steps[stepper.currentStep - 1].status = 'done';
    this.steps[stepper.currentStep].status = 'current';
    if (this.steps[stepper.currentStep + 1])
      this.steps[stepper.currentStep + 1].status = 'next';
  }

  previousStep(stepper: StepperComponent) {
    stepper.previousStep();
    this.steps[stepper.currentStep - 1].status = 'next';
    this.steps[stepper.currentStep - 1 - 1].status = 'current';
  }

  cancel() {
    this.emitCancel.emit(true);
  }

  saveService() {
    this.save.emit(true);
  }

  private _getAllServices() {
    return this._servicesService
      .getAllServices(this._entityId)
      .pipe(takeUntilDestroyed(this._destroyRef));
  }

  private _getServiceById(serviceId: number) {
    return this._servicesService.getServiceById(serviceId);
  }

  get serviceControl(): FormControl {
    return this.formGroup.get('service') as FormControl;
  }

  get servicePeriodicityControl(): FormControl {
    return this.formGroup.get('servicePeriodicity') as FormControl;
  }

  get billingPeriodicityControl(): FormControl {
    return this.formGroup.get('billingPeriodicity') as FormControl;
  }

  get serviceDetailsControls(): {
    description: FormControl;
    disclosure: FormControl;
    fee: FormControl;
  } {
    const descriptionControl: FormControl = this.formGroup.get(
      'description'
    ) as FormControl;
    const disclosureControl: FormControl = this.formGroup.get(
      'disclosure'
    ) as FormControl;
    const feeControl: FormControl = this.formGroup.get('fee') as FormControl;

    return {
      description: descriptionControl,
      disclosure: disclosureControl,
      fee: feeControl,
    };
  }

  get startDateControl(): FormControl {
    return this.formGroup.get('startDate') as FormControl;
  }

  get isFormValid(): boolean {
    return this.formGroup.invalid;
  }

  get isServicePeriodicityControlValid(): boolean {
    return this.formGroup.get('servicePeriodicity')?.valid ?? false;
  }

  get isBillingPeriodicityControlValid(): boolean {
    return this.formGroup.get('billingPeriodicity')?.valid ?? false;
  }

  get isFeeControlValid(): boolean {
    return this.formGroup.get('fee')?.valid ?? false;
  }

  get hasSelectedServiceTasks(): boolean {
    return !!(this.formGroup.get('tasks')?.value as []).find(task => {
      return Object.values(task)[0];
    });
  }

  get serviceDetails(): {
    description: Service['description'];
    disclosure: Service['defaultDisclosure'];
    fee: Service['defaultFee'];
  } {
    return {
      description: this.service?.description ?? '',
      disclosure: this.service?.defaultDisclosure ?? '',
      fee: this.service?.defaultFee ?? 0,
    };
  }
}
