import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Toast } from 'src/app/core/types/Toast.type';

@Component({
  standalone: true,
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent {
  @Input() toast!: Toast;
  @Input() index!: number;

  @Output() remove = new EventEmitter<number>();
}
