@if (entityServices !== undefined) {
  <table-basic
    [setStatusColor]="true"
    [editAction]="true"
    [deleteAction]="true"
    [columnsToDisplay]="columnsToDisplay"
    [tableData]="entityServices"
    (onEditClick)="onSelectEditRow($event)"
    (onDeleteClick)="onDeleteEntityService($event)"></table-basic>
} @else {
  <div class="flex justify-center">
    <app-loading />
  </div>
}

@if (deleteEntityServiceModalStatus === MODAL_STATUS.OPEN) {
  <confirmation-modal
    [rowData]="selectedEntityService"
    [title]="'Delete ' + selectedEntityService?.serviceName"
    (emitCancel)="closeDeleteEntityServiceModal()"
    (emitDelete)="deleteEntityService()" />
}
