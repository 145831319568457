import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { authenticate } from './authenticate-enums';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateService {
  isAuthenticated: boolean = false;

  constructor(private http: HttpClient) {}

  public get tokenValue() {
    return this.token;
  }

  private token: string = '';

  public login(login_email: string, password: string) {
    const loginData = {
      login_email: login_email,
      password: password,
    };
    return this.http.post<any>(authenticate.loginUrl, loginData);
  }

  public logout() {
    return this.http.post<any>(authenticate.logoutUrl, {});
  }

  public getUserData() {
    return this.http.get<any>(authenticate.profileUrl);
  }

  // isLoggedIn(email: string, password: string): Observable<boolean> {
  //   if (email === 'admin@gmail.com' && password === 'admin') {
  //     this.isAuthenticate = true;
  //     return of(true);
  //   }
  //   return of(false);
  // }
}
