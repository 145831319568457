import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedComponentsModule } from 'src/app/components/components.module';
import {
  EntityNote,
  EntityNoteAction,
} from 'src/app/core/types/EntityNote.type';

@Component({
  standalone: true,
  selector: 'app-entity-notes-list',
  templateUrl: './entity-notes-list.component.html',
  imports: [CommonModule, SharedComponentsModule],
})
export class EntityNotesListComponent {
  @Output() onAction: EventEmitter<EntityNoteAction> = new EventEmitter();

  @Input() entityNotes!: EntityNote[];
  @Input() connectedUserId!: any;

  isFromConnectedUser(note: EntityNote): boolean {
    return note.added_by.id === this.connectedUserId;
  }

  deteleNote(note: EntityNote) {
    this.onAction.emit({ name: 'delete', data: note });
  }

  editNote(note: EntityNote) {
    this.onAction.emit({ name: 'edit', data: note });
  }
}
