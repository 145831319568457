@if (!hasLoading) {
  <div class="divide-gray-100 divide-y-2">
    <h1 class="text-gray-900 font-medium text-lg">{{ title }} Uploads</h1>
    <div>
      @if (files && files.length) {
        <table class="min-w-full divide-y divide-gray-300 mt-6">
          <thead class="bg-gray-50">
            <tr>
              @for (headerTitle of headerTitles; track $index) {
                <th
                  class="font-normal text-sm text-gray-500 pl-4 pr-0 py-2 w-32">
                  {{ headerTitle }}
                </th>
              }
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody class="divide-y divide-gray-200 bg-white">
            @for (file of files; track $index) {
              <tr>
                <td class="pl-4 pr-0 py-2 text-sm text-left w-80">
                  <span class="text-gray-500 font-[500] text-sm"
                    >{{ file.original_file_name }}
                  </span>
                </td>
                <td class="pl-4 pr-0 py-2 text-sm w-40">
                  <span
                    class="text-gray-500 font-[500] flex justify-center text-sm">
                    <app-switch
                      [isActive]="file.visible_to_client"
                      (toggle)="
                        toggleStatus(
                          $event,
                          file,
                          file.visible_to_client,
                          'visible'
                        )
                      " />
                  </span>
                </td>
                <td class="pl-4 pr-0 py-2 text-sm h-auto w-40">
                  <span
                    class="text-gray-500 font-[500] text-sm flex justify-center">
                    <app-switch
                      [isActive]="file.attach_by_default"
                      (toggle)="
                        toggleStatus(
                          $event,
                          file,
                          file.attach_by_default,
                          'attach'
                        )
                      " />
                  </span>
                </td>

                <td class="pl-4 pr-0 py-2 text-sm text-left w-20">
                  <button
                    (click)="
                      downloadUploadedFile(
                        file.absolute_path,
                        file.original_file_name
                      )
                    "
                    class="text-blueDS-500 font-[500] text-sm">
                    Download
                  </button>
                </td>

                <td class="pl-4 pr-0 py-2 text-sm text-left w-20">
                  <button
                    class="text-red-400 font-[500] text-sm"
                    (click)="deleteUploadedFile(file)">
                    Delete
                  </button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      } @else {
        <app-not-found class="mt-8" label="No result."></app-not-found>
      }
      <div
        (click)="openFileInput()"
        class="mt-6 bg-white w-full mx-auto cursor-pointer p-8 border-2 border-gray-300 rounded-lg border-dashed flex items-center flex-col">
        <div class="self-center flex items-center flex-col">
          <div>
            <span class="text-blueDS-500 font-[500]"
              >Click to upload a file</span
            >
          </div>
          <span class="text-gray-500 font-[500] uppercase"
            >PNG, JPG, JPEG up to 100MB</span
          >
        </div>
      </div>
      <input
        type="file"
        multiple
        class="hidden"
        (change)="onFileSelected($event)"
        #fileUpload />
    </div>
  </div>
} @else {
  <div class="flex justify-center">
    <app-loading />
  </div>
}

<div class="flex justify-end mt-6">
  <button
    (click)="onCancel()"
    class="w-[81px] h-[38px] text-sm text-gray-500 border-2 border-gray-300 rounded-md">
    Close
  </button>
</div>
