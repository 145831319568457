export const QUARTER = {
  Q1: 'Q1',
  Q2: 'Q2',
  Q3: 'Q3',
  Q4: 'Q4',
} as const;

export const QUARTER_BY_NUMBER = {
  1: QUARTER.Q1,
  2: QUARTER.Q2,
  3: QUARTER.Q3,
  4: QUARTER.Q4,
} as const;
