export function getBase64(file: File, callback: Function) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    callback(reader.result);
  };
}

export function isImageFileValid(
  file: File,
  maxFileSize: number,
  acceptOnlyImages: boolean = true
): boolean {
  const { size: fileSize, type: fileType } = file;

  return (
    file &&
    fileSize < maxFileSize &&
    (fileType.startsWith('image/') ||
      (!acceptOnlyImages &&
        (fileType === 'application/csv' ||
          fileType ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          fileType === 'application/vnd.ms-excel' ||
          fileType === 'application/pdf' ||
          fileType === 'text/csv' ||
          fileType ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          file.name.includes('.QBW') ||
          file.name.includes('.qbw') ||
          fileType === 'text/plain')))
  );
}
