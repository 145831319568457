import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss',
})
export class TooltipComponet {
  @Input() icon!: any;
  @Input() title!: string;
}
