import { CommonModule, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RequiredAsteriskComponent } from 'src/app/components/shared/required-asterisk/required-asterisk.component';
import { BaseFormComponent } from 'src/app/core/directives/base-form/base-form.directive';

@Component({
  standalone: true,
  selector: 'app-entity-contact-form',
  templateUrl: './entity-contact-form.component.html',
  imports: [CommonModule, RequiredAsteriskComponent, ReactiveFormsModule, NgIf],
})
export class EntityContactFormComponent extends BaseFormComponent {
  getFormControlByName(name: string) {
    return this.formGroup.get(name);
  }
}
