<div class="block border-b border-gray-200">
  <nav class="-mb-[2px] flex" aria-label="Tabs">
    <ng-container *ngFor="let tab of tabs">
      <button
        #buttonTabs
        [attr.id]="tab"
        type="button"
        (click)="setActive($event, tab)"
        [ngClass]="defaultStyle ? 'border-b hover:text-blueDS-500' : ''"
        class="border-transparent px-4 py-3 rounded-t-lg text-center font-medium text-gray-500 text-md pl-0 pr-6">
        {{ tab }}
      </button>
    </ng-container>
  </nav>
</div>
