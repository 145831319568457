import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadGuard } from './auth/load.guard';
import { LoginActivateGuard } from './auth/login-activate.guard';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './structure/main/main.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: MainComponent,
    canActivate: [LoginActivateGuard],
    children: [
      {
        path: 'Entities',
        loadChildren: () =>
          import('./structure/entities/entities.module').then(
            m => m.EntitiesModule
          ),
        canLoad: [LoadGuard],
      },
      {
        path: 'Messages',
        loadChildren: () =>
          import('./structure/messages/messages.module').then(
            m => m.MessagesModule
          ),
        canLoad: [LoadGuard],
      },
      {
        path: 'Tasks',
        loadChildren: () =>
          import('./structure/tasks/tasks.module').then(m => m.TasksModule),
        canLoad: [LoadGuard],
      },
      {
        path: 'Reports',
        loadChildren: () =>
          import('./structure/reports/reports.module').then(
            m => m.ReportsModule
          ),
        canLoad: [LoadGuard],
      },
      {
        path: 'Billing',
        loadChildren: () =>
          import('./structure/billing/billing.module').then(
            m => m.BillingModule
          ),
        canLoad: [LoadGuard],
      },
      {
        path: 'Settings',
        loadChildren: () =>
          import('./structure/settings/settings.module').then(
            m => m.SettingsModule
          ),
        canLoad: [LoadGuard],
      },
      {
        path: 'Security',
        loadChildren: () =>
          import('./structure/security/security.module').then(
            m => m.SecurityModule
          ),
        canLoad: [LoadGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [LoadGuard],
})
export class AppRoutingModule {}
