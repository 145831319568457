<div class="flex flex-row justify-between items-center">
  <div class="relative ml-auto">
    <span
      class="absolute left-3 top-1/2 transform -translate-y-1/2 icon-search text-gray-500">
    </span>
    <input
      ngModel
      type="text"
      placeholder="Search"
      class="bg-white py-2 pl-10 rounded-md w-96 border border-gray-200"
      (ngModelChange)="searchValueChanged.next($event)" />
  </div>
</div>
