import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'pagination-backend',
  templateUrl: './pagination-backend.component.html',
  styleUrls: ['./pagination-backend.component.css'],
})
export class PaginationBackendComponent implements OnChanges {
  @Input() public itemsPerPage: number = 10;
  @Input() public current: number = 0;
  @Input() public dataCount: number = 0;
  @Input() public lastPage: number = 0;
  @Output() public pageChange: EventEmitter<number> =
    new EventEmitter<number>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {}

  public get pages() {
    if (this.dataCount > 0) {
      let collectionOfPages = [];
      for (var i = 1; i <= this.lastPage; i++) {
        collectionOfPages.push(i);
      }
      return collectionOfPages;
    } else return [];
  }

  public goToPrev() {
    if (this.current !== 0 && this.current !== 1) {
      const copiedCurrent = this.current;
      this.pageChange.emit(copiedCurrent - 1);
    }
  }

  public goToPage(pageNum: number) {
    this.pageChange.emit(pageNum);
  }

  public goToNext() {
    if (this.current !== this.lastPage) {
      const copiedCurrent = this.current;
      this.pageChange.emit(copiedCurrent + 1);
    }
  }
}
