<div>
  <div>
    <ng-content select="[step-title]"></ng-content>
  </div>
  @if (
    currentStep === stepNumber ||
    (currentStep > stepNumber && isLast) ||
    (currentStep < stepNumber && isFirst)
  ) {
    <div>
      <ng-content select="[step-content]"></ng-content>
      <ng-content select="[step-action]"></ng-content>
    </div>
  }
</div>
