import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ENTITY_CONTACT_FORM_ARRAYS } from 'src/app/core/enums/EntityContact.enum';
import { EntityContactServicesForms } from 'src/app/core/types/EntityContact.type';
import { EntityContactsComponent } from '../entity-contacts.component';
import { EntityContactDetailsComponent } from '../pages/entity-contact-details/entity-contact-details.component';

export function initEntityContactForm(
  this: EntityContactsComponent | EntityContactDetailsComponent
) {
  this.entityContactForm = new FormGroup({
    id: new FormControl(null),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    alias: new FormControl(''),
    billingAddress: new FormControl(''),
    billingCity: new FormControl(''),
    billingState: new FormControl(''),
    billingZip: new FormControl(null),
    greeting: new FormControl(''),
  });
}

export function initEntityContactServicesForm(
  this: EntityContactDetailsComponent
) {
  this.entityContactServicesForm = new FormGroup({
    emails: new FormArray([]),
    sms: new FormArray([]),
    phones: new FormArray([]),
  });
}

export function addFormControl(
  this: EntityContactDetailsComponent,
  form: EntityContactServicesForms,
  disabled: boolean = false
) {
  switch (form.name) {
    case ENTITY_CONTACT_FORM_ARRAYS.EMAILS: {
      const emailsForm = this._formBuilder.group({
        id: [form.id],
        email: [
          { value: form.email ?? null, disabled },
          [Validators.required, Validators.email],
        ],
        emailStatus: [form.emailStatus ?? 'off', Validators.required],
        grEntityContactId: [+this.entityContatId],
      });

      this.emailsForm.push(emailsForm);
      break;
    }

    case ENTITY_CONTACT_FORM_ARRAYS.PHONES: {
      const phonesForm = this._formBuilder.group({
        id: [form.id ?? null],
        number: [
          { value: form.number ?? null, disabled },
          [Validators.required, Validators.pattern('[- +()0-9]{10,12}')],
        ],
        type: [form.type ?? 'home', Validators.required],
        grEntityContactId: [+this.entityContatId],
      });

      this.phonesForm.push(phonesForm);
      break;
    }

    case ENTITY_CONTACT_FORM_ARRAYS.SMS: {
      const smsForm = this._formBuilder.group({
        id: [form.id ?? null],
        number: [
          { value: form.number ?? null, disabled },
          [Validators.required, Validators.pattern('[- +()0-9]{10,12}')],
        ],
        smsStatus: [form.smsStatus ?? 'off', Validators.required],
        grEntityContactId: [form.id ?? +this.entityContatId],
      });

      this.smsForm.push(smsForm);
      break;
    }

    default:
      break;
  }
}

export function formatedEntityContactData(
  this: EntityContactsComponent | EntityContactDetailsComponent
): unknown {
  const {
    id = null,
    firstName,
    lastName,
    alias,
    billingAddress,
    billingCity,
    billingState,
    billingZip,
  } = this.entityContactForm.value;

  return {
    id,
    firstname: firstName,
    lastname: lastName,
    gr_entity_id: this.entityId,
    alias,
    billing_address: billingAddress,
    billing_city: billingCity,
    billing_state: billingState,
    billing_zip: billingZip,
  };
}
