import { Component, Input } from '@angular/core';
import { StepsBarStep } from 'src/app/core/types/Stepper.type';

@Component({
  standalone: true,
  selector: 'app-steps-bar',
  templateUrl: './steps-bar.component.html',
})
export class StepsBarComponent {
  @Input() steps!: StepsBarStep[];
}
