<div class="space-y-6">
  <span class="font-medium text-gray-900 text-lg">
    {{ title }}
  </span>

  <div class="w-full">
    <div
      class="bg-white w-full border border-gray-300 px-3 py-2 flex-grow mr-4 cursor-pointer rounded-md">
      <ng-select
        (change)="onFieldGroupSelected($event.id)"
        placeholder="Select Field Group..."
        [(ngModel)]="selectedFieldGroupId"
        [items]="customFieldGroups"
        bindLabel="name"
        bindValue="id"
        groupBy="entity_type"
        appendTo="body">
      </ng-select>
    </div>
  </div>

  @if (selectedFieldGroupId) {
    <div class="space-y-2">
      <h3 class="font-semibold">Custom fields in this group:</h3>
      <ul class="list-disc">
        @for (property of selectedFieldGroupInfo.properties; track $index) {
          <li class="ml-6">
            {{ property?.name || '--' }}
          </li>
        } @empty {
          No groups available for this Entity Type
        }
      </ul>
    </div>
  }

  <div class="flex flex-row justify-end w-full gap-3">
    <button
      (click)="closeCopyModal()"
      class="w-[80px] h-[38px] text-sm text-gray-500 border-2 border-gray-300 rounded-md">
      Cancel
    </button>
    <button
      (click)="copyFieldGroup()"
      class="w-[80px] h-[38px] bg-blueDS-500 text-white text-sm rounded-md">
      Copy
    </button>
  </div>
</div>
