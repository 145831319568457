<div
  class="overflow-hidden rounded-lg bg-gray-50 shadow-md border h-[80%] flex flex-col max-w-[1200px] py-4">
  <p
    *ngIf="isRouteServiceEdit"
    class="ml-4 mb-2 text-gray-900 font-semibold self-start">
    {{ selectedServiceName }}
  </p>
  <div class="flex">
    <tabs
      *ngIf="!isRouteServiceEdit"
      class="flex-1 mr-8 px-6"
      (onTabSelect)="switchTab($event)"
      [tabs]="tabs"></tabs>

    <app-icon-button
      *ngIf="!isRouteServiceEdit"
      iconClass="icon-plus"
      title="Add Service"
      class="self-end mr-4"
      (click)="addService()"></app-icon-button>
    <tabs
      [defaultStyle]="false"
      *ngIf="isRouteServiceEdit"
      class="flex-1 mr-4 pl-4"
      (onTabSelect)="switchServiceTab($event)"
      [tabs]="editServiceTabs"></tabs>
  </div>
  <div
    [ngClass]="
      !isRouteServiceEdit
        ? 'px-4'
        : 'bg-white border-gray-200 border-l border-r border-b mx-4'
    "
    class="overflow-x-hidden overflow-y-auto h-full flex-grow">
    <router-outlet></router-outlet>
  </div>
</div>

@if (isAddServiceModalOpen) {
  <modal [modalAreaWidth]="'750px'">
    <app-add-entity-service
      [formGroup]="entityServiceForm"
      [tasksArrayForm]="tasksArrayForm"
      (save)="saveService($event)"
      (emitCancel)="cancel($event)" />
  </modal>
}
