import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, switchMap } from 'rxjs';
import { EntitiesService } from '../entities.service';

@Component({
  selector: 'entity',
  templateUrl: './entity.component.html',
})
export class EntityComponent implements OnInit {
  protected readonly _entitiesService: EntitiesService =
    inject(EntitiesService);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);
  private readonly _router: Router = inject(Router);

  public tabs = [
    'Company Profile',
    'Services',
    'Officers',
    'Contacts',
    'Uploads',
    // 'Billing',
    // 'Assigned Users',
    'Notes',
    // 'Tasks',
  ];
  private readonly _entityId: string = this._router.url.split('/')[3];
  private _selectedServiceId!: string;
  selectedEntityeName!: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._getEntity().subscribe(entity => {
      if (!entity) return;

      this.selectedEntityeName = entity.entity_name;
    });

    this._router.events
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(event => event instanceof NavigationEnd),
        switchMap((event: any) => {
          this._selectedServiceId = event.urlAfterRedirects.split('/').at(5);
          return this._getEntity();
        }),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(entity => {
        if (!entity) return;

        this.selectedEntityeName = entity.entity_name;
      });
  }

  public switchTab(event: any) {
    this.router.navigate([event], { relativeTo: this.activatedRoute });
  }

  public _getEntity() {
    return this._entitiesService.getEntity(this._entityId);
  }
}
