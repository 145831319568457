<div
  class="flex min-h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
  <div class="w-full max-w-md space-y-8">
    <div>
      <img
        class="mx-auto h-12 w-auto"
        src="../../../assets/deskshore-logo.PNG"
        alt="Your Company" />
      <h2
        class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
        Sign in to your account
      </h2>
    </div>

    <form [formGroup]="profileForm" class="mt-8 space-y-2">
      <input type="hidden" name="remember" value="true" />
      <div class="-space-y-px rounded-md shadow-sm">
        <div>
          <label for="email-address" class="sr-only">Email address test</label>
          <input
            id="email-address"
            name="email"
            type="email"
            autocomplete="email"
            formControlName="login_email"
            required
            class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blueDS-500 focus:outline-none focus:ring-blueDS-500 sm:text-sm"
            placeholder="Email address" />
        </div>
        <div>
          <label for="password" class="sr-only">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            autocomplete="current-password"
            formControlName="password"
            required
            class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-blueDS-500 focus:outline-none focus:ring-blueDS-500 sm:text-sm"
            placeholder="Password" />
        </div>
      </div>

      <div>
        <div class="w-full flex justify-center h-6">
          <span class="text-red-500" *ngIf="isLoginInvalid">{{
            errorMessage
          }}</span>
        </div>
        <button
          type="button"
          [disabled]="profileForm.invalid"
          [class.cursor-not-allowed]="profileForm.invalid"
          [class.bg-blueDS-200]="profileForm.invalid"
          (click)="onLoginClick()"
          class="group relative flex w-full justify-center rounded-md border border-transparent bg-blueDS-500 py-2 px-4 text-sm font-medium text-white focus:outline-none">
          Sign in
        </button>
      </div>

      <div class="flex items-center justify-center">
        <a class="font-medium text-blueDS-500 text-sm mt-6"
          >Forgot your password?</a
        >
      </div>
    </form>
  </div>
</div>
