import { NgFor, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Tag } from 'src/app/core/types/Notification.type';
import { LoadingComponent } from '../../../loading/loading.component';
import { TooltipComponet } from '../../../tooltip/tooltip.component';

@Component({
  standalone: true,
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  imports: [
    NgFor,
    LoadingComponent,
    ReactiveFormsModule,
    TitleCasePipe,
    TooltipComponet,
  ],
})
export class TagListComponent {
  @Output() tagDoubleClick = new EventEmitter<string>();
  @Output() onDeleteTag = new EventEmitter<number>();

  @Input() customFields!: Tag[];
  @Input() tags!: Tag[];
  @Input() customFieldControl!: FormControl;
  @Input() staticTags!: Tag[];
  @Input() hasNotificationSettings: boolean = false;

  customFieldsTooltipText: string =
    'When entering Email or SMS content, you can double click on a custom field to insert it in the current cursor position, or simply copy-paste the placeholder variable in curly braces { } to the desired position.';

  tagClicked(tag: Tag) {
    this.tagDoubleClick.emit(tag.tag);
  }

  deleteTag(tag: Tag) {
    this.onDeleteTag.emit(tag.id);
  }
}
