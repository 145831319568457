import { CommonModule, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingComponent } from 'src/app/components/shared/loading/loading.component';
import { EntityContact } from 'src/app/core/types/EntityContact.type';

@Component({
  standalone: true,
  selector: 'add-existing-contact',
  templateUrl: './add-existing-contact.component.html',
  imports: [CommonModule, NgFor, LoadingComponent],
})
export class AddExistingContactComponent {
  @Output() emitCancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitAdd: EventEmitter<number> = new EventEmitter<number>();

  @Input() existingContacts!: Partial<EntityContact>[];

  onAdd(existingContactId: number | undefined): void {
    if (!existingContactId) return;

    this.emitAdd.emit(existingContactId);
  }

  onCancel(): void {
    this.emitCancel.emit(true);
  }
}
