<div class="py-8">
  <tabs
    [defaultStyle]="false"
    class="flex-1"
    (onTabSelect)="switchTab($event)"
    [tabs]="paymentMethods"></tabs>

  <div
    class="overflow-x-hidden overflow-y-auto flex-grow bg-white border-gray-200 border-l border-r border-b">
    <div class="p-4 flex flex-col" *ngIf="selectedTab === paymentMethods[0]">
      <app-icon-button iconClass="icon-plus" title="Add Card"></app-icon-button>
      <ng-container *ngFor="let card of cardData">
        <div
          class="bg-white border border-gray-200 rounded-md my-4 flex flex-row">
          <img
            class="h-12 w-12 flex m-2"
            src="https://tailwindui.com/img/logos/mark.svg?color=blueDS&shade=600"
            alt="Your Company" />
          <div class="flex flex-col m-2">
            <span>{{ card.number }}</span>
            <span>{{ card.name }}</span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="p-4 flex flex-col" *ngIf="selectedTab === paymentMethods[1]">
      <app-icon-button iconClass="icon-plus" title="Add ACH"></app-icon-button>
      <ng-container *ngFor="let ach of achData">
        <div
          class="bg-white border border-gray-200 rounded-md my-4 flex flex-col">
          <span class="mt-2 ml-2">{{ ach.number }}</span>
          <span class="mb-2 ml-2">{{ ach.name }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
