import { AbstractControl, ValidationErrors } from '@angular/forms';

export function formErrorHandler(this: any, control: AbstractControl): string {
  const errors: ValidationErrors | null = control.errors;
  const isinvalid: boolean = control.invalid;

  if (isinvalid && errors) {
    if (errors['required']) {
      return 'Required';
    }

    if (errors['email']) {
      return 'Invalid Email';
    }

    if (errors['pattern']) {
      return 'Invalid';
    }
  }

  return '';
}
