import { EndpointError } from 'src/app/core/types/shared/Error.type';

export function getEndpointErrorMessage({ error }: EndpointError): {
  error: string;
  validation: string[];
} {
  return {
    error: error?.error ?? error?.message ?? 'Server error',
    ...(error?.data && {
      validation: Object.values<string>(error?.data).flatMap(data => data),
    }),
  };
}
