<a
  class="text-blueDS-600 cursor-pointer flex items-center gap-1 pb-2 text-sm font-normal"
  (click)="navigateToEntityContact()"
  ><span class="icon-arrow-left2"></span>Back to Contacts</a
>

<div
  class="flex justify-between flex-col gap-5 p-5 lg:flex-row lg:divide-x bg-white rounded-md">
  @if (isEntityContactLoaded) {
    <app-entity-contact-form
      class="w-full lg:pr-6"
      [formGroup]="entityContactForm"
      [isEditMode]="true"
      (submit)="onContactFormSubmit($event)" />
  } @else {
    <div class="flex justify-center w-full lg:pr-6">
      <app-loading />
    </div>
  }

  @if (isEntityContactServicesLoaded) {
    <app-entity-contact-services-form
      class="w-full lg:pl-6"
      [formGroup]="entityContactServicesForm"
      [entityContactFormArrays]="entityContactFormArrays"
      (addFormControl)="addFormControl($event)"
      (deleteFormControl)="deleteFormControl($event)"
      (addEntityContactService)="addEntityContactService($event)"
      (updatedEntityContactServicesStatus)="
        updateEntityContactServicesStatus($event)
      " />
  } @else {
    <div class="flex justify-center w-full lg:pr-6">
      <app-loading />
    </div>
  }
</div>

<confirmation-modal
  [rowData]="selectedEntityContactService"
  [title]="'Update ' + entityContactServicesModalTitle"
  [message]="entityContactServicesModalMessage"
  [isEditMode]="true"
  (emitCancel)="cancelEntityContactServicesEvent()"
  (emitDelete)="editEntityContactServices($event)"
  *ngIf="entityContactServicesModalType === 'edit'" />

<confirmation-modal
  [rowData]="selectedEntityContactService"
  [title]="'Delete ' + entityContactServicesModalTitle"
  [message]="entityContactServicesModalMessage"
  [propertyToDisplay]="
    entityContactServicesModalTitle === 'emails' ? 'email' : 'number'
  "
  (emitCancel)="cancelEntityContactServicesEvent()"
  (emitDelete)="deleteEntityContatService($event)"
  *ngIf="entityContactServicesModalType === 'delete'" />
