import { NgFor, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityContact } from 'src/app/core/types/EntityContact.type';

@Component({
  standalone: true,
  selector: 'app-entity-contact-list',
  templateUrl: './entity-contact-list.component.html',
  imports: [NgFor, TitleCasePipe],
})
export class EntityContactListComponent {
  private readonly _router: Router = inject(Router);
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  @Output() edit: EventEmitter<Partial<EntityContact>> = new EventEmitter();
  @Output() delete: EventEmitter<Partial<EntityContact>> = new EventEmitter();

  @Input() entityContacts!: Partial<EntityContact>[] | null;

  navigateToDetails(entityContactId: number | undefined): void {
    this._router.navigate(['details', entityContactId], {
      relativeTo: this._activatedRoute,
    });
  }

  onDelete(entityContact: Partial<EntityContact>): void {
    this.delete.emit(entityContact);
  }
}
