import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './structure/main/main.component';

import { AuthenticateInterceptService } from './auth/authenticate-intercept.service';
import { ToasterContainerComponent } from './components/shared/toaster/toaster-container/toaster-container.component';
import { GlobalErrorHandler } from './core/error/GlobalErrorHandler';
import { EntitiesModule } from './structure/entities/entities.module';

@NgModule({
  declarations: [AppComponent, MainComponent, LoginComponent],
  bootstrap: [AppComponent],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    EntitiesModule,
    ToasterContainerComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticateInterceptService,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
