<div
  class="relative z-10 overflow-hidden"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 overflow-hidden">
    <div
      class="flex min-h-full items-start justify-center p-4 text-center mt-24">
      <div
        class="relative transform overflow-hidden rounded-lg bg-white p-5 text-left shadow-xl transition-all sm:max-h-[85vh] sm:overflow-y-auto"
        [style.width]="modalAreaWidth">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
