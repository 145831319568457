@if (!isEntityContactSelected) {
  <div class="flex flex-col px-2">
    <div class="flex justify-end gap-4">
      <app-icon-button
        iconClass="icon-plus"
        title="Add New Contact"
        class="self-end mb-4"
        (click)="openAddModal()" />

      <app-icon-button
        iconClass="icon-plus"
        title="Add Existing Contact"
        class="self-end mb-4"
        (click)="openExistingContactModal()" />
    </div>

    @if (entityContacts !== undefined) {
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <app-entity-contact-list
          [entityContacts]="entityContacts"
          (delete)="openDeleteModal($event)" />

        <!-- <pagination-backend (pageChange)="changePage($event)" *ngIf="entityContactPaginationMetadata"
        [current]="entityContactPaginationMetadata?.currentPage || 0"
        [dataCount]="entityContactPaginationMetadata?.total || 0"
        [itemsPerPage]="entityContactPaginationMetadata?.perPage || 0" [lastPage]="
          entityContactPaginationMetadata?.lastPage || 0
        "></pagination-backend> -->
      </div>
    } @else {
      <div class="flex justify-center">
        <app-loading />
      </div>
    }

    @if (isAddModalOpen || isEditModalOpen) {
      <modal [modalAreaWidth]="'600px'">
        <app-entity-contact-form
          [formGroup]="entityContactForm"
          (submit)="onSubmit($event)"
          (cancel)="cancelAction($event)" />
      </modal>
    }

    @if (isDeleteModalOpen) {
      <confirmation-modal
        [rowData]="selectedEntityContact"
        title="Delete Contact"
        [propertyToDisplay]="'firstname'"
        (emitCancel)="cancelAction($event)"
        (emitDelete)="deleteEntityContact($event)" />
    }

    @if (isAddExistingContactModalOpen) {
      <modal [modalAreaWidth]="'auto'">
        <add-existing-contact
          [existingContacts]="filtredExistingContacts"
          (emitCancel)="cancelAction($event)"
          (emitAdd)="addExistingContact($event)" />
      </modal>
    }
  </div>
} @else {
  <router-outlet></router-outlet>
}
