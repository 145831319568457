@if (services) {
  <select
    [formControl]="serviceControl"
    class="border-slate-300 rounded-md placeholder-slate-400 focus:border-blueDS-400 focus:ring-1 focus:ring-blueDS-500 bg-white">
    <option selected disabled [value]="null">Select service</option>
    @for (service of services; track $index) {
      <option [value]="service?.id">
        {{ service?.serviceName | titlecase }}
      </option>
    }
  </select>
}
<div [ngClass]="{ 'mt-8': services }">
  @if (tasks && tasks.length) {
    Tasks
  } @else {
    <div class="flex justify-center p-6 text-gray-400">
      <p>No task.</p>
    </div>
  }
  <div class="flex gap-10 mt-3 overflow-x-auto">
    @for (task of tasks; track $index) {
      <div class="relative flex items-start">
        <div class="flex h-6 items-center">
          <input
            type="checkbox"
            [formControl]="getTaskControl($index, task.id)"
            class="h-4 w-4 rounded border-slate-300 text-blueDS-600 focus:ring-blueDS-600" />
        </div>
        <div class="ml-2 text-sm leading-6">
          <label for="comments" class="font-medium text-gray-900">{{
            task?.name | titlecase
          }}</label>
        </div>
      </div>
    }
  </div>
</div>
