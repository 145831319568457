import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { formErrorHandler } from 'src/app/core/helper/formErrorHandler.helper';

@Component({
  standalone: true,
  selector: 'app-service-details-form',
  templateUrl: './service-details-form.component.html',
  imports: [ReactiveFormsModule, CommonModule],
})
export class ServiceDetailsFormComponent {
  @Input() controls!: {
    description: FormControl;
    disclosure: FormControl;
    fee: FormControl;
  };
  @Input() flexDirection: 'col' | 'row' = 'col';

  errorMessage(control: AbstractControl | null) {
    if (!control) return;

    return formErrorHandler.call(this, control);
  }

  get isFlexDirectionRow(): boolean {
    return this.flexDirection === 'row';
  }
}
