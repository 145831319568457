import { NgClass } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, switchMap } from 'rxjs';
import { Tag } from 'src/app/core/types/Notification.type';
import { SettingsService } from 'src/app/structure/settings/settings.service';
import { NotificationFormComponent } from './components/forms/notification-form/notification-form.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TagListComponent } from './components/tag-list/tag-list.component';

@Component({
  standalone: true,
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  imports: [
    TabsComponent,
    TagListComponent,
    NotificationFormComponent,
    NgClass,
  ],
})
export class NotificationComponent implements OnInit {
  private readonly _settingsService: SettingsService = inject(SettingsService);

  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Input() taskId!: number;
  @Input() title!: string;

  customFields!: Tag[];
  notificationSettings!: any[];
  notificationForm!: FormGroup;
  staticTags: Tag[] = [
    {
      id: 1,
      name: 'Entity Name',
      tag: '{ENTITY}',
    },
    {
      id: 2,
      name: 'Service Name',
      tag: '{SERVICE}',
    },
    {
      id: 3,
      name: 'Task Name',
      tag: '{TASK}',
    },
    {
      id: 4,
      name: 'Period',
      tag: '{PERIOD}',
    },
    {
      id: 6,
      name: 'Contact, First Name',
      tag: '{CONTACT_FIRSTNAME}',
    },
    {
      id: 7,
      name: 'Contact, Last Name',
      tag: '{CONTACT_LASTNAME}',
    },
    {
      id: 8,
      name: 'Contact, Alias',
      tag: '{ALIAS}',
    },
    {
      id: 9,
      name: 'Email Reply Link',
      tag: '{REPLY_LINK}',
    },
    {
      id: 10,
      name: 'SMS Accept Code',
      tag: '{SMS_ACCEPT_CODE}',
    },
    {
      id: 11,
      name: 'SMS Reject Code',
      tag: '{SMS_REJECT_CODE}',
    },
  ];
  tags: Tag[] = [];
  hasNotificationSettings: boolean = false;

  ngOnInit(): void {
    this._initNotificationForm();
    this._getAllCustomFields().subscribe({
      next: ({ data }) => {
        this.customFields = data;
      },
    });
    this._getServiceTaskNotificationSettings().subscribe({
      next: ({ data }: any) => {
        const notification = data.at(0);

        if (data.length) {
          this.hasNotificationSettings = true;

          this._fillNotificationTemplate(notification);

          if (notification.properties.length) {
            this.tags = notification.properties;
          }
        } else {
          this.hasNotificationSettings = false;

          this.notificationForm
            .get('sendNotification')
            ?.valueChanges.pipe(
              filter(Boolean),
              switchMap(() => {
                const notificationName: string = `${this.title} notification settings`;
                this.notificationForm.get('name')?.setValue(notificationName);
                this.hasNotificationSettings = true;
                return this._settingsService.createServiceTaskNotificationTemplate(
                  this.taskId,
                  notificationName
                );
              })
            )
            .subscribe({
              next: ({ data }: any) => {
                this.notificationSettings = [data];
                if (data.properties.length) {
                  this.tags = data.properties;
                }
              },
            });
        }

        this.notificationSettings = data;
      },
    });

    this.notificationForm
      .get('customField')
      ?.valueChanges.pipe(
        filter(Boolean),
        switchMap(this.addCustomField.bind(this)),
        switchMap(() => this._getServiceTaskNotificationSettings())
      )
      .subscribe({
        next: ({ data }: any) => {
          this.notificationForm.get('customField')?.reset();
          const notification = data.at(0);
          if (notification.properties.length) {
            this.tags = notification.properties;
          }
        },
      });
  }

  copyTag(tag: any) {
    this._copyToClipboard(tag);
  }

  deleteTag(tagId: number) {
    this._settingsService
      .deleteCustomFieldFromNotificationTemplate(tagId)
      .pipe(switchMap(() => this._getServiceTaskNotificationSettings()))
      .subscribe({
        next: ({ data }: any) => {
          const notification = data.at(0);
          this.tags = notification.properties;
        },
      });
  }

  async pastText(event: any): Promise<void> {
    const pastedText: string = await navigator.clipboard.readText();
    const inputField: HTMLTextAreaElement | null =
      document.querySelector<HTMLTextAreaElement>('textarea');
    const editorText: HTMLParagraphElement | null =
      document.querySelector<HTMLParagraphElement>('.NgxEditor__Content > p');

    if (editorText) {
      const selection: Selection | null = window.getSelection();

      if (selection) {
        const range: Range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(pastedText));
      }
    }

    if (inputField) {
      const startPos: number = inputField.selectionStart;
      const endPos: number = inputField.selectionEnd;

      const textBeforeCursor: string = inputField.value.substring(0, startPos);
      const textAfterCursor: string = inputField.value.substring(
        endPos,
        inputField.value.length
      );

      inputField.value = textBeforeCursor + pastedText + textAfterCursor;

      const newCursorPos: number = startPos + pastedText.length;
      inputField.setSelectionRange(newCursorPos, newCursorPos);
    }
  }

  saveNotification() {
    const {
      email,
      emailSubject,
      expectResponse,
      hours,
      sendNotificationAsEmail,
      sendNotificationAsSMS,
      sms,
      name,
    } = this.notificationForm.value;
    const payload = {
      gr_service_task_id: this.taskId,
      name: name,
      email_subject_default: emailSubject,
      email_content_default: email,
      sms_content_default: sms,
      send_as_email: !!sendNotificationAsEmail,
      send_as_sms: !!sendNotificationAsSMS,
      expect_reply: !!expectResponse,
      hours_valid: hours ?? 0,
    };

    this.updateNotificationTemplate(payload).subscribe({
      next: () => {
        this.cancelSaveNotification();
      },
    });
  }

  cancelSaveNotification() {
    this.cancel.emit(true);
    this.notificationForm.reset();
  }

  addCustomField(propertyId: number) {
    const payload = {
      gr_property_id: propertyId,
      gr_notification_template_id: this.notificationSettings.at(0).id,
    };

    return this._settingsService.addCustomFieldToNotificationTemplate(payload);
  }

  updateNotificationTemplate(
    payload: Parameters<
      typeof this._settingsService.updateNotificationTemplate
    >[1]
  ) {
    return this._settingsService.updateNotificationTemplate(
      this.notificationSettings.at(0).id,
      payload
    );
  }

  private _copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

  private _initNotificationForm() {
    this.notificationForm = new FormGroup({
      sendNotification: new FormControl(),
      sendNotificationAsSMS: new FormControl(),
      sendNotificationAsEmail: new FormControl(),
      name: new FormControl('', Validators.required),
      expectResponse: new FormControl(),
      hours: new FormControl(),
      email: new FormControl(),
      sms: new FormControl(),
      customField: new FormControl(),
      emailSubject: new FormControl(),
    });
  }

  private _getAllCustomFields() {
    return this._settingsService.getAllCustomFields();
  }

  private _getServiceTaskNotificationSettings() {
    return this._settingsService.getServiceTaskNotificationSettings(
      this.taskId
    );
  }

  private _fillNotificationTemplate(notification: any) {
    this.notificationForm.get('sendNotification')?.setValue(true);
    this.notificationForm.get('name')?.setValue(notification.name);
    this.notificationForm
      .get('expectResponse')
      ?.setValue(notification.expect_reply);
    this.notificationForm.get('hours')?.setValue(notification.hours_valid);
    this.notificationForm
      .get('email')
      ?.setValue(notification.email_content_default);
    this.notificationForm
      .get('sms')
      ?.setValue(notification.sms_content_default);
    this.notificationForm
      .get('sendNotificationAsSMS')
      ?.setValue(notification.send_as_sms);
    this.notificationForm
      .get('sendNotificationAsEmail')
      ?.setValue(notification.send_as_email);
    this.notificationForm
      .get('emailSubject')
      ?.setValue(notification.email_subject_default);
  }

  get customFieldControl(): FormControl {
    return this.notificationForm.get('customField') as FormControl;
  }
}
