import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { toHTML } from 'ngx-editor';
import { filter } from 'rxjs';
import { EditorComponent } from '../../../editor/editor.component';
import { TooltipComponet } from '../../../tooltip/tooltip.component';
import { CommunicationsFormComponent } from '../forms/communications-notification-form/communications-notification-form.component';

const TAB = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
} as const;

@Component({
  standalone: true,
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  imports: [
    EditorComponent,
    NgIf,
    NgClass,
    CommunicationsFormComponent,
    TooltipComponet,
    ReactiveFormsModule,
  ],
})
export class TabsComponent implements OnInit {
  @Input() notificationForm!: FormGroup;
  @Output() onEditorClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  TAB = TAB;
  activeTab: (typeof TAB)[keyof typeof TAB] = this.TAB.EMAIL;
  emailTooltipMessage =
    '* This notification\'s template is set to expect a response from the recipient. Remember to add the placeholder {REPLY_LINK} to your email content. Feel free to edit and place it where you\'d like and add an accompanying message (example: "To confirm, please click here: {REPLY_LINK}"). The system will replace it upon sending.';
  smsTooltipMessage =
    '* This notification\'s template is set to expect a response from the recipient. Remember to add the placeholders {SMS_ACCEPT_CODE} and {SMS_REJECT_CODE} to your SMS content for the recipient response. Feel free to edit and place it where you\'d like and add an accompanying message (example: "Please reply with the code {SMS_ACCEPT_CODE} to accept and {SMS_REJECT_CODE} to reject."). The system will replace it upon sending.';
  emailContent!: string;

  ngOnInit(): void {
    this.emailFormControl.valueChanges
      .pipe(filter(Boolean))
      .subscribe(value => {
        this.emailContent = value;
      });
  }

  editorClicked() {
    this.onEditorClicked.emit(true);
  }

  editorChange(event: Parameters<typeof toHTML>[0] | string) {
    let htmlTexEditor = event;

    if (this._isHtmlString(event)) {
      htmlTexEditor = toHTML(event);
    }

    this.emailFormControl?.setValue(htmlTexEditor);
  }

  private _isHtmlString(html: any): html is Record<string, any> {
    return html.type;
  }

  get emailFormControl() {
    return this.notificationForm.get('email') as FormControl;
  }

  get smsFormControl() {
    return this.notificationForm.get('sms') as FormControl;
  }
}
