<div>
  <!-- Static sidebar for desktop -->
  <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div
      class="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
      <div class="flex flex-shrink-0 items-center px-4">
        <img
          class="h-10 w-auto"
          src="../../../assets/deskshoredashboard.PNG"
          alt="Your Company" />
      </div>
      <div class="mt-5 flex flex-grow flex-col">
        <nav class="flex-1 space-y-1 px-2 pb-4">
          <ng-container *ngFor="let item of navigationValues | slice: 0 : 3">
            <a
              [routerLink]="item.value"
              [class.bg-gray-100]="activeNavItem === item.value"
              class="select-none text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
              (click)="setActiveNavItem(item.value)">
              <svg
                class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  [attr.d]="item.icon" />
                <path
                  *ngIf="item.icon_2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  [attr.d]="item.icon_2" />
              </svg>
              {{ item.value }}
            </a>
          </ng-container>
          <div class="w-full mb-2">
            <a
              (click)="reportButton = !reportButton"
              class="select-none text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer">
              <svg
                class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.3246 4.31731C10.751 2.5609 13.249 2.5609 13.6754 4.31731C13.9508 5.45193 15.2507 5.99038 16.2478 5.38285C17.7913 4.44239 19.5576 6.2087 18.6172 7.75218C18.0096 8.74925 18.5481 10.0492 19.6827 10.3246C21.4391 10.751 21.4391 13.249 19.6827 13.6754C18.5481 13.9508 18.0096 15.2507 18.6172 16.2478C19.5576 17.7913 17.7913 19.5576 16.2478 18.6172C15.2507 18.0096 13.9508 18.5481 13.6754 19.6827C13.249 21.4391 10.751 21.4391 10.3246 19.6827C10.0492 18.5481 8.74926 18.0096 7.75219 18.6172C6.2087 19.5576 4.44239 17.7913 5.38285 16.2478C5.99038 15.2507 5.45193 13.9508 4.31731 13.6754C2.5609 13.249 2.5609 10.751 4.31731 10.3246C5.45193 10.0492 5.99037 8.74926 5.38285 7.75218C4.44239 6.2087 6.2087 4.44239 7.75219 5.38285C8.74926 5.99037 10.0492 5.45193 10.3246 4.31731Z" />
                <path
                  d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" />
              </svg>
              Reports
              <svg
                class="ml-8 h-5 w-5"
                [class.rotate-180]="settingsButton"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clip-rule="evenodd" />
              </svg>
            </a>
            <ng-container *ngIf="reportButton">
              <ng-container *ngFor="let report of reportsNavigationValues">
                <a
                  [routerLink]="'Reports/' + report"
                  [class.bg-gray-100]="activeNavItem === report"
                  class="mx-8 pl-3 select-none text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center py-2 text-base font-medium rounded-md cursor-pointer"
                  (click)="setActiveNavItem(report)"
                  >{{ report }}</a
                >
              </ng-container>
            </ng-container>
          </div>
          <!-- <a [routerLink]="navigationValues[4].value" [class.bg-gray-100]="activeNavItem === 'Billing'"
            class="select-none text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
            (click)="setActiveNavItem('Billing')">
            <svg class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" [attr.d]="navigationValues[4].icon" />
            </svg>
            Billing
          </a> -->
          <div class="w-full mb-2">
            <a
              (click)="settingsButton = !settingsButton"
              class="select-none text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer">
              <svg
                class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.3246 4.31731C10.751 2.5609 13.249 2.5609 13.6754 4.31731C13.9508 5.45193 15.2507 5.99038 16.2478 5.38285C17.7913 4.44239 19.5576 6.2087 18.6172 7.75218C18.0096 8.74925 18.5481 10.0492 19.6827 10.3246C21.4391 10.751 21.4391 13.249 19.6827 13.6754C18.5481 13.9508 18.0096 15.2507 18.6172 16.2478C19.5576 17.7913 17.7913 19.5576 16.2478 18.6172C15.2507 18.0096 13.9508 18.5481 13.6754 19.6827C13.249 21.4391 10.751 21.4391 10.3246 19.6827C10.0492 18.5481 8.74926 18.0096 7.75219 18.6172C6.2087 19.5576 4.44239 17.7913 5.38285 16.2478C5.99038 15.2507 5.45193 13.9508 4.31731 13.6754C2.5609 13.249 2.5609 10.751 4.31731 10.3246C5.45193 10.0492 5.99037 8.74926 5.38285 7.75218C4.44239 6.2087 6.2087 4.44239 7.75219 5.38285C8.74926 5.99037 10.0492 5.45193 10.3246 4.31731Z" />
                <path
                  d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" />
              </svg>
              Settings
              <svg
                class="ml-8 h-5 w-5"
                [class.rotate-180]="settingsButton"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clip-rule="evenodd" />
              </svg>
            </a>
            <ng-container *ngIf="settingsButton">
              <ng-container *ngFor="let setting of settingsNavigationValues">
                <a
                  [routerLink]="'Settings/' + setting"
                  [class.bg-gray-100]="activeNavItem === setting"
                  class="mx-8 pl-3 select-none text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center py-2 text-base font-medium rounded-md cursor-pointer"
                  (click)="setActiveNavItem(setting)"
                  >{{ setting }}</a
                >
              </ng-container>
            </ng-container>
          </div>

          <div class="w-full mb-2">
            <a
              (click)="securityButton = !securityButton"
              class="select-none text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer">
              <svg
                class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true">
                <path
                  d="M20.6179 5.98434C20.4132 5.99472 20.2072 5.99997 20 5.99997C16.9265 5.99997 14.123 4.84453 11.9999 2.94434C9.87691 4.84446 7.07339 5.99985 4 5.99985C3.79277 5.99985 3.58678 5.9946 3.38213 5.98422C3.1327 6.94783 3 7.95842 3 9.00001C3 14.5915 6.82432 19.2898 12 20.622C17.1757 19.2898 21 14.5915 21 9.00001C21 7.95847 20.8673 6.94791 20.6179 5.98434Z" />
                <path d="M12 9V11M12 15H12.01" />
              </svg>
              Security
              <svg
                class="ml-8 h-5 w-5"
                [class.rotate-180]="securityButton"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clip-rule="evenodd" />
              </svg>
            </a>
            <ng-container *ngIf="securityButton">
              <ng-container *ngFor="let security of securityNavigationValues">
                <a
                  [routerLink]="'Security/' + security"
                  [class.bg-gray-100]="activeNavItem === security"
                  class="mx-8 pl-3 select-none text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center py-2 text-base font-medium rounded-md cursor-pointer"
                  (click)="setActiveNavItem(security)"
                  >{{ security }}</a
                >
              </ng-container>
            </ng-container>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <div class="flex flex-1 flex-col md:pl-64 h-screen">
    <div class="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
      <button
        type="button"
        class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blueDS-500 md:hidden">
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: outline/bars-3-bottom-left -->
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
        </svg>
      </button>
      <div class="flex flex-1 justify-between px-4">
        <div class="ml-auto flex items-center">
          <button
            type="button"
            class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2">
            <span class="sr-only">View notifications</span>
            <!-- Heroicon name: outline/bell -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
            </svg>
          </button>

          <div class="ml-3 text-gray-500 pb-2">|</div>

          <!-- Profile dropdown -->
          <div class="relative ml-3">
            <div class="ml-auto">
              <button
                type="button"
                class="flex max-w-xs items-center bg-white p-2 text-sm text-blueDS-600 focus:outline-none hover:text-blueDS-400"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true">
                <span class="sr-only">Open user menu</span>
                <!-- <img class="h-8 w-8 rounded-full" (click)="openProfile = !openProfile"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""> -->
                <span (click)="openProfile = !openProfile"
                  >{{ firstName }} {{ lastName }}</span
                >
              </button>
            </div>

            <!--
                Dropdown menu, show/hide based on menu state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
            <div
              *ngIf="openProfile"
              class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1">
              <!-- Active: "bg-gray-100", Not Active: "" -->
              <a
                class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-blueDS-50 hover:text-blueDS-700"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-0"
                >Profile <small class="text-red-700">(Inactive)</small></a
              >
              <a
                class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-blueDS-50 hover:text-blueDS-700"
                (click)="logoutUser()"
                >Sign out</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <main class="bg-gray-100 h-full p-6 overflow-x-hidden">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
