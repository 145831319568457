import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'entity-billing',
  templateUrl: './entity-billing.component.html',
  styleUrls: ['./entity-billing.component.css'],
})
export class EntityBillingComponent implements OnInit {
  public entityBillingTabs = [
    'Invoices',
    'Payment Methods',
    'Billing Profile',
    'Billing Log',
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  public switchTab(event: any) {
    this.router.navigate([event], { relativeTo: this.activatedRoute });
  }
}
