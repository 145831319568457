import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { MODAL_STATUS } from 'src/app/core/enums/Modal.enum';
import { EntityService } from 'src/app/core/types/EntityService.type';
import { ModalStatus } from 'src/app/core/types/Modal.type';
import { ServicesService } from '../add-entity-sevice/data-access/entity-service.service';
import { filterEntityService } from '../add-entity-sevice/helper/filterEntityService.helper';

@Component({
  selector: 'entity-services-ongoing',
  templateUrl: './entity-services-ongoing.component.html',
})
export class EntityServicesOngoingComponent implements OnInit {
  private readonly _servicesService: ServicesService = inject(ServicesService);
  private readonly _router: Router = inject(Router);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);

  public urlNav: string = '';
  public columnsToDisplay = [
    { key: 'serviceName', value: 'Service Name' },
    { key: 'status', value: 'Status' },
    { key: 'startDate', value: 'Start Date' },
  ];

  entityId: string = this._router.url.split('/')[3];
  entityServices!: Partial<EntityService>[];
  selectedEntityService!: EntityService | null;
  deleteEntityServiceModalStatus!: ModalStatus;
  MODAL_STATUS = MODAL_STATUS;
  ngOnInit(): void {
    this.urlNav = this._router.url.split('/').slice(0, -1).join('/');

    this._getEntityServices().subscribe();

    this._servicesService.entityServices$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: entityServices => {
          if (!entityServices) return;

          this.entityServices = filterEntityService(
            entityServices,
            'ongoing',
            true
          );
        },
      });
  }

  public onSelectEditRow(entityService: any) {
    this._router.navigate([
      this.urlNav + '/' + entityService?.id + '/Service Log',
    ]);
  }

  onDeleteEntityService(entityService: EntityService) {
    this.selectedEntityService = entityService;
    this.deleteEntityServiceModalStatus = this.MODAL_STATUS.OPEN;
  }

  deleteEntityService() {
    if (!this.selectedEntityService) return;

    this._servicesService
      .deleteEntityService(this.selectedEntityService.id)
      .pipe(switchMap(this._getEntityServices.bind(this)))
      .subscribe(() => {
        this.closeDeleteEntityServiceModal();
      });
  }

  closeDeleteEntityServiceModal() {
    this.selectedEntityService = null;
    this.deleteEntityServiceModalStatus = this.MODAL_STATUS.CLOSE;
  }

  private _getEntityServices() {
    return this._servicesService
      .getAllEntityServices(this.entityId)
      .pipe(takeUntilDestroyed(this._destroyRef));
  }
}
