<div class="my-4">
  <h1 class="text-2xl font-medium leading-6 text-gray-900 pb-4">
    {{ title }} notification settings
  </h1>
</div>

<div class="py-4">
  <app-notification-form class="w-full" [formGroup]="notificationForm" />
</div>
<div class="flex gap-3">
  <app-tabs
    class="w-2/3"
    [notificationForm]="notificationForm"
    (onEditorClicked)="pastText($event)"></app-tabs>
  <app-tag-list
    class="w-1/3"
    [tags]="tags"
    [customFields]="customFields"
    [customFieldControl]="customFieldControl"
    [staticTags]="staticTags"
    [hasNotificationSettings]="hasNotificationSettings"
    (tagDoubleClick)="copyTag($event)"
    (onDeleteTag)="deleteTag($event)" />
</div>
<div class="mt-16">
  <div class="flex justify-end gap-1">
    <button
      type="submit"
      class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
      (click)="cancelSaveNotification()">
      Cancel
    </button>

    <button
      type="submit"
      [disabled]="notificationForm.invalid"
      [class.cursor-not-allowed]="notificationForm.invalid"
      class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blueDS-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blueDS-700 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
      [ngClass]="{ 'bg-blueDS-200': notificationForm.invalid }"
      (click)="saveNotification()">
      Save
    </button>
  </div>
</div>
