import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, switchMap } from 'rxjs';
import { EntityContact } from 'src/app/core/types/EntityContact.type';
import { PaginationMetadata } from 'src/app/core/types/shared/PaginationMetadata.type';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityContactService } from './data-access/entity-contact.service';
import { ENTITY_CONTACT_MODALS } from './enums/EntityContactModals.enum';
import {
  formatedEntityContactData,
  initEntityContactForm,
} from './helper/forms.helper';
import { type EntityContactModalTypes } from './types/EntityContactModalTypes.type';

@Component({
  selector: 'entity-contacts',
  templateUrl: './entity-contacts.component.html',
  providers: [EntityContactService],
})
export class EntityContactsComponent implements OnInit {
  private _entityContactService: EntityContactService =
    inject(EntityContactService);
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly _router: Router = inject(Router);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);

  entityId: string =
    this._activatedRoute.parent?.snapshot.paramMap.get('id') ?? '';
  isEntityContactSelected: boolean = false;
  entityContacts!: Partial<EntityContact>[];
  entityContactPaginationMetadata!: PaginationMetadata;
  entityContactForm!: FormGroup;
  isAddModalOpen: boolean = false;
  isDeleteModalOpen: boolean = false;
  isEditModalOpen: boolean = false;
  selectedEntityContact!: any;
  isAddExistingContactModalOpen: boolean = false;
  private _existingContacts!: Partial<EntityContact>[];

  ngOnInit(): void {
    this._router.events
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
        this.isEntityContactSelected =
          event.urlAfterRedirects.split('/').pop() !== 'view';
      });

    this._initEntityContactForm();

    this._getEntityContacts().subscribe(({ data, meta }) => {
      this.entityContacts = data;
      this.entityContactPaginationMetadata = meta;
    });

    this._getExistingContacts()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((data: EntityContact[]) => {
        this._existingContacts = data;
      });
  }

  openAddModal() {
    this._openModal(ENTITY_CONTACT_MODALS.ADD);
  }

  openDeleteModal(selectedEntityContact: Partial<EntityContact>) {
    this._openModal(ENTITY_CONTACT_MODALS.DELETE);
    this.selectedEntityContact = selectedEntityContact;
  }

  cancelAction(event?: boolean) {
    if (!event) return;

    this._resetFormsAndModals();
  }

  onSubmit(event: boolean) {
    if (!event) return;

    this._addEntityContact()
      .pipe(
        switchMap(() => {
          return this._getEntityContacts();
        })
      )
      .subscribe({
        next: ({ data }) => {
          this.entityContacts = data;
          this._resetFormsAndModals();
        },
      });
  }

  deleteEntityContact({ id: entityContactID }: EntityContact) {
    if (!entityContactID) return;

    return this._entityContactService
      .deleteEntityContact(entityContactID)
      .pipe(
        switchMap(() => {
          return this._getEntityContacts();
        })
      )
      .subscribe({
        next: ({ data }) => {
          this.entityContacts = data;
          this._resetFormsAndModals();
        },
      });
  }

  openExistingContactModal() {
    this._openModal(ENTITY_CONTACT_MODALS.ADD_EXISTING_CONTACT);
  }

  addExistingContact(event: number) {
    const existingContactPayload: Parameters<
      typeof this._entityContactService.addExistingContact
    >[0] = {
      gr_entity_contact_id: event,
      gr_entity_id: this.entityId,
    };
    this._addExistingContact(existingContactPayload).subscribe({
      next: () => {
        this._getEntityContacts().subscribe(({ data, meta }) => {
          this.entityContacts = data;
          this.entityContactPaginationMetadata = meta;
        });
      },
    });
  }

  private _initEntityContactForm() {
    initEntityContactForm.apply(this);
  }

  private _getEntityContacts(pageNumber: number = 1) {
    return this._entityContactService
      .getEntityContacts(pageNumber, this.entityId)
      .pipe(takeUntilDestroyed(this._destroyRef));
  }

  private _getExistingContacts() {
    return this._entityContactService.getExistingContacts();
  }

  private _addEntityContact() {
    return this._entityContactService.addEntityContact(
      this.formatedEntityContactData
    );
  }

  private _addExistingContact(
    existingContact: Parameters<
      typeof this._entityContactService.addExistingContact
    >[0]
  ) {
    return this._entityContactService.addExistingContact(existingContact);
  }

  private _openModal(modalType: EntityContactModalTypes) {
    switch (modalType) {
      case ENTITY_CONTACT_MODALS.DELETE:
        this.isDeleteModalOpen = true;
        break;

      case ENTITY_CONTACT_MODALS.ADD:
        this.isAddModalOpen = true;
        break;

      case ENTITY_CONTACT_MODALS.ADD_EXISTING_CONTACT:
        this.isAddExistingContactModalOpen = true;
        break;

      default:
        break;
    }
  }

  private _resetFormsAndModals(): void {
    this.entityContactForm.reset();
    this.isDeleteModalOpen = false;
    this.isAddModalOpen = false;
    this.isEditModalOpen = false;
    this.isAddExistingContactModalOpen = false;
  }

  get formatedEntityContactData(): unknown {
    return formatedEntityContactData.apply(this);
  }

  get filtredExistingContacts(): Partial<EntityContact>[] {
    const entityContactsIds: (number | undefined)[] = this.entityContacts?.map(
      ({ id }) => id
    );

    return this._existingContacts.filter(
      ({ id: existingContactId }) =>
        !entityContactsIds.includes(existingContactId)
    );
  }
}
