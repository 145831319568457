import { NgClass, NgIf, TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { StatusDirective } from 'src/app/core/directives/status/status.directive';

@Component({
  standalone: true,
  selector: 'app-title',
  templateUrl: './text.component.html',
  imports: [NgClass, NgIf, TitleCasePipe, StatusDirective],
})
export class TitleComponet {
  @Input({ required: true }) title!: string;
  @Input() description!: string;
}
