import { EntityService } from 'src/app/core/types/EntityService.type';

export function filterEntityService(
  entityServices: Partial<EntityService>[],
  property: keyof EntityService,
  condition: boolean
): Partial<EntityService>[] {
  return entityServices.filter(
    entityService => entityService[property] === condition
  );
}
