import { TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'app-service-start-date-form',
  templateUrl: './service-start-date-form.component.html',
  imports: [ReactiveFormsModule, TitleCasePipe],
})
export class ServiceStartDateFormComponent {
  @Input() control!: FormControl;
}
