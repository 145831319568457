import { NgIf } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { SharedComponentsModule } from 'src/app/components/components.module';
import { LoadingComponent } from 'src/app/components/shared/loading/loading.component';
import {
  ENTITY_CONTACT_FORM_ARRAYS,
  ENTITY_CONTACT_SERVICES_CONFORMATION_MESSAGES,
} from 'src/app/core/enums/EntityContact.enum';
import {
  EmailsForm,
  EntityContactFormArrays,
  EntityContactFormArraysTypes,
  EntityContactServices,
  EntityContactServicesTypes,
  PhonesForm,
  SMSForm,
} from 'src/app/core/types/EntityContact.type';
import { EntityContactFormComponent } from '../../components/entity-contact-form/entity-contact-form.component';
import { EntityContactServicesFormComponent } from '../../components/entity-contact-services-form/entity-contact-services-form.component';
import { EntityContactService } from '../../data-access/entity-contact.service';
import {
  addFormControl,
  formatedEntityContactData,
  initEntityContactForm,
  initEntityContactServicesForm,
} from '../../helper/forms.helper';

@Component({
  standalone: true,
  selector: 'app-entity-contact-details',
  templateUrl: './entity-contact-details.component.html',
  imports: [
    EntityContactFormComponent,
    EntityContactServicesFormComponent,
    NgIf,
    LoadingComponent,
    SharedComponentsModule,
  ],
  providers: [EntityContactService],
})
export class EntityContactDetailsComponent {
  private readonly _entityContactService: EntityContactService =
    inject(EntityContactService);
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);
  readonly _formBuilder: FormBuilder = inject(FormBuilder);
  private readonly _router: Router = inject(Router);

  readonly entityId: string = this._router.url.split('/')[3];
  readonly entityType: string =
    this._activatedRoute.snapshot.paramMap.get('entityType') ?? '';
  readonly entityContatId: string =
    this._activatedRoute.snapshot.paramMap.get('id') ?? '';
  entityContactServicesForm!: FormGroup;
  entityContactForm!: FormGroup;
  isEntityContactLoaded: boolean = false;
  isEntityContactServicesLoaded: boolean = false;
  entityContactServicesModalType: string = 'none';
  entityContactServicesModalMessage: string = '';
  selectedEntityContactService!: {
    formArrayName: EntityContactFormArraysTypes;
    control: AbstractControl;
  };
  entityContactServicesModalTitle: string = '';

  ngOnInit(): void {
    this._initEntityContactForm();
    this._initEntityContactServicesForm();
    this._getEntityContactById()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: entityContact => {
          this.isEntityContactLoaded = !!entityContact;
          this._fillEntityContactForm(entityContact);
        },
      });
    this._getEntityContactServices().subscribe({
      next: entityContactServices => {
        this.isEntityContactServicesLoaded = !!entityContactServices;
        this._fillEntityContactServicesForms(entityContactServices);
      },
    });
  }

  onContactFormSubmit(event: boolean) {
    if (!event) return;

    this._updateEntityContact()
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        switchMap(() => {
          return this._getEntityContactById();
        })
      )
      .subscribe({
        next: entityContact => {
          this._fillEntityContactForm(entityContact);
        },
      });
  }

  addFormControl(
    form: Parameters<typeof addFormControl>['0'],
    disabled: boolean = false
  ) {
    addFormControl.call(this, form, disabled);
  }

  addEntityContactService({
    name,
    index,
  }: {
    name: EntityContactFormArraysTypes;
    index: number;
  }) {
    this._addEntityContactService(name, index)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: _ => {
          this.isEntityContactServicesLoaded = false;
          this._clearEntityServicesFormArrays();
          this._getEntityContactServices().subscribe({
            next: entityContactServices => {
              this.isEntityContactServicesLoaded = !!entityContactServices;
              this._fillEntityContactServicesForms(entityContactServices, true);
            },
          });
        },
      });
  }

  deleteFormControl({
    name,
    index,
    saved,
  }: {
    name: EntityContactFormArraysTypes;
    index: number;
    saved: boolean;
  }) {
    if (saved) {
      this.entityContactServicesModalType = 'delete';
      this.entityContactServicesModalTitle = name;
    }

    switch (name) {
      case ENTITY_CONTACT_FORM_ARRAYS.EMAILS: {
        if (saved) {
          this.selectedEntityContactService = {
            formArrayName: name,
            control: this.emailsForm.at(index),
          };
        } else {
          this.emailsForm.removeAt(index);
        }
        break;
      }

      case ENTITY_CONTACT_FORM_ARRAYS.PHONES: {
        if (saved) {
          this.selectedEntityContactService = {
            formArrayName: name,
            control: this.phonesForm.at(index),
          };
        } else {
          this.phonesForm.removeAt(index);
        }
        break;
      }

      case ENTITY_CONTACT_FORM_ARRAYS.SMS: {
        if (saved) {
          this.selectedEntityContactService = {
            formArrayName: name,
            control: this.smsForm.at(index),
          };
        } else {
          this.smsForm.removeAt(index);
        }
        break;
      }

      default:
        break;
    }
  }

  deleteEntityContatService({
    formArrayName,
    control,
  }: typeof this.selectedEntityContactService) {
    this._deleteFormControl(formArrayName, control?.getRawValue().id);
  }

  updateEntityContactServicesStatus({
    formArrayName,
    targetControlName,
    index,
  }: any) {
    const control: AbstractControl = (
      this.entityContactServicesForm.get(formArrayName) as FormArray
    )?.controls[index];
    const isStatusOn: boolean = control.value[targetControlName] === 'on';

    this.entityContactServicesModalType = 'edit';
    this.entityContactServicesModalTitle = formArrayName;
    this.selectedEntityContactService = { formArrayName, control };

    if (formArrayName === 'emails') {
      if (isStatusOn) {
        this.entityContactServicesModalMessage =
          ENTITY_CONTACT_SERVICES_CONFORMATION_MESSAGES.EMAIL_ON;
      } else {
        this.entityContactServicesModalMessage =
          ENTITY_CONTACT_SERVICES_CONFORMATION_MESSAGES.EMAIL_OFF;
      }
    }

    if (formArrayName === 'sms') {
      if (isStatusOn) {
        this.entityContactServicesModalMessage =
          ENTITY_CONTACT_SERVICES_CONFORMATION_MESSAGES.SMS_ON;
      } else {
        this.entityContactServicesModalMessage =
          ENTITY_CONTACT_SERVICES_CONFORMATION_MESSAGES.SMS_OFF;
      }
    }

    if (formArrayName === 'phones') {
      this.entityContactServicesModalMessage =
        ENTITY_CONTACT_SERVICES_CONFORMATION_MESSAGES.PHONES;
    }
  }

  editEntityContactServices({
    formArrayName,
    control,
  }: typeof this.selectedEntityContactService) {
    this._entityContactService
      .updateEntityContactService(
        formArrayName,
        this._formatEntityContactServicesData(formArrayName, control.value)
      )
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: _ => {
          this.entityContactServicesModalType = 'none';
          this.isEntityContactServicesLoaded = false;
          this._clearEntityServicesFormArrays();
          this._getEntityContactServices().subscribe({
            next: entityContactServices => {
              this.isEntityContactServicesLoaded = !!entityContactServices;
              this._fillEntityContactServicesForms(entityContactServices, true);
            },
          });
        },
      });
  }

  cancelEntityContactServicesEvent() {
    this.entityContactServicesModalType = 'none';
    this.isEntityContactServicesLoaded = false;
    this._clearEntityServicesFormArrays();
    this._getEntityContactServices().subscribe({
      next: entityContactServices => {
        this.isEntityContactServicesLoaded = !!entityContactServices;
        this._fillEntityContactServicesForms(entityContactServices, true);
      },
    });
  }

  navigateToEntityContact(entityId: number | string = 'view') {
    this._router.navigate([`../${entityId}`], {
      relativeTo: this._activatedRoute,
    });
  }

  private _initEntityContactForm() {
    initEntityContactForm.apply(this);
  }

  private _initEntityContactServicesForm() {
    initEntityContactServicesForm.apply(this);
  }

  private _getEntityContactById() {
    return this._entityContactService.getEntityContactById(this.entityContatId);
  }

  private _getEntityContactServices() {
    return this._entityContactService
      .getEntityContactServices(this.entityContatId)
      .pipe(takeUntilDestroyed(this._destroyRef));
  }

  private _updateEntityContact() {
    return this._entityContactService.updateEntityContact(
      this.formatedEntityContactData
    );
  }

  private _fillEntityContactForm(entityContact: any) {
    Object.keys(this.entityContactForm.controls).forEach(key =>
      this.entityContactForm.controls[key].setValue(entityContact[key])
    );
  }

  private _fillEntityContactServicesForms(
    entityContactServices: EntityContactServices,
    reset: boolean = false
  ) {
    (
      Object.keys(entityContactServices) as EntityContactFormArraysTypes[]
    ).forEach(key => {
      entityContactServices[key]?.forEach(
        (entityContactService: any, index: number) => {
          reset &&
            this.deleteFormControl({ name: key, index: index, saved: false });

          this.addFormControl(
            {
              name: key,
              ...entityContactService,
            },
            true
          );
        }
      );
    });
  }

  private _addEntityContactService(
    entityContactServiceType: EntityContactFormArraysTypes,
    index: number
  ) {
    switch (entityContactServiceType) {
      case ENTITY_CONTACT_FORM_ARRAYS.EMAILS:
        return this._entityContactService.addEntityContactService(
          entityContactServiceType,
          this.formatedEntityContactEmailsData[index]
        );
      case ENTITY_CONTACT_FORM_ARRAYS.PHONES:
        return this._entityContactService.addEntityContactService(
          entityContactServiceType,
          this.formatedEntityContactPhonesData[index]
        );
      case ENTITY_CONTACT_FORM_ARRAYS.SMS:
        return this._entityContactService.addEntityContactService(
          entityContactServiceType,
          this.formatedAllEntityContactSMSData[index]
        );
      default:
        return of();
    }
  }

  private _deleteFormControl(
    name: EntityContactFormArraysTypes,
    entityContactServiceId: number
  ) {
    this._entityContactService
      .deleteEntityContactService(name, entityContactServiceId)
      .subscribe({
        next: _ => {
          this._getEntityContactServices().subscribe({
            next: _ => {
              this.entityContactServicesModalType = 'none';
              this.isEntityContactServicesLoaded = false;
              this._clearEntityServicesFormArrays();
              this._getEntityContactServices().subscribe({
                next: entityContactServices => {
                  this.isEntityContactServicesLoaded = !!entityContactServices;
                  this._fillEntityContactServicesForms(
                    entityContactServices,
                    true
                  );
                },
              });
            },
          });
        },
      });
  }

  private _clearEntityServicesFormArrays() {
    this.emailsForm.clear();
    this.phonesForm.clear();
    this.smsForm.clear();
  }

  private _formatedEntityContactEmailData({
    id,
    email,
    emailStatus,
    grEntityContactId,
  }: EmailsForm) {
    return {
      id,
      email,
      email_status: emailStatus,
      gr_entity_contact_id: grEntityContactId,
    };
  }

  private _formatedEntityContactPhoneData({
    id,
    number,
    type,
    grEntityContactId,
  }: PhonesForm) {
    return {
      id,
      number,
      type,
      gr_entity_contact_id: grEntityContactId,
    };
  }

  private _formatedEntityContactSMSData({
    id,
    number,
    smsStatus,
    grEntityContactId,
  }: SMSForm) {
    return {
      id,
      number,
      sms_status: smsStatus,
      gr_entity_contact_id: grEntityContactId,
    };
  }

  private _formatEntityContactServicesData(
    formArrayName: EntityContactFormArraysTypes,
    entityContact: EntityContactServicesTypes
  ) {
    switch (formArrayName) {
      case 'emails':
        return this._formatedEntityContactEmailData(
          entityContact as EmailsForm
        );
      case 'sms':
        return this._formatedEntityContactSMSData(entityContact as SMSForm);
      case 'phones':
        return this._formatedEntityContactPhoneData(
          entityContact as PhonesForm
        );
    }
  }

  get formatedEntityContactData(): unknown {
    return formatedEntityContactData.apply(this);
  }

  get formatedEntityContactEmailsData(): unknown[] {
    return this.emailsForm.value.map((emailsFormData: EmailsForm) => {
      return this._formatedEntityContactEmailData(emailsFormData);
    });
  }

  get formatedAllEntityContactSMSData(): unknown[] {
    return this.smsForm.value.map((smsFormData: SMSForm) => {
      return this._formatedEntityContactSMSData(smsFormData);
    });
  }

  get formatedEntityContactPhonesData(): unknown[] {
    return this.phonesForm.value.map((phonesFormData: PhonesForm) => {
      return this._formatedEntityContactPhoneData(phonesFormData);
    });
  }

  get emailsForm(): FormArray {
    return this.entityContactServicesForm.get('emails') as FormArray;
  }

  get smsForm(): FormArray {
    return this.entityContactServicesForm.get('sms') as FormArray;
  }

  get phonesForm(): FormArray {
    return this.entityContactServicesForm.get('phones') as FormArray;
  }

  get entityContactFormArrays(): EntityContactFormArrays[] {
    return [
      {
        name: ENTITY_CONTACT_FORM_ARRAYS.EMAILS,
        form: this.emailsForm,
        controls: [
          { name: 'email' },
          { name: 'emailStatus', options: ['on', 'off'] },
        ],
      },
      {
        name: ENTITY_CONTACT_FORM_ARRAYS.SMS,
        form: this.smsForm,
        controls: [
          { name: 'number' },
          { name: 'smsStatus', options: ['on', 'off'] },
        ],
      },
      {
        name: ENTITY_CONTACT_FORM_ARRAYS.PHONES,
        form: this.phonesForm,
        controls: [
          { name: 'number' },
          { name: 'type', options: ['home', 'work', 'cell'] },
        ],
      },
    ];
  }
}
