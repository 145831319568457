<!-- component -->

<div class="h-full w-full text-gray-700 p-6 flex flex-row">
  <nav class="w-48 p-3 border-r-2 h-full">
    <app-icon-button
      iconClass="icon-plus"
      title="Add Period"
      (click)="openAddPeriodModal()"></app-icon-button>
    <ng-container
      *ngFor="let serviceLog of serviceLogs | keyvalue; let index = index">
      <div class="w-full mb-2 mt-3">
        <app-icon-button
          iconClass="icon-circle-down"
          (click)="toggle(isYearly, serviceLog.key, index)"
          background="bg-transparent"
          additionalClass="flex items-center w-full py-2 px-5 font-medium text-gray-700 rounded-md hover:bg-gray-100"
          [title]="serviceLog.key"
          [conditionalClass]="{
            'bg-gray-300': serviceLogDisplayStatus[index][serviceLog.key],
          }"
          textColor="text-gray-700" />
        <ng-container *ngIf="serviceLogDisplayStatus[index][serviceLog.key]">
          <ng-container
            *ngFor="
              let monthOrQuarter of isMonthly
                ? serviceLog.value?.months
                : serviceLog.value?.quarters
            ">
            <a
              (click)="
                openServiceLog(isMonthly, false, {
                  monthOrQuarterOrYear: monthOrQuarter,
                  year: +serviceLog.key,
                })
              "
              class="hover:bg-gray-100 my-2 text-gray-700 block px-4 py-1 text-sm rounded-md cursor-pointer text-right"
              >{{ getMonthByNumber(isMonthly, monthOrQuarter) }}</a
            >
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </nav>
  <div class="flex-grow p-8" *ngIf="serviceLogInfo">
    <page-header
      pageHeader="{{
        getMonthByNumber(
          !!serviceLogInfo.services[0].month,
          serviceLogInfo.monthOrQuarter
        )
      }} {{ serviceLogInfo.year }}"></page-header>
    <div *ngIf="serviceLogInfo.services.length > 0" class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div
          class="inline-block min-w-[100%] py-2 align-middle md:px-6 lg:px-8">
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg max-w-3xl">
            <form [formGroup]="entityServiceLogForm">
              <table class="min-w-full divide-y divide-gray-300 table-fixed">
                <thead class="bg-gray-50">
                  <th
                    scope="col"
                    class="p-3 text-left font-[500] text-gray-500 uppercase">
                    Task
                  </th>
                  <th
                    scope="col"
                    class="p-3 text-left font-[500] text-gray-500 uppercase">
                    Status
                  </th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <ng-container
                    *ngFor="
                      let row of serviceLogInfo.services;
                      let index = index
                    ">
                    <tr class="text-sm">
                      <td class="p-3 min-w-[300px]">{{ row.name }}</td>
                      <td class="p-3 w-[180px] min-w-[180px]">
                        <select
                          [formControl]="
                            entityServiceLogStatusControlById(index, row.id)
                          "
                          class="w-full bg-custom-gray flex px-2 border-slate-300 rounded-md text-sm placeholder-slate-400 focus:border-blueDS-400 focus:ring-1 focus:ring-blueDS-500 cursor-pointer uppercase"
                          (change)="
                            updateEntityServiceLogStatus(index, row.id)
                          ">
                          @for (
                            status of entityServiceLogStatus;
                            track $index
                          ) {
                            <option [value]="status.toLocaleLowerCase()">
                              {{ status | titlecase }}
                            </option>
                          }
                        </select>
                      </td>
                      <td
                        class="flex py-3 w-[40px] justify-center text-blueDS-500">
                        <div
                          class="bg-gray-200 px-2 border border-slate-300 rounded-md text-sm text-gray-900 shadow-sm placeholder-slate-400 cursor-pointer focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-blueDS-500"
                          style="padding: 6px"
                          (click)="openUploadModel(row)">
                          <!-- Ajouter ce div pour l'icône -->
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="Upload">
                              <path
                                id="Icon"
                                d="M4 16L4 17C4 18.6569 5.34315 20 7 20L17 20C18.6569 20 20 18.6569 20 17L20 16M16 8L12 4M12 4L8 8M12 4L12 16"
                                stroke="#4B5563"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round" />
                            </g>
                          </svg>
                        </div>

                        <div
                          class="bg-blueDS-500 rounded-full px-1.5 text-white text-xs self-start -ml-2 cursor-pointer"
                          *ngIf="row.gr_uploads.length > 0">
                          {{ row.gr_uploads.length }}
                        </div>
                      </td>
                      <td
                        [class]="
                          row.sendNotif ? 'text-blueDS-500' : 'text-gray-400'
                        "
                        class="pl-3 pr-2 py-3 text-center">
                        Notifications
                      </td>
                      <td
                        [class]="
                          row.history ? 'text-blueDS-500' : 'text-gray-400'
                        "
                        class="px-2 py-3 text-center">
                        History
                      </td>
                      <td
                        [class]="
                          row.timer ? 'text-blueDS-500' : 'text-gray-400'
                        "
                        class="pl-2 pr-4 py-3 text-center">
                        Timer
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

@if (uploadModalStatus === 'open') {
  <modal [modalAreaWidth]="'860px'">
    <upload-files
      [title]="uploadModalTitle"
      [headerTitles]="headerTitles"
      [files]="files"
      [hasLoading]="hasLoading"
      (deleteFile)="openDeleteModal($event)"
      (uploadFiles)="onFileSelected($event)"
      (updateFileStatus)="updateFileStatus($event)"
      (cancel)="cancel($event)"
      (downloadFile)="downloadFile($event)" />
  </modal>
}

@if (deleteModalStatus === 'open') {
  <confirmation-modal
    [title]="deleteModalTitle"
    (emitCancel)="cancel($event, true)"
    (emitDelete)="deleteFile()" />
}

@if (addPeriodModalStatus === 'open') {
  <modal [modalAreaWidth]="'560px'">
    <div step-content class="p-2 flex flex-col gap-6">
      <h2 class="text-xl text-blueDS-600">Add Period</h2>
      <app-date-period-selector
        [formGroup]="addPeriodForm"
        [isMonthly]="isMonthly"
        [isYearly]="isYearly"
        [monthOrQuarter]="monthOrQuarterByNumber" />

      <div class="pt-5">
        <div class="flex justify-end gap-1">
          <button
            type="submit"
            class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
            (click)="cancelAddPeriod()">
            Cancel
          </button>

          <button
            type="submit"
            [disabled]="addPeriodForm.invalid"
            [class.cursor-not-allowed]="addPeriodForm.invalid"
            class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blueDS-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blueDS-700 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
            [ngClass]="{ 'bg-blueDS-300': addPeriodForm.invalid }"
            (click)="addPeriod()">
            Save
          </button>
        </div>
      </div>
    </div>
  </modal>
}
