<div class="w-full h-24 flex flex-row py-4 px-2 justify-between">
  <div class="flex flex-row gap-4 self-end">
    <select-block label="Status" [options]="status"></select-block>
    <select-block label="Month" [options]="month"></select-block>
    <select-block label="Year" [options]="year"></select-block>
  </div>
  <div class="flex flex-row gap-4 self-end">
    <button
      type="button"
      class="bg-white border border-gray-300 rounded-md w-36 text-gray-700 font-[500] h-[42px]">
      Master Invoice
    </button>
    <button
      type="button"
      class="bg-white border border-gray-300 rounded-md w-36 text-gray-700 font-[500] h-[42px]">
      Import
    </button>
    <app-icon-button
      iconClass="icon-plus"
      title="Add Invoice"
      class="self-end"></app-icon-button>
  </div>
</div>

<div class="px-2">
  <table-basic
    [setWidth]="48"
    [isRowClickable]="true"
    [tableData]="invoiceData"></table-basic>
</div>
