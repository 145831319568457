import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Officer } from './entity-officers.model';
import { OfficerService } from './entity-officers.service';

type ModalState = 'none' | 'edit' | 'delete' | 'add';

@Component({
  selector: 'entity-officers',
  templateUrl: './entity-officers.component.html',
  styleUrls: ['./entity-officers.component.css'],
})
export class EntityOfficersComponent implements OnInit {
  selectedOfficer: Officer | undefined;
  officerForm!: FormGroup;
  public officerData: any = [];
  public columnsToDisplay = [{ key: 'fullName', value: 'officer Name' }];
  entityId: string = '';
  modalState: ModalState = 'none';

  constructor(
    private fb: FormBuilder,
    private officerService: OfficerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.entityId = this.route.parent?.snapshot.paramMap.get('id') || '';
    this.initOfficersList();
  }

  initOfficersList() {
    this.officerService
      .getOfficers(this.entityId)
      .subscribe(officers => (this.officerData = officers));
    this.initForms();
  }

  public openModal(state: ModalState, officer = undefined) {
    (this.modalState = state), (this.selectedOfficer = officer);
  }

  deleteOfficer(officer: Officer) {
    if (officer.id) {
      this.officerService.deleteOfficer(officer.id).subscribe(() => {
        this.modalState = 'none';
        this.initOfficersList();
      });
    }
  }

  initForms(officer: Officer | undefined = undefined) {
    this.officerForm = this.fb.group({
      firstname: [officer?.firstname, Validators.required],
      middlename: [officer?.middlename],
      lastname: [officer?.lastname, Validators.required],
      title: [officer?.title],
      date_of_birth: [officer?.date_of_birth],
      ssn: [officer?.ssn],
      dln: [officer?.dln],
      phone: [officer?.phone],
      email: [officer?.email],
      address: [officer?.address],
      city: [officer?.city],
      state: [officer?.state],
      zip: [officer?.zip],
      country: [officer?.country],
      gr_entity_id: [this.entityId],
      id: [officer?.id],
    });
  }

  public openEditModal(officer: any) {
    this.initForms(officer);
    this.openModal('edit', officer);
  }

  isRequired(controlName: string): boolean {
    const control = this.officerForm.get(controlName);
    return control?.errors && control?.errors['required'];
  }

  onSubmit() {
    const officer = Object.entries(this.officerForm.value).reduce(
      (a, [k, v]) => (v === null || v === '' ? a : { ...a, [k]: v }),
      {}
    );
    this.officerService[
      this.modalState === 'edit' ? 'updateOfficer' : 'createOfficer'
    ](officer).subscribe(() => {
      this.modalState = 'none';
      this.initOfficersList();
    });
  }
}
