<div [ngClass]="isHorizontal ? 'flex flex-row items-center gap-10' : ''">
  <div
    [ngClass]="setLabelWidth ? 'w-' + setLabelWidth : ''"
    class="flex block text-sm font-medium text-gray-700">
    {{ label }}
  </div>
  <div class="flex items-center">
    <!-- Ajouter ce div -->
    <input
      [ngClass]="setInputWidth ? 'w-' + setInputWidth : ''"
      [type]="type"
      [(ngModel)]="value"
      (ngModelChange)="onChange($event)"
      (blur)="onTouched()"
      [ngClass]="isEditMode ? 'bg-custom-gray' : 'bg-white'"
      class="mt-1 mr-1 block min-w-[200px] w-full px-3 py-2 border border-slate-300 rounded-md text-sm text-gray-900 shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-blueDS-500" />
    <div
      *ngIf="IsuploadSupport"
      class="mt-1 bg-gray-200 px-2 border border-slate-300 rounded-md text-sm text-gray-900 shadow-sm placeholder-slate-400 cursor-pointer focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-blueDS-500"
      style="padding: 5px"
      (click)="upload()">
      <!-- Ajouter ce div pour l'icône -->
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Upload">
          <path
            id="Icon"
            d="M4 16L4 17C4 18.6569 5.34315 20 7 20L17 20C18.6569 20 20 18.6569 20 17L20 16M16 8L12 4M12 4L8 8M12 4L12 16"
            stroke="#4B5563"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </g>
      </svg>
    </div>

    <div
      class="bg-blueDS-500 rounded-full px-1.5 text-white text-xs self-start -ml-4 cursor-pointer"
      *ngIf="IsuploadSupport && uploadCounter > 0">
      {{ uploadCounter }}
    </div>
    <!-- Fermer le div pour l'icône -->
  </div>
  <!-- Fermer le div pour le conteneur flex -->
</div>
