<form
  [formGroup]="formGroup"
  class="space-y-4 divide-y divide-gray-200 flex flex-col">
  <div>
    <h1 class="text-lg font-medium leading-6 text-gray-900">
      {{ isEditMode ? 'Edit' : 'Add' }} Note
    </h1>
  </div>
  <div class="flex flex-row items-start w-full">
    <div class="flex flex-col gap-3 w-full">
      <textarea
        formControlName="content"
        placeholder="Start typing..."
        class="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6">
      </textarea>
    </div>
  </div>

  <div class="flex justify-end pt-4">
    @if (!isEditMode) {
      <button
        type="button"
        class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
        (click)="onCancel()">
        Cancel
      </button>
    }
    <button
      type="button"
      (click)="onSubmit()"
      [disabled]="formGroup.invalid"
      [class.cursor-not-allowed]="formGroup.invalid"
      class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blueDS-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blueDS-700 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2">
      Save
    </button>
  </div>
</form>
