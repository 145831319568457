<div class="h-96">
  <div class="flex gap-3">
    <button
      class="px-5"
      [ngClass]="{
        'tab-item': activeTab == TAB.EMAIL,
      }"
      (click)="activeTab = TAB.EMAIL">
      Email
      <app-tooltip
        *ngIf="activeTab == TAB.EMAIL"
        class="tooltip"
        [title]="emailTooltipMessage"
        icon="&#9432;" />
    </button>
    <button
      class="px-5"
      [ngClass]="{
        'tab-item': activeTab == TAB.SMS,
      }"
      (click)="activeTab = TAB.SMS">
      SMS
      <app-tooltip
        *ngIf="activeTab == TAB.SMS"
        class="tooltip"
        [title]="smsTooltipMessage"
        icon="&#9432;" />
    </button>
  </div>

  <div class="border-2 h-96 border-gray-100 p-3">
    @if (activeTab === TAB.EMAIL) {
      <app-communications-notification-form [formGroup]="notificationForm" />
      <app-editor
        [ngClass]="'h-full'"
        [(onHtml)]="emailContent"
        (onHtmlChange)="editorChange($event)"
        (dblclick)="editorClicked()" />
    }
    @if (activeTab === TAB.SMS) {
      <app-communications-notification-form
        [formGroup]="notificationForm"
        [isSMS]="true" />
      <textarea
        [formControl]="smsFormControl"
        class="w-full h-80 resize-none block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6"
        (dblclick)="editorClicked()"></textarea>
    }
  </div>
</div>
