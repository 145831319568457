<div class="flex flex-col h-full justify-between">
  <search
    (emitFilteredData)="setFilteredData($event)"
    [originalData]="originalData"
    [filteredData]="filteredData"
    [columnsToDisplay]="columnsToDisplay"></search>

  <div class="h-full flex-1">
    <div
      class="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-4">
      <table class="min-w-full divide-y divide-gray-300 table-auto">
        <thead class="bg-gray-50">
          <tr>
            <ng-container *ngFor="let data of columnsToDisplay; let i = index">
              <th
                (click)="sortColumns(filteredData, data.key)"
                [ngClass]="i > 2 ? 'text-center' : 'text-left'"
                class="font-[500] pl-4 pr-0 py-2 text-sm text-gray-900 uppercase cursor-pointer">
                {{ data.value | camelCaseToTitleSeperatedCase }}
              </th>
            </ng-container>
            <ng-container *ngFor="let action of actionColumns">
              <th
                *ngIf="action"
                scope="col"
                class="pl-4 pr-0 py-2 text-center w-40">
                <span class="sr-only">Header for action columns</span>
              </th>
            </ng-container>
            <th
              *ngIf="editAction"
              scope="col"
              class="px-0 py-2 text-center w-20">
              <span class="sr-only">Header for Edit</span>
            </th>
            <th
              *ngIf="deleteAction"
              scope="col"
              class="px-0 py-2 text-center w-20">
              <span class="sr-only">Header for Delete</span>
            </th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200 bg-white">
          <ng-container
            *ngFor="let rowData of filteredData | slice: startSlice : endSlice">
            <tr>
              <ng-container
                *ngFor="let column of columnsToDisplay; let i = index">
                <td
                  *ngIf="i === 0"
                  class="text-gray-900 font-[500] pl-4 pr-0 py-2 text-sm">
                  <input
                    type="text"
                    (keyup)="updateChange(rowData)"
                    [(ngModel)]="rowData[column.key]"
                    [readonly]="isInputsReadonly"
                    class="w-full block px-2 py-2 bg-custom-gray border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-blueDS-500" />
                </td>
                <td
                  *ngIf="i === 1 && dropdownOptions.length > 0"
                  class="text-gray-500 font-[500] pl-4 pr-0 py-2 text-sm">
                  <p *ngIf="isInputsReadonly">{{ rowData[column.key] }}</p>
                  <select
                    *ngIf="!isInputsReadonly"
                    class="bg-custom-gray flex px-2 border-slate-300 rounded-md text-sm placeholder-slate-400 focus:border-blueDS-400 focus:ring-1 focus:ring-blueDS-500 cursor-pointer w-full"
                    [class.uppercase]="upperCaseSecondColumn"
                    [class.capitalize]="!upperCaseSecondColumn"
                    [(ngModel)]="rowData[column.key]"
                    (change)="updateChange(rowData)">
                    <ng-container *ngFor="let option of dropdownOptions">
                      <option [selected]="option === rowData[column.key]">
                        {{ option }}
                      </option>
                    </ng-container>
                  </select>
                </td>

                <td
                  *ngIf="i === 2"
                  class="pl-4 pr-0 py-2 text-sm text-left w-40">
                  <span class="text-gray-500 font-[500] text-sm">{{
                    rowData[column.key]
                  }}</span>
                </td>

                <td
                  *ngIf="i === 3"
                  class="pl-4 pr-0 py-2 text-sm text-center w-40">
                  <select
                    class="bg-custom-gray flex px-2 border-slate-300 rounded-md text-sm placeholder-slate-400 focus:border-blueDS-400 focus:ring-1 focus:ring-blueDS-500 cursor-pointer w-full uppercase"
                    [(ngModel)]="rowData[column.key]"
                    (change)="updateChange(rowData)">
                    <ng-container *ngFor="let option of customFieldOption">
                      <option [ngValue]="option">
                        {{ option === true ? 'YES' : 'NO' }}
                      </option>
                    </ng-container>
                  </select>
                </td>
              </ng-container>

              <ng-container *ngFor="let action of actionColumns">
                <td class="pl-4 pr-0 py-2 text-sm text-center w-40">
                  <span
                    (click)="customAction(action, rowData)"
                    class="text-blueDS-500 hover:text-blueDS-400 cursor-pointer font-[400]"
                    >{{ action }}</span
                  >
                </td>
              </ng-container>

              <td
                *ngIf="editAction && !isCustomField"
                class="pl-4 pr-0 py-2 text-sm text-center w-20">
                <span
                  (click)="rowEdit(rowData)"
                  class="text-blueDS-500 hover:text-blueDS-400 cursor-pointer font-[400]"
                  >Edit</span
                >
              </td>
              <td
                *ngIf="
                  editAction &&
                  isCustomField &&
                  rowData['input_type'] !== 'select'
                "
                scope="col"
                class="px-0 py-2 text-center w-20">
                <span class="sr-only">Edit</span>
              </td>

              <td
                *ngIf="
                  editAction &&
                  isCustomField &&
                  rowData['input_type'] === 'select'
                "
                class="pl-2 pr-0 py-2 text-sm text-center">
                <span
                  (click)="rowEdit(rowData)"
                  class="text-blueDS-500 hover:text-blueDS-400 cursor-pointer font-[400]"
                  >Options</span
                >
              </td>
              <td *ngIf="deleteAction" class="px-0 py-2 text-sm text-center">
                <span
                  *ngIf="!useTrashCanIcon"
                  (click)="rowDelete(rowData)"
                  class="text-red-600 hover:text-red-400 cursor-pointer font-[400]"
                  >Delete</span
                >

                <span
                  *ngIf="useTrashCanIcon"
                  aria-hidden="true"
                  (click)="rowDelete(rowData)"
                  class="icon-trash text-gray-600 font-[700] w-[24px] h-[24px] text-xl cursor-pointer"></span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div>
    <pagination
      [dataCount]="this.filteredData.length"
      [itemsPerPage]="itemsPerPage"
      (setPageToDisplay)="setItemsDisplay($event)"></pagination>
  </div>
</div>
