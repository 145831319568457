export function generateDownloadURL({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}): void {
  _urlToBlob(url)
    .then(blobURL => {
      _downloadFile(blobURL, fileName);
    })
    .catch(error => {
      throw new Error(error);
    });
}

function _urlToBlob(url: string): Promise<string> {
  if (!url) {
    throw new Error('Resource URL not provided! You need to provide one');
  }

  return fetch(url)
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .catch(error => {
      throw new Error('Error fetching blob: ' + error);
    });
}

function _downloadFile(blobURL: string, fileName: string): void {
  if (!blobURL) return;

  const link = document.createElement('a');

  link.href = blobURL;
  link.download = fileName;
  link.click();
}
