import { Component, ErrorHandler, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { PAGE_TYPE } from 'src/app/components/shared/upload-files/enums/PageType.enum';
import { FileManagement } from 'src/app/components/shared/upload-files/helper/file-management';
import { PageType } from 'src/app/components/shared/upload-files/types/PageType';

@Component({
  selector: 'entity-profile',
  templateUrl: './entity-profile.component.html',
  styleUrls: ['./entity-profile.component.css'],
})
export class EntityProfileComponent extends FileManagement implements OnInit {
  private readonly _fb: FormBuilder = inject(FormBuilder);
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly _errorHandler: ErrorHandler = inject(ErrorHandler);

  entityId!: string;
  entityForm!: FormGroup;
  entity_types: Array<any> = [];
  users = [{ id: 1, name: 'oprion 1' }];
  form!: FormGroup;
  entityPropertiesGroupe: any;
  override pageType: PageType = PAGE_TYPE.PROFILE;

  ngOnInit() {
    this.entityId =
      this._activatedRoute.parent?.snapshot.paramMap.get('id') ?? '';
    this.entityForm = this._fb.group({
      entity_name: [],
      et_id: [],
    });

    this.loadEntityTypes();
    this.loadEntity().subscribe(groups => {
      this.entityPropertiesGroupe = this.groupList(groups);
    });
    this.handelEntityInfoUpdate();
  }

  getClass(length: number, i: number) {
    if (length > 4) return 'w-1/2';
    return length === 2 && i === 0 ? 'w-2/3' : 'w-1/3';
  }

  groupList(data: any) {
    const output: any = [];
    this.form = this._fb.group({});
    data.data.forEach((item: any) => {
      let groupName = item.name;
      let group = output.find((group: any) => group.groupName === groupName);
      if (!group) {
        group = { groupName: groupName, propertiesList: [] };
        output.push(group);
      }

      item.gr_properties_to_entity_types.forEach((prop: any) => {
        this.initPropertyForm(prop);
        let grProperty = prop.gr_property;
        let propertyInfo = {
          id: grProperty?.id,
          name: grProperty?.name,
          input_type: grProperty?.input_type,
          default_value: grProperty?.default_value,
          gr_entity_type_id: prop?.gr_entity_type_id,
          upload_support: grProperty?.upload_support,
          options: this.formatOptions(grProperty?.options),
          zone_id: prop.zone_id,
          uploader_id: this.entityId,
          shared_upload: 0,
          gr_uploads: prop.gr_uploads,
          property_entity: prop.id,
        };

        group.propertiesList.push(propertyInfo);
      });
    });

    return output;
  }

  formatOptions(options: any) {
    return options?.map((option: any) => {
      return { id: option?.id, name: option?.select_option };
    });
  }

  initPropertyForm(property: any) {
    const control = this._fb.control(
      property?.gr_entity_properties[0]?.value || ''
    );
    let grProperty = property.gr_property;
    this.form.addControl(grProperty?.name, control);
    control.valueChanges.subscribe(val => {
      const change = {
        entity_id: this.entityId,
        property_id: grProperty?.id,
        prope_entity_type: property.id,
        value: val,
      };

      this.updateProperty(change);
    });
  }

  updateProperty(changes: any) {
    this._entitiesService.updateEntityPropertyValue(changes).subscribe();
  }

  private handelEntityInfoUpdate() {
    this.entityForm.valueChanges.subscribe(values => {
      this._entitiesService.updateEntity(this.entityId, values).subscribe();
    });
  }

  private loadEntityTypes() {
    this._entitiesService
      .getEntitiesTypes()
      .subscribe(types => (this.entity_types = types));
  }

  private loadEntity() {
    return this._entitiesService.getEntity(this.entityId).pipe(
      switchMap(entity => {
        this.entityForm.patchValue(entity);
        return this._entitiesService.getDynamicEntityForm(
          entity.et_id,
          this.entityId
        );
      })
    );
  }

  override _completeUploadAndRefresh(observable$: Observable<any>) {
    this.deleteModalStatus = 'close';
    this.fileToDelete = null;

    return observable$
      .pipe(
        switchMap(() => {
          this.loadEntityTypes();
          return this.loadEntity();
        })
      )
      .subscribe({
        next: groups => {
          this.entityPropertiesGroupe = this.groupList(groups);

          const selectedItem = this.entityPropertiesGroupe
            .flatMap((group: any) => group.propertiesList)
            .find(
              (property: any) =>
                property.id === this._selectedItem?.property_id &&
                property.zone_id === this._selectedItem?.zone_id
            );

          // gr_property_group_id
          this._selectedItem = {
            property_id: selectedItem.id,
            zone_id: selectedItem?.zone_id || selectedItem.zoneId,
            uploader_id: String(
              selectedItem.uploader_id || selectedItem.uploaderId
            ),
            shared_upload:
              selectedItem.shared_upload ?? selectedItem.sharedUpload,
            property_entity:
              selectedItem.property_entity ?? selectedItem.property_entity,
          } as any;
          this.files = selectedItem?.gr_uploads;

          this.hasLoading = false;
        },
        error: error => {
          this.hasLoading = false;
          this._errorHandler.handleError(error);
        },
      });
  }
}
