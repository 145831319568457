import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { EntityService, Service } from 'src/app/core/types/EntityService.type';
import ResponseData from 'src/app/core/types/shared/Response.type';
import { environment } from 'src/environments/environment';
import { formatedDate } from '../helper/formatDate.helper';
import {
  formatEntityServieData,
  formatServieData,
} from '../helper/formatEntityServieData.helper';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  private readonly _http: HttpClient = inject(HttpClient);
  private readonly _datePipe: DatePipe = inject(DatePipe);

  private readonly baseApiUrl = environment.baseApiUrl;

  entityServices$: BehaviorSubject<Partial<EntityService>[] | null> =
    new BehaviorSubject<Partial<EntityService>[] | null>(null);

  getAllServices(entityId?: string): Observable<Partial<EntityService>[]> {
    return this._http
      .get<
        ResponseData<any>
      >(`${this.baseApiUrl}services${entityId ? '?entity_id=$' + entityId + '&isNotOngoing=true' : ''}`)
      .pipe(
        map(response => {
          return response.data.map((entityService: any) => ({
            ...entityService,
            ...formatEntityServieData(entityService),
            startDate: entityService.start_date
              ? this._datePipe.transform(
                  formatedDate(entityService.start_date),
                  'MMMM yyyy'
                )
              : '--',
            endDate: entityService.end_date
              ? this._datePipe.transform(
                  formatedDate(entityService.end_date),
                  'MMMM yyyy'
                )
              : '--',
          }));
        })
      );
  }

  getAllEntityServices(entityId: string): Observable<Partial<EntityService>[]> {
    return this._http
      .get<ResponseData<any>>(`${this.baseApiUrl}entity-services/${entityId}`)
      .pipe(
        map(response => {
          return response.data.map((entityService: any) => ({
            ...entityService,
            ...formatEntityServieData(entityService),
            startDate: entityService.start_date
              ? this._datePipe.transform(
                  formatedDate(entityService.start_date),
                  'MMMM yyyy'
                )
              : '--',
            endDate: entityService.end_date
              ? this._datePipe.transform(
                  formatedDate(entityService.end_date),
                  'MMMM yyyy'
                )
              : '--',
          }));
        }),
        tap(entityServices => {
          this.entityServices$.next(entityServices);
        })
      );
  }

  getServiceById(serviceId: number): Observable<Service> {
    return this._http
      .get<{ data: Service }>(`${this.baseApiUrl}service-tasks/${serviceId}`)
      .pipe(
        map(({ data }) => {
          return formatServieData(data);
        })
      );
  }

  saveService(service: unknown): Observable<unknown> {
    return this._http.post<unknown>(
      `${this.baseApiUrl}entity-service`,
      service
    );
  }

  getEntityServiceById(entityServiceId: number): Observable<EntityService> {
    return this._http
      .get<any>(`${this.baseApiUrl}entity-service/${entityServiceId}`)
      .pipe(
        map(({ data }) => ({
          ...data,
          ...formatEntityServieData(data),
          startDate: this._datePipe.transform(data.start_date, 'MMMM yyyy'),
          endDate: this._datePipe.transform(data.end_date, 'MMMM yyyy'),
        }))
      );
  }

  updateEntityService(entityService: EntityService): Observable<unknown> {
    return this._http.put<EntityService>(
      `${this.baseApiUrl}entity-service/${entityService.id}`,
      entityService
    );
  }

  deleteEntityService(entityServiceId: number): Observable<unknown> {
    return this._http.delete<boolean>(
      `${this.baseApiUrl}entity-service/${entityServiceId}`
    );
  }

  addEntityServiceTask(
    entityServiceId: number,
    tasks: Record<'tasks', number[]>
  ) {
    return this._http.post<boolean>(
      `${this.baseApiUrl}entity-service-task/${entityServiceId}`,
      tasks
    );
  }
}
