import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { PAGE_TYPE } from 'src/app/components/shared/upload-files/enums/PageType.enum';
import { FileRecord } from 'src/app/components/shared/upload-files/types/FileRecord.type';
import { PageType } from 'src/app/components/shared/upload-files/types/PageType';
import {
  EntityServiceLog,
  EntityServiceLogInfo,
  EntityServiceLogInfoService,
} from 'src/app/core/types/EntityService.type';
import { Periodicity } from 'src/app/core/types/shared/Periodicity.type';
import ResponseData from 'src/app/core/types/shared/Response.type';
import { environment } from '../../../environments/environment';
import { ServiceLogStatus } from './entity/entity-services/entity-service-log/types/ServiceLogStatus.type';

@Injectable({
  providedIn: 'root',
})
export class EntitiesService {
  private baseApiUrl = environment.baseApiUrl;

  constructor(private http: HttpClient) {}

  public getEntities(status?: string) {
    const url = status
      ? `${this.baseApiUrl}entities?status=${status}`
      : `${this.baseApiUrl}entities`;
    return this.http.get<any>(url).pipe(map((response: any) => response.data));
  }

  public getEntitiesTypes() {
    return this.http.get<any>(`${this.baseApiUrl}entity-types`);
  }

  public getEntity(entityId: string) {
    return this.http
      .get<any>(`${this.baseApiUrl}entities/${entityId}`)
      .pipe(map((response: any) => response.data[0]));
  }

  public createEntity(entity: any) {
    return this.http.post<any>(`${this.baseApiUrl}entities`, entity);
  }

  public updateEntity(entityId: string, entity: any) {
    const payload = {
      gr_entity_type_id: entity.et_id,
      entity_name: entity.entity_name,
    };
    return this.http.put<any>(
      `${this.baseApiUrl}entities/${entityId}`,
      payload
    );
  }

  public getDynamicEntityForm(et_id: string, entityId: string) {
    return this.http.get<any>(
      `${this.baseApiUrl}entities/entity-profile/${et_id}?entity_id=${entityId}`
    );
  }

  public updateEntityPropertyValue(payload: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseApiUrl}entities/entity-property-value`,
      payload
    );
  }

  stopEntityService(
    entityServiceId: number,
    endDate: { end_date: Date }
  ): Observable<any> {
    return this.http.put<any>(
      `${this.baseApiUrl}entity-service/${entityServiceId}/stop`,
      endDate
    );
  }

  deleteEntityServiceTask(
    entityServiceTaskId: number,
    entityServiceId: number = 0
  ): Observable<unknown> {
    return this.http.delete(
      `${this.baseApiUrl}entity-service-task/${entityServiceTaskId}/${entityServiceId}`
    );
  }

  getServiceLog(entityId: string): Observable<EntityServiceLog> {
    return this.http.get<EntityServiceLog>(
      `${this.baseApiUrl}service-logs-yearly?gr_entity_service_id=${entityId}`
    );
  }

  getServiceLogByInfo(
    entityId: string,
    logInfo: { month?: number; quarter?: number; year: number }
  ): Observable<EntityServiceLogInfo> {
    let query: string;
    if (!logInfo.month && !logInfo.quarter) {
      query = `&year=${logInfo.year}`;
    } else {
      query =
        (logInfo.month
          ? `&month=${logInfo.month}`
          : `&quarter=${logInfo.quarter}`) + `&year=${logInfo.year}`;
    }

    return this.http
      .get<
        ResponseData<any>
      >(`${this.baseApiUrl}service-logs?entity_service=${entityId}${query}`)
      .pipe(
        map(({ data: serviceLogInfo }) => {
          return {
            year: serviceLogInfo[0].year,
            monthOrQuarter:
              serviceLogInfo[0]?.month || serviceLogInfo[0]?.quarter,
            services: serviceLogInfo.map(serviceLogInfo =>
              this._formatEntityerviceLogInfoServiceData(serviceLogInfo)
            ),
          };
        })
      );
  }

  uploadFiles(
    pageType: PageType,
    payload: { files: File[] } & FileRecord
  ): Observable<unknown> {
    if (!pageType) {
      throw new Error('pageType is required');
    }

    let uploadPath = '';

    switch (pageType) {
      case PAGE_TYPE.PROFILE:
        uploadPath = 'entity-property';
        break;
      case PAGE_TYPE.SERVICE_LOG:
        uploadPath = 'service-log';
        break;
      case PAGE_TYPE.PROPERTY_SERVICE:
        uploadPath = 'property-service';
        break;
      case PAGE_TYPE.ENTITY:
        uploadPath = 'entity-generale';
        break;
      default:
        throw new Error(`Unsupported page type: ${pageType}`);
    }

    const url = `${this.baseApiUrl}upload-${uploadPath}`;

    return this.http.post<ResponseData<any>>(url, payload);
  }

  updateFileData(
    pageType: PageType,
    fileId: number,
    payload: {
      uploadName?: string;
      visibleToClient?: boolean;
      attachByDefault?: boolean;
    }
  ): Observable<unknown> {
    const fileInfo = {
      visible_to_client: payload.visibleToClient,
      attach_by_default: payload.attachByDefault,
    };
    return this.http.put<ResponseData<any>>(
      `${this.baseApiUrl}upload/${fileId}`,
      fileInfo
    );
  }

  deleteUploadedFile(fileId: number): Observable<unknown> {
    return this.http.delete<ResponseData<any>>(
      `${this.baseApiUrl}upload/${fileId}`
    );
  }

  updateEntityServiceLog(
    entityServiceLogId: number,
    status: ServiceLogStatus
  ): Observable<unknown> {
    return this.http.put<ResponseData<any>>(
      `${this.baseApiUrl}service-logs/${entityServiceLogId}`,
      {
        task_status: status,
      }
    );
  }

  addServiceLogPeriod(payload: {
    entity: string;
    entity_service: number;
    service_periodicity: Periodicity;
    quarter?: number | undefined;
    start_date: Date;
  }): Observable<unknown> {
    return this.http.post<ResponseData<any>>(
      `${this.baseApiUrl}service-logs`,
      payload
    );
  }

  getEntityServiceCustomFields(serviceId: number, entityId: string) {
    return this.http.get<ResponseData<any>>(
      `${this.baseApiUrl}entity-service-profile/${serviceId}?entity_id=${entityId}`
    );
  }

  updateServicePropertyValue(payload: any): Observable<any> {
    return this.http.post<any>(
      `${this.baseApiUrl}entities/service-property-value`,
      payload
    );
  }

  getEntityGeneralUploads(entityId: string) {
    return this.http
      .get<{
        data: { zone_id: string; uploads: [] };
      }>(`${this.baseApiUrl}entities/${entityId}/uploads`)
      .pipe(
        map(({ data }) => ({
          ...data,
          gr_uploads: data.uploads,
        }))
      );
  }

  private _formatEntityerviceLogInfoServiceData({
    created_at,
    deadline,
    entity_id,
    deleted_at,
    entity_service_id,
    entity_service_task_id,
    service_id,
    service_task_id,
    task_status,
    updated_at,
    year,
    id,
    month,
    name,
    quarter,
    history,
    send_notif,
    timer,
    zone_id,
    gr_uploads,
  }: any): EntityServiceLogInfoService {
    return {
      createdAt: created_at,
      deadline: deadline,
      deletedAt: deleted_at,
      entityId: entity_id,
      entityServiceId: entity_service_id,
      entityServiceTaskId: entity_service_task_id,
      id,
      month,
      name,
      quarter,
      serviceId: service_id,
      serviceTaskId: service_task_id,
      taskStatus: task_status,
      updatedAt: updated_at,
      updatedBy: created_at,
      year,
      history,
      sendNotif: send_notif,
      timer,
      zoneId: zone_id,
      uploaderId: id,
      sharedUpload: true,
      gr_uploads,
    };
  }
}
