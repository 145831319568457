<form [formGroup]="formGroup">
  <div class="flex flex-col w-full pt-8">
    @for (formArray of entityContactFormArrays; track $index) {
      <div class="flex flex-col gap-2 mt-6 w-full items-start px-4">
        <label
          class="w-1/4 text-gray-900 font-semibold"
          [for]="formArray.name"
          >{{ formArray.name | uppercase }}</label
        >

        @for (control of formArray.form.controls; track $index) {
          <div [formArrayName]="formArray.name">
            <div
              [formGroupName]="$index"
              class="flex justify-between items-center">
              <div class="flex flex-col">
                <div class="relative mt-2 rounded-md shadow-sm flex">
                  <input
                    type="text"
                    [formControlName]="formArray.controls[0].name"
                    [placeholder]="'Enter ' + formArray.name | titlecase"
                    class="block w-[250px] border-slate-300 rounded-r-none rounded-md py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-600 sm:text-sm sm:leading-6"
                    [ngClass]="{
                      ' border-red-500': errorMessage(
                        getFormControlByName(
                          formArray.name,
                          formArray.controls[0].name,
                          $index
                        )
                      ),
                    }" />
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    <select
                      [formControlName]="formArray.controls[1].name"
                      [ariaPlaceholder]=""
                      class="h-full w-[80px] rounded-md border-slate-300 rounded-l-none py-0 pl-2 pr-7 text-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-600 sm:text-sm"
                      (change)="
                        getUpdatedEntityContactServicesStatus(
                          formArray.name,
                          formArray.controls[1].name,
                          $index
                        )
                      ">
                      @for (
                        option of formArray.controls[1].options;
                        track $index
                      ) {
                        <option [value]="option">
                          {{ option | titlecase }}
                        </option>
                      }
                    </select>
                  </div>
                </div>
                <p class="text-red-500 text-sm">
                  {{
                    errorMessage(
                      getFormControlByName(
                        formArray.name,
                        formArray.controls[0].name,
                        $index
                      )
                    )
                  }}
                </p>
              </div>
              <div class="px-4 w-[30px]">
                @if (
                  !isControlDisabled(
                    formArray.name,
                    formArray.controls[0].name,
                    $index
                  )
                ) {
                  @if (
                    canAddEntityContactService(formArray.name, $index) &&
                    !errorMessage(
                      getFormControlByName(
                        formArray.name,
                        formArray.controls[0].name,
                        $index
                      )
                    )
                  ) {
                    <button
                      type="button "
                      class="text-sm text-blueDS-600"
                      (click)="
                        onAddEntityContactService(formArray.name, $index)
                      ">
                      Save
                    </button>
                  } @else {
                    <button
                      type="button"
                      (click)="onDeleteFormControl(formArray.name, $index)">
                      <span class="icon-trash text-red-400"></span>
                    </button>
                  }
                } @else {
                  <button
                    type="button"
                    (click)="onDeleteFormControl(formArray.name, $index, true)">
                    <span class="icon-trash text-red-400"></span>
                  </button>
                }
              </div>
            </div>
          </div>
        } @empty {
          No {{ formArray.name }}.
        }

        <button type="button" (click)="onAddFormControl(formArray.name)">
          <span class="text-sm text-blueDS-600 self-start"
            >+ Add {{ formArray.name | titlecase }}</span
          >
        </button>
      </div>
    }
  </div>
</form>
