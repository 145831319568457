import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedComponentsModule } from 'src/app/components/components.module';
import { EntitiesComponent } from './entities.component';

import { ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from 'src/app/components/shared/loading/loading.component';
import { UploadFilesComponent } from 'src/app/components/shared/upload-files/upload-files.component';
import { EntitiesActiveComponent } from './entities-active/entities-active.component';
import { EntitiesInactiveComponent } from './entities-inactive/entities-inactive.component';
import { EntityAssignedUsersComponent } from './entity/entity-assigned-users/entity-assigned-users.component';
import { BillingLogComponent } from './entity/entity-billing/billing-log/billing-log.component';
import { BillingProfileComponent } from './entity/entity-billing/billing-profile/billing-profile.component';
import { EntityBillingComponent } from './entity/entity-billing/entity-billing.component';
import { InvoicesComponent } from './entity/entity-billing/invoices/invoices.component';
import { PaymentMethodsComponent } from './entity/entity-billing/payment-methods/payment-methods.component';
import { AddExistingContactComponent } from './entity/entity-contacts/components/add-existing-contact/add-existing-contact.component';
import { EntityContactFormComponent } from './entity/entity-contacts/components/entity-contact-form/entity-contact-form.component';
import { EntityContactListComponent } from './entity/entity-contacts/components/entity-contact-list/entity-contact-list.component';
import { EntityContactsComponent } from './entity/entity-contacts/entity-contacts.component';
import { EntityContactDetailsComponent } from './entity/entity-contacts/pages/entity-contact-details/entity-contact-details.component';
import { EntityNotesFormComponent } from './entity/entity-notes/components/entity-notes-form/entity-notes-form.component';
import { EntityNotesListComponent } from './entity/entity-notes/components/entity-notes-list/entity-notes-list.component';
import { EntityNotesComponent } from './entity/entity-notes/entity-notes.component';
import { EntityOfficersComponent } from './entity/entity-officers/entity-officers.component';
import { EntityProfileComponent } from './entity/entity-profile/entity-profile.component';
import { AddEntityServiceComponent } from './entity/entity-services/add-entity-sevice/add-entity-sevice.component';
import { ServiceDetailsFormComponent } from './entity/entity-services/add-entity-sevice/forms/service-details-form/service-details-form.component';
import { ServiceStartDateFormComponent } from './entity/entity-services/add-entity-sevice/forms/service-start-date-form/service-start-date-form.component';
import { ServiceTasksFormComponent } from './entity/entity-services/add-entity-sevice/forms/service-tasks-form/service-tasks-form.component';
import { EntityServiceBillingsAndFeesComponent } from './entity/entity-services/entity-service-billings-and-fees/entity-service-billings-and-fees.component';
import { EntityServiceLogComponent } from './entity/entity-services/entity-service-log/entity-service-log.component';
import { EntityServicePropertiesComponent } from './entity/entity-services/entity-service-properties/entity-service-properties.component';
import { EntityServiceSettingsComponent } from './entity/entity-services/entity-service-settings/entity-service-settings.component';
import { EntityServicesHistoryComponent } from './entity/entity-services/entity-services-history/entity-services-history.component';
import { EntityServicesOngoingComponent } from './entity/entity-services/entity-services-ongoing/entity-services-ongoing.component';
import { EntityServicesComponent } from './entity/entity-services/entity-services.component';
import { EntityTasksComponent } from './entity/entity-tasks/entity-tasks.component';
import { EntityUploadsComponent } from './entity/entity-uploads/entity-uploads.component';
import { EntityComponent } from './entity/entity.component';

const routes: Routes = [
  {
    path: '',
    component: EntitiesComponent,
    children: [
      {
        path: '',
        redirectTo: 'Active',
        pathMatch: 'full',
      },
      { path: 'Active', component: EntitiesActiveComponent },
      { path: 'Inactive', component: EntitiesInactiveComponent },
    ],
  },
  {
    path: 'Active/:id',
    component: EntityComponent,
    children: [
      { path: 'Company Profile', component: EntityProfileComponent },
      {
        path: 'Services',
        component: EntityServicesComponent,
        children: [
          {
            path: '',
            redirectTo: 'Ongoing',
            pathMatch: 'full',
          },
          { path: 'Ongoing', component: EntityServicesOngoingComponent },
          { path: 'History', component: EntityServicesHistoryComponent },
          { path: ':id/Service Log', component: EntityServiceLogComponent },
          {
            path: ':id/Service Settings',
            component: EntityServiceSettingsComponent,
          },
          {
            path: ':id/Billings And Fees',
            component: EntityServiceBillingsAndFeesComponent,
          },
          {
            path: ':id/Service Properties',
            component: EntityServicePropertiesComponent,
          },
        ],
      },
      { path: 'Officers', component: EntityOfficersComponent },
      {
        path: 'Contacts',
        component: EntityContactsComponent,
        children: [
          {
            path: '',
            redirectTo: 'details/view',
            pathMatch: 'full',
          },
          {
            path: 'details/:id',
            component: EntityContactDetailsComponent,
          },
        ],
      },
      { path: 'Uploads', component: EntityUploadsComponent },
      {
        path: 'Billing',
        component: EntityBillingComponent,
        children: [
          {
            path: '',
            redirectTo: 'Invoices',
            pathMatch: 'full',
          },
          { path: 'Invoices', component: InvoicesComponent },
          { path: 'Payment Methods', component: PaymentMethodsComponent },
          { path: 'Billing Profile', component: BillingProfileComponent },
          { path: 'Billing Log', component: BillingLogComponent },
        ],
      },
      { path: 'Assigned Users', component: EntityAssignedUsersComponent },
      { path: 'Notes', component: EntityNotesComponent },
      { path: 'Tasks', component: EntityTasksComponent },
    ],
  },
  {
    path: 'Inactive/:id',
    component: EntityComponent,
    children: [
      { path: 'Company Profile', component: EntityProfileComponent },
      {
        path: 'Services',
        component: EntityServicesComponent,
        children: [
          {
            path: '',
            redirectTo: 'Ongoing',
            pathMatch: 'full',
          },
          { path: 'Ongoing', component: EntityServicesOngoingComponent },
          { path: 'History', component: EntityServicesHistoryComponent },
          { path: ':id/Service Log', component: EntityServiceLogComponent },
          {
            path: ':id/Service Settings',
            component: EntityServiceSettingsComponent,
          },
          {
            path: ':id/Billings And Fees',
            component: EntityServiceBillingsAndFeesComponent,
          },
          {
            path: ':id/Service Properties',
            component: EntityServicePropertiesComponent,
          },
        ],
      },
      { path: 'Officers', component: EntityOfficersComponent },
      {
        path: 'Contacts',
        component: EntityContactsComponent,
        children: [
          {
            path: '',
            redirectTo: 'details/view',
            pathMatch: 'full',
          },
          { path: '', component: EntityContactsComponent },
          { path: 'details/:id', component: EntityContactDetailsComponent },
        ],
      },
      { path: 'Uploads', component: EntityUploadsComponent },
      {
        path: 'Billing',
        component: EntityBillingComponent,
        children: [
          {
            path: '',
            redirectTo: 'Invoices',
            pathMatch: 'full',
          },
          { path: 'Invoices', component: InvoicesComponent },
          { path: 'Payment Methods', component: PaymentMethodsComponent },
          { path: 'Billing Profile', component: BillingProfileComponent },
          { path: 'Billing Log', component: BillingLogComponent },
        ],
      },
      { path: 'Assigned Users', component: EntityAssignedUsersComponent },
      { path: 'Notes', component: EntityNotesComponent },
      { path: 'Tasks', component: EntityTasksComponent },
    ],
  },
];

@NgModule({
  declarations: [
    EntitiesComponent,
    EntityComponent,
    EntityProfileComponent,
    EntityServicesComponent,
    EntityOfficersComponent,
    EntityContactsComponent,
    EntityUploadsComponent,
    EntityBillingComponent,
    EntityAssignedUsersComponent,
    EntityNotesComponent,
    EntityTasksComponent,
    EntityServicesOngoingComponent,
    EntityServicesHistoryComponent,
    EntityServiceLogComponent,
    EntityServiceSettingsComponent,
    EntityServiceBillingsAndFeesComponent,
    EntityServicePropertiesComponent,
    InvoicesComponent,
    PaymentMethodsComponent,
    BillingProfileComponent,
    BillingLogComponent,
    EntitiesActiveComponent,
    EntitiesInactiveComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    RouterModule.forChild(routes),
    EntityContactFormComponent,
    EntityContactListComponent,
    LoadingComponent,
    EntityContactDetailsComponent,
    AddExistingContactComponent,
    AddEntityServiceComponent,
    ServiceDetailsFormComponent,
    ServiceStartDateFormComponent,
    UploadFilesComponent,
    EntityNotesFormComponent,
    EntityNotesListComponent,
    ServiceTasksFormComponent,
  ],
  exports: [RouterModule],
  providers: [DatePipe],
})
export class EntitiesModule {}
