<div class="flex items-start">
  <span
    class="text-sm font-medium leading-6 text-gray-900"
    [ngClass]="{
      'w-1/4': isFlexDirectionRow,
      'w-[100px]': !isFlexDirectionRow,
    }"
    >Fee</span
  >
  <div
    class="flex flex-col"
    [ngClass]="{
      'w-3/4': isFlexDirectionRow,
      'w-[200px]': !isFlexDirectionRow,
    }">
    <input
      type="number"
      [formControl]="controls.fee"
      class="border-slate-300 rounded-md placeholder-slate-400 focus:border-blueDS-400 focus:ring-1 focus:ring-blueDS-500 bg-white text-sm"
      [ngClass]="{
        'border-red-500': errorMessage(controls.fee),
      }" />
    <p class="text-red-500 text-sm">
      {{ errorMessage(controls.fee) }}
    </p>
  </div>
</div>

<div [class]="'flex mt-5 flex-' + (isFlexDirectionRow ? 'col' : 'row')">
  <div [class]="'w-1/2 flex '" [ngClass]="{ 'w-1/2': !isFlexDirectionRow }">
    <span
      class="text-sm font-medium leading-6 text-gray-900"
      [ngClass]="{
        'w-1/4': isFlexDirectionRow,
        'w-[100px]': !isFlexDirectionRow,
      }"
      >Description</span
    >
    <textarea
      [formControl]="controls.description"
      class="border-slate-300 rounded-md placeholder-slate-400 focus:border-blueDS-400 focus:ring-1 focus:ring-blueDS-500 bg-white text-sm h-36"
      [ngClass]="{
        'w-3/4': isFlexDirectionRow,
        'w-[200px]': !isFlexDirectionRow,
      }"></textarea>
  </div>
  <div
    [class]="'w-1/2 flex ' + (isFlexDirectionRow ? 'mt-5' : '')"
    [ngClass]="{ 'w-1/2': !isFlexDirectionRow }">
    <span
      class="text-sm font-medium leading-6 text-gray-900"
      [ngClass]="{
        'w-1/4': isFlexDirectionRow,
        'w-[100px]': !isFlexDirectionRow,
      }"
      >Disclosure</span
    >
    <textarea
      [formControl]="controls.disclosure"
      class="border-slate-300 rounded-md placeholder-slate-400 focus:border-blueDS-400 focus:ring-1 focus:ring-blueDS-500 bg-white text-sm h-36"
      [ngClass]="{
        'w-3/4': isFlexDirectionRow,
        'w-[200px]': !isFlexDirectionRow,
      }"></textarea>
  </div>
</div>
