import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BaseFormComponent } from 'src/app/core/directives/base-form/base-form.directive';

@Component({
  standalone: true,
  selector: 'app-entity-notes-form',
  templateUrl: './entity-notes-form.component.html',
  imports: [CommonModule, ReactiveFormsModule],
})
export class EntityNotesFormComponent extends BaseFormComponent {}
