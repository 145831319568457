@if (entityServices !== undefined) {
  <table-basic
    [setStatusColor]="true"
    [editAction]="true"
    [deleteAction]="false"
    [columnsToDisplay]="columnsToDisplay"
    [tableData]="entityServices"
    (onEditClick)="onSelectEditRow($event)"></table-basic>
} @else {
  <div class="flex justify-center">
    <app-loading />
  </div>
}
