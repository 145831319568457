import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Toast, ToastType } from 'src/app/core/types/Toast.type';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private readonly _toastStore$: BehaviorSubject<Toast | null> =
    new BehaviorSubject<Toast | null>(null);
  toast$: Observable<Toast | null> = this._toastStore$
    .asObservable()
    .pipe(filter(toast => toast !== null));

  show(type: ToastType, title: string, body: string[], delay: number) {
    this._toastStore$.next({ type, title, body, delay });
  }
}
