import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EntityNote } from 'src/app/core/types/EntityNote.type';
import ResponseData from 'src/app/core/types/shared/Response.type';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EntityNotesService {
  private readonly _http: HttpClient = inject(HttpClient);

  private readonly baseApiUrl = environment.baseApiUrl;

  getEntityNotes(entityId: string): Observable<EntityNote[]> {
    return this._http
      .get<
        ResponseData<EntityNote>
      >(`${this.baseApiUrl}entity-notes/${entityId}`)
      .pipe(map(({ data }) => data));
  }

  addEntityNote(payload: {
    content: string;
    gr_entity_id: string;
  }): Observable<unknown> {
    return this._http.post<ResponseData<unknown>>(
      `${this.baseApiUrl}entity-notes`,
      payload
    );
  }

  editEntityNote(payload: {
    noteId: number;
    content: string;
    gr_entity_id: string;
  }): Observable<unknown> {
    const { gr_entity_id, content, noteId } = payload;
    return this._http.put<ResponseData<unknown>>(
      `${this.baseApiUrl}entity-notes/${noteId}`,
      { content, gr_entity_id }
    );
  }

  deleteEntityNote(noteId: number): Observable<unknown> {
    return this._http.delete<ResponseData<unknown>>(
      `${this.baseApiUrl}entity-notes/${noteId}`
    );
  }
}
