import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.css'],
})
export class IconButonComponent {
  @Input() iconClass!: string;
  @Input() title!: string;
  @Input() disabled: boolean = false;
  @Input() background?: string;
  @Input() textColor?: string;
  @Input() additionalClass?: string;
  @Input() conditionalClass!: { [key: string]: boolean };
  @Input() hasPadding: boolean = true;

  getDefaultBackground(): string {
    return this.background ?? 'bg-blueDS-500';
  }

  getTextClass(): string {
    return this.textColor ?? 'text-white';
  }

  getHoverClass(): string {
    return this.background
      ? 'hover:bg-gray-100 hover:text-gray-500'
      : 'hover:bg-blue-400';
  }

  getPadding() {
    return this.hasPadding ? 'py-2.5 px-4' : 'p-0';
  }

  iconGap() {
    return this.title ? 'mr-2' : '';
  }
}
