export function getSelectedServiceTasks(
  this: any,
  tasksFormArrayName: string
): number[] {
  return (this[tasksFormArrayName]?.value as [])
    .filter(task => Object.values(task)[0])
    .map(taks => {
      return +Object.keys(taks)[0];
    });
}
