<div class="flex flex-row justify-between px-2">
  <search
    [originalData]="entityNotes"
    [columnsToDisplay]="searchKeys"
    [filteredData]="filteredEntityNotes"
    (emitFilteredData)="setFilteredEntityNotes($event)" />
  <app-icon-button
    iconClass="icon-plus"
    title="Add Note"
    class="self-end"
    (click)="openNoteModal()" />
</div>

<div class="bg-white p-10 mt-4">
  @if (filteredEntityNotes) {
    <app-entity-notes-list
      [entityNotes]="filteredEntityNotes"
      [connectedUserId]="connectedUser?.id"
      (onAction)="handleActions($event)" />
  } @else {
    <div class="flex justify-center">
      <app-loading />
    </div>
  }
</div>

@if (noteModalStatus === 'open') {
  <modal [modalAreaWidth]="'600px'">
    <app-entity-notes-form
      [formGroup]="entityNotesFormGroup"
      [isEditMode]="isEditMode"
      (submit)="saveNote()"
      (cancel)="cancel()" />
  </modal>
}

@if (deleteEntityNoteModalStatus === 'open') {
  <confirmation-modal
    title="Delete Entity Note"
    (emitCancel)="cancel(true)"
    (emitDelete)="deleteEntityNote()" />
}
