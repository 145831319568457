import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'select-block',
  templateUrl: './select-block.component.html',
  styleUrls: ['./select-block.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectBlockComponent),
      multi: true,
    },
  ],
})
export class SelectBlockComponent implements ControlValueAccessor {
  @Input()
  public label: string = '';
  @Input()
  public options: any[] = [];
  @Input()
  public isHorizontal: boolean = false;
  @Input()
  public setLabelWidth: any;
  @Input()
  public setSelectWidth: any;
  @Input() public isEditMode = false;

  @Input()
  public includeDefaultAllOption: boolean = false;

  @Input()
  public idProperty: string = '';

  @Input()
  public displayProperty: string = 'false';

  public value: string = '';

  public onChange = (value: any) => {};
  public onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // implement this method if you need to disable your custom input
  }
}
