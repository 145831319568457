import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-block',
  templateUrl: './input-block.component.html',
  styleUrls: ['./input-block.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputBlockComponent),
      multi: true,
    },
  ],
})
export class InputBlockComponent implements ControlValueAccessor {
  @Output() onUpload = new EventEmitter<boolean>();

  @Input() public label: string = 'Label';
  @Input() public type: string = 'text';
  @Input() public isHorizontal: boolean = false;
  @Input() public setLabelWidth: string = '';
  @Input() public setInputWidth: any;
  @Input() public isEditMode = false;
  @Input() public IsuploadSupport = false;
  @Input() public uploadCounter = 0;

  public value: string = '';

  public onChange = (value: any) => {};
  public onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // implement this method if you need to disable your custom input
  }

  upload() {
    this.onUpload.emit(true);
  }
}
