import { TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Periodicity } from 'src/app/core/types/shared/Periodicity.type';

@Component({
  standalone: true,
  selector: 'app-periodicity-form',
  templateUrl: './periodicity-form.component.html',
  imports: [ReactiveFormsModule, TitleCasePipe],
})
export class PeriodicityFormComponent {
  @Input() periodicities!: Periodicity[];
  @Input() control!: FormControl;
}
