import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import ResponseData from 'src/app/core/types/shared/Response.type';
import {
  CustomField,
  CustomFieldOption,
  EntityType,
  settingsUrl,
} from './settings-constants';
import { PropertyGroup } from './types/PropertyGroup';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  //customFieldType 0 - 10
  //'text', 'longtext', 'select', 'multiselect', 'calendar', 'numeric', 'datetext', 'ssn', 'email', 'ein', 'shared-upload'

  //ENTITY TYPES
  public getAllEntityTypes() {
    return this.http.get<EntityType[]>(settingsUrl.entityTypes);
  }

  public updateEntityType(entityType: EntityType) {
    return this.http.put<any>(settingsUrl.entityTypes + '/' + entityType.id, {
      name: entityType.name,
      ownership_type: entityType.ownership_type,
    });
  }

  public deleteEntityType(entityType: EntityType) {
    return this.http.delete<any>(settingsUrl.entityTypes + '/' + entityType.id);
  }

  public addEntityType(params: { name: string; ownership_type: string }) {
    return this.http.post<EntityType>(settingsUrl.entityTypes, params);
  }

  //CUSTOM FIELD GROUP
  public getEntityType(entityTypeId: string) {
    return this.http.get<any>(settingsUrl.entityTypes + '/' + entityTypeId);
  }

  public updateEntityTypeFieldGroup(entityTypeFieldGroup: {
    id: number;
    name: string;
    list_order: number;
  }) {
    return this.http.put<any>(
      settingsUrl.entityTypes + '/update-group/' + entityTypeFieldGroup.id,
      {
        name: entityTypeFieldGroup.name,
        list_order: entityTypeFieldGroup.list_order,
      }
    );
  }

  public deleteEntityTypeFieldGroup(entityTypeFieldGroupId: string) {
    return this.http.delete<any>(
      settingsUrl.entityTypes + '/delete-group/' + entityTypeFieldGroupId
    );
  }

  public addEntityTypeFieldGroup(entityTypeId: string, fieldGroupName: string) {
    const params = { name: fieldGroupName };
    return this.http.post<any>(
      settingsUrl.entityTypes + '/' + entityTypeId + '/add-group',
      params
    );
  }

  public addCustomFieldToCustomFieldGroupInEntityTypes(
    customFieldId: number,
    entityTypeId: string,
    customFieldGroupId: string
  ) {
    const params = {
      gr_entity_type_id: entityTypeId,
      gr_property_id: customFieldId,
    };
    return this.http.post<any>(
      settingsUrl.entityTypes + '/add-property/' + customFieldGroupId,
      params
    );
  }

  public deleteCustomFieldFromEntityGroup(
    propertyId: number,
    entityTypeId: number,
    groupId: number
  ) {
    return this.http.delete<any>(
      settingsUrl.entityTypes +
        `/delete-property-group/${propertyId}/${entityTypeId}/${groupId}`
    );
  }

  //CUSTOM FIELDS
  public getAllCustomFields() {
    return this.http.get<any>(settingsUrl.customFields);
  }

  public getCustomField(id: string) {
    return this.http.get<any>(settingsUrl.customFields + '/' + id);
  }

  public updateCustomField(customField: CustomField) {
    return this.http.put<any>(settingsUrl.customFields + '/' + customField.id, {
      name: customField.name,
      input_type: customField.input_type,
      upload_support: customField.upload_support,
    });
  }

  public deleteCustomField(customField: CustomField) {
    return this.http.delete<any>(
      settingsUrl.customFields + '/' + customField.id
    );
  }

  public addCustomField(params: {
    name: string;
    input_type: string;
    upload_support: number;
  }) {
    return this.http.post<CustomField>(settingsUrl.customFields, params);
  }

  //CUSTOM FIELD OPTION
  public addCustomFieldOption(params: { id: string; select_option: string }) {
    const selectOptionParam = { select_option: params.select_option };
    return this.http.post<CustomFieldOption>(
      settingsUrl.customFields + '/' + params.id + '/option',
      selectOptionParam
    );
  }

  public updateCustomFieldOption(customFieldOption: CustomFieldOption) {
    return this.http.put<any>(
      settingsUrl.customFields + '/update-option/' + customFieldOption.id,
      {
        select_option: customFieldOption.select_option,
        list_order: customFieldOption.list_order,
      }
    );
  }

  public deleteCustomFieldOption(customFieldOptionId: number) {
    return this.http.delete<any>(
      settingsUrl.customFields + '/delete-option/' + customFieldOptionId
    );
  }

  //GENERAL BUSINESS INFORMATION
  public getBusinessInformation() {
    return this.http.get<any>(settingsUrl.general);
  }

  public updateGeneralInformation(valueString: string, settingId: number) {
    return this.http.put<any>(settingsUrl.general + '/' + settingId, {
      value_string: valueString,
    });
  }

  addLogo({ value_image }: { value_image: File }): Observable<any> {
    if (!value_image) return of('error');

    return this.http.post<{
      value_image: File;
    }>(settingsUrl.general + '/add-logo', value_image);
  }

  deleteLogo(): Observable<any> {
    return this.http.delete<any>(settingsUrl.general + '/delete-logo');
  }

  //SERVICES
  public getAllServices(page: number = 1) {
    return this.http.get<any>(settingsUrl.services + '?page=' + page);
  }

  public getService(serviceId: any) {
    return this.http.get<any>(settingsUrl.services + '/' + serviceId);
  }

  public createService(
    name: string,
    default_fee: number,
    default_fee_type: string
  ) {
    const params = {
      name: name,
      default_fee: default_fee,
      default_fee_type: default_fee_type,
    };
    return this.http.post<any>(settingsUrl.services, params);
  }

  public deleteService(serviceId: number) {
    return this.http.delete<any>(settingsUrl.services + '/' + serviceId);
  }

  public updateServiceSettings(serviceData: any) {
    const params = {
      name: serviceData.name,
      default_fee: serviceData.default_fee,
      default_fee_type: serviceData.default_fee_type,
      default_description: serviceData.description,
      default_disclosure: serviceData.default_disclosure,
    };
    return this.http.put<any>(
      settingsUrl.services + '/' + serviceData.id,
      params
    );
  }

  //SERVICES FIELD GROUP
  public addCustomFieldToCustomFieldGroupInServices(
    customFieldId: number,
    groupServiceId: string,
    customFieldGroupId: string
  ) {
    const params = {
      gr_service_id: groupServiceId,
      gr_property_id: customFieldId,
    };
    return this.http.post<any>(
      settingsUrl.services + '/add-property/' + customFieldGroupId,
      params
    );
  }

  public updateServiceGroup(serviceGroup: {
    id: number;
    name: string;
    list_order: number;
  }) {
    return this.http.put<any>(
      settingsUrl.services + '/update-group/' + serviceGroup.id,
      { name: serviceGroup.name, list_order: serviceGroup.list_order }
    );
  }

  public deleteServiceGroup(serviceGroupId: string) {
    return this.http.delete<any>(
      settingsUrl.services + '/delete-group/' + serviceGroupId
    );
  }

  public addServiceGroup(serviceId: string, groupName: string) {
    const params = { name: groupName };
    return this.http.post<any>(
      settingsUrl.services + '/' + serviceId + '/add-group',
      params
    );
  }

  public deleteCustomFieldFromServiceGroup(
    serviceId: string,
    propertyToGroupId: number
  ) {
    //NOT EXISTING YET
    return this.http.delete<any>(
      settingsUrl.services +
        '/delete-property/' +
        serviceId +
        '/' +
        propertyToGroupId
    );
  }

  // SERVICE TASKS
  public getAllServiceTasks(serviceId: any) {
    return this.http.get<any>(settingsUrl.serviceTasks + '/' + serviceId);
  }

  public addServiceTask(params: {
    name: string;
    gr_service_id: number;
    description: string;
    depends_on: number;
  }) {
    return this.http.post<any>(settingsUrl.serviceTasks, params);
  }

  public deleteServiceTask(serviceTaskId: string) {
    return this.http.delete<any>(
      `${settingsUrl.serviceTasks}/${serviceTaskId}`
    );
  }

  updateServiceTask(serviceTask: {
    id: number;
    name: string;
    gr_service_id: number;
    description: string;
    depends_on: number;
  }) {
    return this.http.put<any>(
      `${settingsUrl.serviceTasks}/${serviceTask.id}`,
      serviceTask
    );
  }

  reorderTasks(tasks: unknown[]): Observable<any> {
    return this.http.put<unknown>(`${settingsUrl.serviceTasks}/reorder`, tasks);
  }

  getServiceTaskNotificationSettings(serviceTaskId: number) {
    return this.http.get<ResponseData<any>>(
      `${settingsUrl.serviceTasks}/${serviceTaskId}/notification`
    );
  }

  getAllCustomFieldGroups() {
    return this.http
      .get<ResponseData<PropertyGroup>>(`${settingsUrl.base}property-groups`)
      .pipe(
        map(({ data }) => {
          return data.flatMap(customFieldGroups => {
            return customFieldGroups.groups.map((group: any) => ({
              ...group,
              entity_type: customFieldGroups.entity_type,
            }));
          });
        })
      );
  }

  getAllServiceCustomFieldGroups() {
    return this.http
      .get<
        ResponseData<PropertyGroup>
      >(`${settingsUrl.services}/property-groups`)
      .pipe(
        map(({ data }) => {
          return data.flatMap(customFieldGroups => {
            return customFieldGroups.groups.map((group: any) => ({
              ...group,
              entity_type: customFieldGroups.name,
            }));
          });
        })
      );
  }

  copyCustomFieldGrouptoEntityType(
    entityTypeId: number,
    payload: {
      name: string;
      property_group_id: number;
      list_order?: number;
    }
  ): Observable<any> {
    return this.http.post<any>(
      `${settingsUrl.entityTypes}/${entityTypeId}/copy-group`,
      payload
    );
  }

  copyServiceCustomFieldGroup(
    serviceId: number,
    payload: {
      name: string;
      service_property_group_id: number;
      list_order?: number;
    }
  ): Observable<any> {
    return this.http.post<any>(
      `${settingsUrl.services}/${serviceId}/copy-group`,
      payload
    );
  }

  addCustomFieldToNotificationTemplate(payload: {
    gr_property_id: number;
    gr_notification_template_id: number;
  }): Observable<any> {
    return this.http.post<any>(
      `${settingsUrl.notifications}/property`,
      payload
    );
  }

  createServiceTaskNotificationTemplate(
    serviceTaskId: number,
    name: string
  ): Observable<any> {
    return this.http.post<any>(`${settingsUrl.serviceTasks}/notification`, {
      name,
      gr_service_task_id: serviceTaskId,
    });
  }

  deleteCustomFieldFromNotificationTemplate(
    propertyId: number
  ): Observable<any> {
    return this.http.delete<any>(
      `${settingsUrl.notifications}/property/${propertyId}`
    );
  }

  updateNotificationTemplate(
    notificationTemplateId: number,
    payload: {
      gr_service_task_id: number;
      name: string;
      email_subject_default: string;
      email_content_default: string;
      sms_content_default: string;
      send_as_email: boolean;
      send_as_sms: boolean;
      expect_reply: boolean;
      hours_valid: number;
    }
  ): Observable<any> {
    return this.http.put<any>(
      `${settingsUrl.notifications}/${notificationTemplateId}`,
      payload
    );
  }
}
