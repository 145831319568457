import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  imports: [NgClass],
})
export class SwitchComponet {
  @Output() toggle: EventEmitter<boolean> = new EventEmitter();

  @Input() isActive: boolean = false;
  @Input() title!: string;

  handleToggleIPFirewall() {
    this.toggle.emit(true);
  }
}
