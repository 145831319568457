import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EntitiesService } from './entities.service';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.css'],
})
export class EntitiesComponent implements OnInit {
  public entityForm!: FormGroup;
  public id: string = '';
  public tabs = ['Active', 'Inactive'];
  public isModalOpen: boolean = false;
  public entities: any = [];
  public entityTypes: Array<any> = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private entitiesService: EntitiesService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadEntityTypes();
    this.entityForm = this.fb.group({
      entity_name: ['', Validators.required],
      gr_entity_type_id: ['', Validators.required],
      bpm_status: ['active', Validators.required],
    });

    this.initEntitiesList();
  }

  private loadEntityTypes() {
    this.entitiesService
      .getEntitiesTypes()
      .subscribe(types => (this.entityTypes = types));
  }

  initEntitiesList() {
    this.entitiesService
      .getEntities()
      .subscribe(entities => (this.entities = entities));
  }

  public switchTab(event: any) {
    this.router.navigate([event], { relativeTo: this.activatedRoute });
  }

  createEntity() {
    if (this.entityForm.valid) {
      this.entitiesService
        .createEntity(this.entityForm.value)
        .subscribe(entity => {
          this.isModalOpen = false;
          this.entityForm.reset();
          this.router.navigate([
            'Entities',
            entity.bpm_status === 'active' ? 'Active' : 'Inactive',
            entity.id,
            'Company Profile',
          ]);
        });
    }
  }
}
