@if (isEntityServiceLoaded) {
  <div class="p-8 max-w-full">
    <app-service-details-form
      [controls]="serviceDetailsControls"
      flexDirection="row" />
    <div class="flex items-start mt-4">
      <span class="w-1/4 text-sm font-medium leading-6 text-gray-900"
        >Billing Period</span
      >
      <select
        [formControl]="billingPeriodicityControl"
        class="w-3/4 bg-custom-gray flex px-2 border-slate-300 rounded-md text-sm placeholder-slate-400 focus:border-blueDS-400 focus:ring-1 focus:ring-blueDS-500 cursor-pointer uppercase">
        @for (periodicity of periodicities; track $index) {
          <option disabled [value]="periodicity.toLocaleUpperCase()">
            {{ periodicity | titlecase }}
          </option>
        }
      </select>
    </div>
    <div class="mt-8">
      <div class="flex justify-end">
        <button
          type="button"
          (click)="editBillingAndFees()"
          [disabled]="billingAndFeesForm.invalid"
          [class.cursor-not-allowed]="billingAndFeesForm.invalid"
          [class.bg-blueDS-300]="billingAndFeesForm.invalid"
          class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blueDS-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blueDS-700 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2">
          Save Changes
        </button>
      </div>
    </div>
  </div>
} @else {
  <div class="flex justify-center">
    <app-loading />
  </div>
}
