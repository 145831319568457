import { Component, ErrorHandler, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { PAGE_TYPE } from 'src/app/components/shared/upload-files/enums/PageType.enum';
import { FileManagement } from 'src/app/components/shared/upload-files/helper/file-management';
import { PageType } from 'src/app/components/shared/upload-files/types/PageType';

@Component({
  selector: 'app-entity-uploads',
  templateUrl: './entity-uploads.component.html',
  styleUrls: ['./entity-uploads.component.css'],
})
export class EntityUploadsComponent extends FileManagement implements OnInit {
  private readonly _errorHandler: ErrorHandler = inject(ErrorHandler);
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  entityForm!: FormGroup;
  entityId!: string;
  form!: FormGroup;
  entityPropertiesGroupe: any;
  entityGeneralUploads: any;
  override pageType: PageType = PAGE_TYPE.ENTITY;

  ngOnInit(): void {
    this.entityId =
      this._activatedRoute.parent?.snapshot.paramMap.get('id') ?? '';

    this._getEntityGeneralUploads().subscribe({
      next: entityGeneralUploads => {
        this.entityGeneralUploads = {
          ...entityGeneralUploads,
          uploader_id: this.entityId,
        };
      },
    });
  }

  override _completeUploadAndRefresh(observable$: Observable<any>) {
    this.deleteModalStatus = 'close';
    this.fileToDelete = null;

    return observable$
      .pipe(
        switchMap(() => {
          return this._getEntityGeneralUploads();
        })
      )
      .subscribe({
        next: entityGeneralUploads => {
          this.entityGeneralUploads = {
            ...entityGeneralUploads,
            uploader_id: this.entityId,
          };

          this._selectedItem = {
            zone_id: this.entityGeneralUploads.zone_id,
            uploader_id: this.entityGeneralUploads.uploader_id,
          } as any;
          this.files = this.entityGeneralUploads?.gr_uploads;

          this.hasLoading = false;
        },
        error: error => {
          this.hasLoading = false;
          this._errorHandler.handleError(error);
        },
      });
  }

  private _getEntityGeneralUploads() {
    return this._entitiesService.getEntityGeneralUploads(this.entityId);
  }
}
