@if (propertiesGroupe && propertiesGroupe.length) {
  <div *ngFor="let group of propertiesGroupe">
    <form [formGroup]="propertiesForm">
      <div class="px-4 py-5 sm:px-6">
        <page-header [pageHeader]="group.groupName"></page-header>
        <div class="py-5">
          <div
            class="flex flex-wrap sm:flex-col md:flex-col lg:flex-col xl:flex-row"
            [ngClass]="{ 'w-2/3': group.propertiesList.length > 4 }">
            <ng-container
              *ngFor="let item of group.propertiesList; let itemIndex = index">
              <input-block
                *ngIf="
                  item.input_type === 'text' ||
                  item.input_type === 'numeric' ||
                  item.input_type === 'ssn' ||
                  item.input_type === 'datetext' ||
                  item.input_type === 'longtext'
                "
                [formControlName]="item.name"
                class="mb-4 pr-8"
                [type]="item.input_type === 'datetext' ? 'date' : 'text'"
                [label]="item.name"
                [isEditMode]="true"
                [setInputWidth]="'full'"
                [IsuploadSupport]="item.upload_support"
                [ngClass]="getClass(group.propertiesList.length, itemIndex)"
                [uploadCounter]="item.gr_uploads?.length"
                (onUpload)="openUploadModel(item)">
              </input-block>

              <select-block
                *ngIf="item.input_type === 'select'"
                [formControlName]="item.name"
                class="mb-4 pr-8"
                [options]="item.options"
                [label]="item.name"
                [setSelectWidth]="'full'"
                [isEditMode]="true"
                [ngClass]="getClass(group.propertiesList.length, itemIndex)"
                idProperty="id"
                displayProperty="name">
              </select-block>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </div>
} @else {
  <app-not-found class="mt-8" label="No result."></app-not-found>
}
@if (uploadModalStatus === 'open') {
  <modal [modalAreaWidth]="'860px'">
    <upload-files
      [title]="uploadModalTitle"
      [headerTitles]="headerTitles"
      [files]="files"
      [hasLoading]="hasLoading"
      (deleteFile)="openDeleteModal($event)"
      (uploadFiles)="onFileSelected($event)"
      (updateFileStatus)="updateFileStatus($event)"
      (cancel)="cancel($event)"
      (downloadFile)="downloadFile($event)" />
  </modal>
}

@if (deleteModalStatus === 'open') {
  <confirmation-modal
    [title]="deleteModalTitle"
    (emitCancel)="cancel($event, true)"
    (emitDelete)="deleteFile()" />
}
