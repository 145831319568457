import { PaginationMetadata } from 'src/app/core/types/shared/PaginationMetadata.type';

export function formatPaginationMetadata(data: any): PaginationMetadata {
  return {
    currentPage: data.current_page,
    from: data.from,
    lastPage: data.last_page,
    links: data.links,
    path: data.path,
    perPage: data.per_page,
    to: data.to,
    total: data.total,
  };
}
