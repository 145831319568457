<form [formGroup]="formGroup">
  <div class="flex mb-4">
    <app-switch
      [isActive]="isSendNotificationActive"
      (toggle)="toggleSendNotification()" />
    Send notifications for this task
  </div>

  <div class="flex gap-3 my-8 w-full">
    <div class="flex items-center gap-2 w-2/3">
      <app-required-asterisk>
        <label class="text-sm font-medium leading-6 text-gray-900" for="name"
          >Name</label
        >
      </app-required-asterisk>
      <input
        type="text"
        formControlName="name"
        class="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6" />
    </div>

    <div class="flex items-center w-1/3">
      <app-switch
        [isActive]="isExpectResponseActive"
        (toggle)="toggleExpectResponse()" />
      <span>Expect response</span>
      <input
        type="number"
        formControlName="hours"
        class="mx-3 w-16 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6" />
      hours after sending
    </div>
  </div>
</form>
