export function getNestedValue(object: Record<string, any>, key: string): any {
  const keys = key.split('.');

  return keys.reduce((currentValue, currentKey) => {
    return currentValue && currentValue[currentKey] !== undefined
      ? currentValue[currentKey]
      : null;
  }, object);
}

export function search(
  keyword: string,
  originalData: Record<string, any>[],
  searchKeys: { key: string }[]
) {
  const searchResults: Record<string, any>[] = [];
  const lowerCaseKeyword = keyword.toLowerCase();

  for (const data of originalData) {
    let matchFound = false;

    for (const { key } of searchKeys) {
      const nestedValue = getNestedValue(data, key);
      if (nestedValue) {
        const stringValue = nestedValue.toString().toLowerCase();

        if (stringValue.includes(lowerCaseKeyword)) {
          searchResults.push(data);
          matchFound = true;
          break;
        }
      }
    }

    if (matchFound) {
      continue;
    }
  }

  return searchResults;
}
