import { NgClass, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  EntityService,
  ServiceTask,
} from 'src/app/core/types/EntityService.type';

@Component({
  standalone: true,
  selector: 'app-service-tasks-form',
  templateUrl: './service-tasks-form.component.html',
  imports: [ReactiveFormsModule, TitleCasePipe, NgClass],
})
export class ServiceTasksFormComponent {
  @Output() checkedTask: EventEmitter<string> = new EventEmitter<string>();
  @Input() services!: Partial<EntityService>[];
  @Input() tasks!: ServiceTask[];
  @Input() serviceControl!: FormControl;
  @Input() tasksArrayForm!: FormArray;

  getTaskControl(index: number, taksId: number) {
    return this.tasksArrayForm
      .get(String(index))
      ?.get(String(taksId)) as FormControl;
  }
}
