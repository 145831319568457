import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntitiesService } from '../entities.service';

@Component({
  selector: 'entities-active',
  templateUrl: './entities-active.component.html',
  styleUrls: ['./entities-active.component.css'],
})
export class EntitiesActiveComponent implements OnInit {
  public activeData: any[] = [];
  public filteredData: any[] = [];
  public columnsToDisplay = [
    { key: 'entity_name', value: 'Entity Name' },
    { key: 'entity_type', value: 'Entity Type' },
  ];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private entitiesService: EntitiesService
  ) {}

  ngOnInit(): void {
    this.entitiesService.getEntities('active').subscribe(entities => {
      this.activeData = entities;
      this.filteredData = entities;
    });
  }

  public navigateToEntity(event: any) {
    this.router.navigate([this.router.url + '/' + event.id], {
      relativeTo: this.activatedRoute,
    });
  }

  public setFilteredData(filteredData: any) {
    this.filteredData = filteredData;
  }
}
