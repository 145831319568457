import {
  EntityContact,
  EntityContactFormArraysTypes,
} from 'src/app/core/types/EntityContact.type';

export function formatEntityContactData(data: any): EntityContact {
  return {
    id: data.id,
    firstName: data.firstname,
    lastName: data.lastname,
    alias: data.alias,
    billingAddress: data.billing_address,
    billingCity: data.billing_city,
    billingState: data.billing_state,
    billingZip: data.billing_zip,
    greeting: data.greeting,
    createdAt: data.created_at,
    updatedAt: data.created_at,
    updatedBy: data.updated_by,
    deletedAt: data.created_at,
  };
}

export function formatEntityContactServicesData(
  formArrayName: EntityContactFormArraysTypes,
  entityContact: any
) {
  switch (formArrayName) {
    case 'emails':
      return {
        id: entityContact.id,
        email: entityContact.email,
        emailStatus: entityContact.email_status,
        grEntityContactId: entityContact.gr_entity_contact_id,
      };
    case 'sms':
      return {
        id: entityContact.id,
        number: entityContact.number,
        smsStatus: entityContact.sms_status,
        grEntityContactId: entityContact.gr_entity_contact_id,
      };
    case 'phones':
      return {
        id: entityContact.id,
        number: entityContact.number,
        type: entityContact.type,
        grEntityContactId: entityContact.gr_entity_contact_id,
      };
  }
}
