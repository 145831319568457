import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent {
  @Input()
  title: string = '';
  @Input()
  message: string | null = null;
  @Input()
  isEditMode: boolean = false;
  @Input()
  propertyToDisplay: string = 'name';
  @Input()
  rowData: any;

  @Output()
  emitCancel: EventEmitter<boolean> = new EventEmitter();
  @Output()
  emitDelete: EventEmitter<any> = new EventEmitter();

  disableButtons: boolean = false;

  public onCancelClick() {
    this.emitCancel.emit(true);
  }

  public onDeleteClick() {
    this.emitDelete.emit(this.rowData);
  }
}
