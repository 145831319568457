import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CamelCaseToTitleSeperatedCasePipe } from '../core/pipes/camel-case-to-title-case/camel-case-to-title-seperated-case.pipe';
import { CheckActivePipe } from '../core/pipes/check-active/check-active.pipe';
import { FormatInstanceofDatePipe } from '../core/pipes/format-instanceof-date/format-instanceof-date.pipe';
import { IncludesPipe } from '../core/pipes/includes/includes.pipe';
import { IsInstanceOfDatePipe } from '../core/pipes/is-instance-of-date/is-instance-of-date.pipe';
import { ReverseIncludesFilterByKeyPipe } from '../core/pipes/reverse-includes-filter-by-key/reverse-includes-filter-by-key.pipe';
import { UnderscoreToTitlePipe } from '../core/pipes/underscore-to-title/underscore-to-title.pipe';
import { CopyGroupComponent } from '../structure/settings/components/copy-group/copy-group.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { IconButonComponent } from './icon-button/icon-button.component';
import { InputBlockComponent } from './input-block/input-block.component';
import { ModalComponent } from './modal/modal.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PaginationBackendComponent } from './pagination-backend/pagination-backend.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SearchComponent } from './search/search.component';
import { SelectBlockComponent } from './select-block/select-block.component';
import { DatePeriodSelectorComponent } from './shared/date-period-selector/date-period-selector.component';
import { NotificationComponent } from './shared/notifications/notification.component';
import { TitleComponet } from './shared/text/text.component';
import { TableWithInputComponent } from './table-with-input/table-with-input.component';
import { TableComponent } from './table/table.component';
import { TabsComponent } from './tabs/tabs.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';

@NgModule({
  declarations: [
    PageHeaderComponent,
    PaginationComponent,
    TableComponent,
    TabsComponent,
    InputBlockComponent,
    IconButonComponent,
    SelectBlockComponent,
    CamelCaseToTitleSeperatedCasePipe,
    FormatInstanceofDatePipe,
    IncludesPipe,
    IsInstanceOfDatePipe,
    CheckActivePipe,
    ReverseIncludesFilterByKeyPipe,
    TableWithInputComponent,
    UnderscoreToTitlePipe,
    ModalComponent,
    SearchComponent,
    ConfirmationModalComponent,
    PaginationBackendComponent,
    NotFoundComponent,
    CheckboxComponent,
    ToggleButtonComponent,
    DatePeriodSelectorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TitleComponet,
    NotificationComponent,
    CopyGroupComponent,
  ],
  exports: [
    PageHeaderComponent,
    PaginationComponent,
    TableComponent,
    TabsComponent,
    InputBlockComponent,
    IconButonComponent,
    SelectBlockComponent,
    TableWithInputComponent,
    ModalComponent,
    SearchComponent,
    ConfirmationModalComponent,
    ReverseIncludesFilterByKeyPipe,
    PaginationBackendComponent,
    NotFoundComponent,
    CheckboxComponent,
    ToggleButtonComponent,
    DatePeriodSelectorComponent,
  ],
})
export class SharedComponentsModule {}
