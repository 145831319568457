<button
  type="button"
  [disabled]="disabled"
  [class]="
    getDefaultBackground() +
    ' ' +
    getTextClass() +
    ' ' +
    getPadding() +
    '  rounded-md transition' +
    ' ' +
    getHoverClass() +
    ' ' +
    additionalClass
  "
  [ngClass]="conditionalClass">
  <span
    [class]="
      iconClass +
      ' ' +
      getTextClass() +
      '  font-[100] text-sm cursor-pointer' +
      ' ' +
      iconGap()
    "
    aria-hidden="true">
  </span>
  {{ title }}
</button>
