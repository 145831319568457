export const MODAL_TYPES = {
  DELETE: 'DELETE',
  ADD: 'ADD',
  EDIT: 'EDIT',
} as const;

export const MODAL_STATUS = {
  CLOSE: 'close',
  OPEN: 'open',
} as const;
