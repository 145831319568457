@if (selectedEntityeName) {
  <page-header [pageHeader]="'' + selectedEntityeName"></page-header>
} @else {
  --
}

<tabs (onTabSelect)="switchTab($event)" [tabs]="tabs"></tabs>
<main class="py-[20px] h-full overflow-x-hidden">
  <router-outlet></router-outlet>
</main>
