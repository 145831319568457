export const MONTHS = {
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
} as const;

export const MONTH_BY_NUMBER = {
  1: MONTHS.JANUARY,
  2: MONTHS.FEBRUARY,
  3: MONTHS.MARCH,
  4: MONTHS.APRIL,
  5: MONTHS.MAY,
  6: MONTHS.JUNE,
  7: MONTHS.JULY,
  8: MONTHS.AUGUST,
  9: MONTHS.SEPTEMBER,
  10: MONTHS.OCTOBER,
  11: MONTHS.NOVEMBER,
  12: MONTHS.DECEMBER,
} as const;
