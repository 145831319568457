import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-required-asterisk',
  templateUrl: './required-asterisk.component.html',
  standalone: true,
  imports: [NgIf],
})
export class RequiredAsteriskComponent {
  @Input() disappear: boolean = false;
}
