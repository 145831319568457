<div
  class="relative z-10"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div
      class="flex min-h-full items-start justify-center p-4 text-center mt-24">
      <div
        class="relative transform overflow-hidden rounded-lg bg-white p-[24px] text-left shadow-xl transition-all w-[512px] h-auto">
        <div class="flex">
          @if (!isEditMode) {
            <img
              class="h-[40px] w-[40px]"
              src="../../../assets/delete-icon.png" />
          }

          <div class="w-[408px] flex flex-col ml-4">
            <span class="font-medium text-gray-900 text-lg mb-2 break-words">{{
              title
            }}</span>
            <span class="font-[400] text-gray-500 text-sm">
              {{ message || 'Are you sure you want to delete?' }} <br />This
              action cannot be undone.</span
            >
            <div class="mt-4 flex ml-auto">
              <button
                [disabled]="disableButtons"
                [class.cursor-not-allowed]="disableButtons"
                (click)="onCancelClick()"
                class="w-[81px] h-[38px] text-sm text-gray-500 border-2 border-gray-300 rounded-md">
                Cancel
              </button>
              <button
                [disabled]="disableButtons"
                [class.cursor-not-allowed]="disableButtons"
                (click)="onDeleteClick()"
                class="w-[81px] h-[38px] bg-red-600 text-white text-sm ml-4 rounded-md"
                [ngClass]="{ 'bg-blueDS-500': isEditMode }">
                {{ isEditMode ? 'Save' : 'Delete' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
