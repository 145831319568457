import { ErrorHandler, Injectable, NgZone, inject } from '@angular/core';
import { ToasterService } from 'src/app/components/shared/toaster/toaster-item/toaster.service';
import { EndpointError } from 'src/app/core/types/shared/Error.type';
import { getEndpointErrorMessage } from '../helper/getEndpointErrorMessage';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly _toasterService: ToasterService = inject(ToasterService);
  private readonly _ngZone: NgZone = inject(NgZone);

  handleError(error: EndpointError): void {
    console.error(error);

    this._ngZone.run(() => {
      this._toasterService.show(
        'error',
        getEndpointErrorMessage(error).error,
        getEndpointErrorMessage(error).validation,
        5000
      );
    });
  }
}
