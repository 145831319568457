<div
  *ngIf="dataCount > itemsPerPage"
  class="inline-block min-w-[100%] bottom-0 left-0">
  <nav
    class="inline-block flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
    <div class="-mt-px flex w-0 flex-1">
      <button
        type="button"
        (click)="goToPrev()"
        [disabled]="current === 1"
        [ngClass]="{ 'cursor-not-allowed': current === 1 }"
        class="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer">
        <svg
          class="mr-3 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path
            fill-rule="evenodd"
            d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
            clip-rule="evenodd" />
        </svg>
        Previous
      </button>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <ng-container *ngFor="let page of pages">
        <button
          type="button"
          class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium cursor-pointer"
          (click)="goToPage(page)"
          [ngClass]="{
            'text-blueDS-500 border-blueDS-500': page === current,
            'text-gray-500': page !== current,
          }">
          {{ page }}
        </button>
      </ng-container>
    </div>
    <div class="-mt-px flex w-0 flex-1 justify-end">
      <button
        type="button"
        (click)="goToNext()"
        [disabled]="current === lastPage"
        [ngClass]="{ 'cursor-not-allowed': current === lastPage }"
        class="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer">
        Next
        <svg
          class="ml-3 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path
            fill-rule="evenodd"
            d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
            clip-rule="evenodd" />
        </svg>
      </button>
    </div>
  </nav>
</div>
