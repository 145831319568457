import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-step',
  templateUrl: './step.component.html',
})
export class StepComponent {
  stepNumber: number = 1;
  isLast: boolean = false;
  isFirst: boolean = false;
  currentStep: number = 1;
}
