import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Officer } from './entity-officers.model';

@Injectable({
  providedIn: 'root',
})
export class OfficerService {
  constructor(private http: HttpClient) {}

  getOfficers(entityId: string): Observable<Officer[]> {
    return this.http
      .get<
        Officer[]
      >(`${environment.baseApiUrl}entity-officers/all/${entityId}`)
      .pipe(
        map((response: any) =>
          response.data.map((officer: Officer) => ({
            ...officer,
            date_of_birth: this.formatDate(officer.date_of_birth),
            fullName: `${officer.firstname} ${officer.lastname}`,
          }))
        )
      );
  }

  formatDate(date?: string): string {
    if (date) {
      const d = new Date(date);
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(d.getDate()).padStart(2, '0')}`;
    }
    return '';
  }

  createOfficer(officer: any): Observable<Officer> {
    return this.http.post<Officer>(
      `${environment.baseApiUrl}entity-officers`,
      officer
    );
  }

  updateOfficer(officer: any): Observable<Officer> {
    return this.http.put<Officer>(
      `${environment.baseApiUrl}entity-officers/${officer.id}`,
      officer
    );
  }

  deleteOfficer(officerId: number): Observable<any> {
    return this.http.delete(
      `${environment.baseApiUrl}entity-officers/${officerId}`
    );
  }
}
