import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { formErrorHandler } from 'src/app/core/helper/formErrorHandler.helper';

@Directive({
  standalone: true,
})
export class BaseFormComponent {
  @Output() submit: EventEmitter<boolean> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();

  @Input() isEditMode: boolean = false;
  @Input() formGroup!: FormGroup;

  onSubmit(): void {
    this.submit.emit(true);
  }

  onCancel(): void {
    this.cancel.emit(true);
  }

  errorMessage(control: AbstractControl | null) {
    if (!control) return;

    return formErrorHandler.call(this, control);
  }
}
