<div class="flex flex-col">
  <div>
    <app-steps-bar [steps]="steps" />
  </div>
  <form [formGroup]="formGroup">
    <app-stepper #stepper [(counter)]="counter">
      @switch (stepper.currentStep) {
        @case (1) {
          <app-step>
            <div step-content class="p-4 mb-10 flex flex-col gap-6">
              <h2 class="text-xl text-blueDS-600">Step 1: Select Service</h2>
              <app-service-tasks-form
                [services]="services"
                [tasks]="tasks"
                [serviceControl]="serviceControl"
                [tasksArrayForm]="tasksArrayForm" />
            </div>
            <div step-action class="flex justify-between">
              <button
                class="inline-flex justify-center py-2 px-4 text-gray-600 border-2 border-gray-300 rounded-md ml-4"
                (click)="cancel()">
                Cancel
              </button>
              <button
                class="inline-flex justify-center py-2 px-4 text-white bg-blueDS-600 border-2 border-blueDS-600 rounded-md mr-4"
                [ngClass]="{
                  'cursor-not-allowed bg-blueDS-400 border-blueDS-400':
                    !hasSelectedServiceTasks,
                }"
                [disabled]="!hasSelectedServiceTasks"
                (click)="nextStep(stepper)">
                Next
              </button>
            </div>
          </app-step>
        }
        @case (2) {
          <app-step>
            <div step-content class="p-4 mb-10 flex flex-col gap-6">
              <h2 class="text-xl text-blueDS-600">Step 2: Service Settings</h2>
              <app-periodicity-form
                [periodicities]="periodicities"
                [control]="servicePeriodicityControl" />
            </div>
            <div step-action class="flex justify-between">
              <button
                class="inline-flex justify-center py-2 px-4 text-gray-600 border-2 border-gray-300 rounded-md ml-4"
                (click)="previousStep(stepper)">
                Previous
              </button>
              <button
                class="inline-flex justify-center py-2 px-4 text-white bg-blueDS-600 border-2 border-blueDS-600 rounded-md mr-4"
                [ngClass]="{
                  'cursor-not-allowed bg-blueDS-400 border-blueDS-400':
                    !isServicePeriodicityControlValid,
                }"
                [disabled]="!isServicePeriodicityControlValid"
                (click)="nextStep(stepper)">
                Next
              </button>
            </div>
          </app-step>
        }
        @case (3) {
          <app-step>
            <div step-content class="p-4 mb-10 flex flex-col gap-6">
              <h2 class="text-xl text-blueDS-600">
                Step 3: Billing & Fees (Frequency of Billing)
              </h2>

              <app-periodicity-form
                [periodicities]="periodicities"
                [control]="billingPeriodicityControl" />
            </div>
            <div step-action class="flex justify-between">
              <button
                class="inline-flex justify-center py-2 px-4 text-gray-600 border-2 border-gray-300 rounded-md ml-4"
                (click)="previousStep(stepper)">
                Previous
              </button>
              <button
                class="inline-flex justify-center py-2 px-4 text-white bg-blueDS-600 border-2 border-blueDS-600 rounded-md mr-4"
                [ngClass]="{
                  'cursor-not-allowed bg-blueDS-400 border-blueDS-400':
                    !isBillingPeriodicityControlValid,
                }"
                [disabled]="!isBillingPeriodicityControlValid"
                (click)="nextStep(stepper)">
                Next
              </button>
            </div>
          </app-step>
        }
        @case (4) {
          <app-step>
            <div step-content class="p-4 mb-10 flex flex-col gap-6">
              <h2 class="text-xl text-blueDS-600">
                Step 4: Billing & Fees (Fee, Description, Disclosure)
              </h2>
              <app-service-details-form [controls]="serviceDetailsControls" />
            </div>
            <div step-action class="flex justify-between">
              <button
                class="inline-flex justify-center py-2 px-4 text-gray-600 border-2 border-gray-300 rounded-md ml-4"
                (click)="previousStep(stepper)">
                Previous
              </button>
              <button
                class="inline-flex justify-center py-2 px-4 text-white bg-blueDS-600 border-2 border-blueDS-600 rounded-md mr-4"
                [ngClass]="{
                  'cursor-not-allowed bg-blueDS-400 border-blueDS-400':
                    !isFeeControlValid,
                }"
                [disabled]="!isFeeControlValid"
                (click)="nextStep(stepper)">
                Next
              </button>
            </div>
          </app-step>
        }
        @case (5) {
          <app-step>
            <div step-content class="p-4 mb-10 flex flex-col gap-6">
              <h2 class="text-xl text-blueDS-600">Step 5: Start Date</h2>
              <app-service-start-date-form [control]="startDateControl" />
            </div>
            <div step-action class="flex justify-between">
              <button
                class="inline-flex justify-center py-2 px-4 text-gray-600 border-2 border-gray-300 rounded-md ml-4"
                (click)="previousStep(stepper)">
                Previous
              </button>
              <button
                class="inline-flex justify-center py-2 px-4 text-white bg-blueDS-600 border-2 border-blueDS-600 rounded-md mr-4"
                [ngClass]="{ 'cursor-not-allowed bg-blueDS-400': isFormValid }"
                [disabled]="isFormValid"
                (click)="saveService()">
                Save
              </button>
            </div>
          </app-step>
        }
      }
    </app-stepper>
  </form>
</div>
