import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underscoreToTitle',
})
export class UnderscoreToTitlePipe implements PipeTransform {
  transform(value: any): string {
    if (typeof value === 'string')
      return value.replace(/(['_'])/g, ' ').toString();
    else return value;
  }
}
