<h4
  [appStatus]="title"
  [ngClass]="{
    'py-1 rounded-2xl flex items-center justify-center w-16':
      title === 'active',
  }">
  {{ title || '--' }}
</h4>
<p class="text-xs text-gray-400" *ngIf="description">
  {{ description }}
</p>
