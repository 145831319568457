import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticateService } from 'src/app/auth/authenticate.service';
import { getEndpointErrorMessage } from 'src/app/core/helper/getEndpointErrorMessage';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public tokenValue: string = '';
  public isLoginInvalid: boolean = false;
  private formChangesSubs = new Subscription();

  public profileForm = this.fb.group({
    login_email: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    password: new FormControl('', {
      validators: Validators.required,
      nonNullable: true,
    }),
  });
  errorMessage: string = '';

  public get getTokenValue() {
    return this.tokenValue;
  }

  constructor(
    protected authenticateService: AuthenticateService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.formChangesSubs = this.profileForm.valueChanges.subscribe(
      () => (this.isLoginInvalid = false)
    );
  }

  ngOnDestroy() {
    this.formChangesSubs.unsubscribe();
  }

  onLoginClick() {
    if (
      this.profileForm.value.login_email!.trim() === '' ||
      this.profileForm.value.password!.trim() === ''
    )
      return;

    this.authenticateService
      .login(
        this.profileForm.value.login_email!,
        this.profileForm.value.password!
      )
      .subscribe({
        next: response => {
          localStorage.setItem('token', response.access_token);
          this.router.navigate(['']);
        },
        error: error => {
          this.isLoginInvalid = true;
          this.errorMessage = getEndpointErrorMessage(error).error;
        },
      });
  }
}
