<div
  class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-md border">
  <form [formGroup]="entityForm">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="font-medium text-black">Entity Information</h3>
      <div class="py-5">
        <div class="flex flex-wrap">
          <input-block
            formControlName="entity_name"
            class="w-2/3 mb-4 pr-8"
            [isEditMode]="true"
            label="Entity Name"
            [setInputWidth]="'full'">
          </input-block>
          <select-block
            formControlName="et_id"
            class="w-1/3 mb-4 pr-8"
            [isEditMode]="true"
            [options]="entity_types"
            label="Entity Type:"
            [setSelectWidth]="'full'"
            idProperty="id"
            displayProperty="name">
          </select-block>
        </div>
      </div>
    </div>
  </form>

  <div *ngFor="let group of entityPropertiesGroupe">
    <form [formGroup]="form">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="font-medium text-black">{{ group.groupName }}</h3>
        <div class="py-5">
          <div
            class="flex flex-wrap sm:flex-col md:flex-col lg:flex-col xl:flex-row"
            [ngClass]="{ 'w-2/3': group.propertiesList.length > 4 }">
            <ng-container
              *ngFor="let item of group.propertiesList; let itemIndex = index">
              <input-block
                *ngIf="
                  item.input_type === 'text' ||
                  item.input_type === 'numeric' ||
                  item.input_type === 'ssn' ||
                  item.input_type === 'datetext'
                "
                [formControlName]="item.name"
                class="mb-4 pr-8"
                [type]="item.input_type === 'datetext' ? 'date' : 'text'"
                [label]="item.name"
                [isEditMode]="true"
                [setInputWidth]="'full'"
                [IsuploadSupport]="item.upload_support"
                [ngClass]="getClass(group.propertiesList.length, itemIndex)"
                [uploadCounter]="item.gr_uploads.length"
                (onUpload)="openUploadModel(item)">
              </input-block>

              <select-block
                *ngIf="item.input_type === 'select'"
                [formControlName]="item.name"
                class="mb-4 pr-8"
                [options]="item.options"
                [label]="item.name"
                [setSelectWidth]="'full'"
                [isEditMode]="true"
                [ngClass]="getClass(group.propertiesList.length, itemIndex)"
                idProperty="id"
                displayProperty="name">
              </select-block>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

@if (uploadModalStatus === 'open') {
  <modal [modalAreaWidth]="'860px'">
    <upload-files
      [title]="uploadModalTitle"
      [headerTitles]="headerTitles"
      [files]="files"
      [hasLoading]="hasLoading"
      (deleteFile)="openDeleteModal($event)"
      (uploadFiles)="onFileSelected($event)"
      (updateFileStatus)="updateFileStatus($event)"
      (cancel)="cancel($event)"
      (downloadFile)="downloadFile($event)" />
  </modal>
}

@if (deleteModalStatus === 'open') {
  <confirmation-modal
    [title]="deleteModalTitle"
    (emitCancel)="cancel($event, true)"
    (emitDelete)="deleteFile()" />
}
