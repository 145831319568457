<table
  aria-describedby="Entity Contact List"
  class="min-w-full divide-y divide-gray-300 table-fixed bg-gray-50 text-gray-400">
  <thead class="bg-gray-50">
    <th
      scope="col"
      class="px-6 py-4 text-left text-sm font-[500] text-gray-500 uppercase">
      Contact Name
    </th>
    <th scope="col"></th>
    <th scope="col"></th>
    <th scope="col"></th>
  </thead>
  <tbody class="divide-y">
    @for (contact of entityContacts; track contact.id) {
      <tr class="w-full">
        <td class="px-6 py-4 text-gray-900 font-[500] text-sm">
          {{ contact?.firstName | titlecase }}
          {{ contact?.lastName | titlecase }}
        </td>

        <td
          class="px-6 py-4 font-[400] text-blueDS-500 w-[100px] cursor-pointer text-sm"
          (click)="navigateToDetails(contact.id)">
          View/Edit
        </td>
        <td
          class="px-6 py-4 text-red-400 font-[400] w-[100px] cursor-pointer text-sm"
          (click)="onDelete(contact)">
          Delete
        </td>
      </tr>
    } @empty {
      <div class="flex justify-center p-6">
        <p>No result.</p>
      </div>
    }
  </tbody>
</table>
