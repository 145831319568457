import { CommonModule, NgIf, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  ReactiveFormsModule,
} from '@angular/forms';
import { RequiredAsteriskComponent } from 'src/app/components/shared/required-asterisk/required-asterisk.component';
import { BaseFormComponent } from 'src/app/core/directives/base-form/base-form.directive';
import { EntityContactFormArrays } from 'src/app/core/types/EntityContact.type';

@Component({
  standalone: true,
  selector: 'app-entity-contact-services-form',
  templateUrl: './entity-contact-services-form.component.html',
  imports: [
    CommonModule,
    RequiredAsteriskComponent,
    ReactiveFormsModule,
    NgIf,
    TitleCasePipe,
  ],
})
export class EntityContactServicesFormComponent extends BaseFormComponent {
  @Output() addFormControl = new EventEmitter();
  @Output() addEntityContactService = new EventEmitter();
  @Output() deleteFormControl = new EventEmitter();
  @Output() updatedEntityContactServicesStatus = new EventEmitter();

  @Input() entityContactFormArrays!: EntityContactFormArrays[];

  onAddFormControl(formArrayType: string) {
    this.addFormControl.emit({ name: formArrayType });
  }

  onAddEntityContactService(formArrayType: string, index: number) {
    this.addEntityContactService.emit({ name: formArrayType, index });
  }

  onDeleteFormControl(
    formArrayType: string,
    index: number,
    saved: boolean = false
  ) {
    this.deleteFormControl.emit({ name: formArrayType, index, saved });
  }

  canAddEntityContactService(name: string, index: number): boolean {
    const controlValue: unknown = (this.formGroup.get(name) as FormArray)
      ?.controls[index]?.value;

    if (controlValue) {
      return !!Object.values(controlValue)[1];
    }

    return false;
  }

  isControlDisabled(
    formArrayName: string,
    targetControlName: string,
    index: number
  ): boolean {
    const control: AbstractControl | null = (
      this.formGroup.get(formArrayName) as FormArray
    )?.controls[index].get(targetControlName);

    if (control) {
      return control.disabled;
    }

    return false;
  }

  getFormControlByName(
    formArrayName: string,
    targetControlName: string,
    index: number
  ) {
    return (this.formGroup.get(formArrayName) as FormArray)?.controls[
      index
    ].get(targetControlName);
  }

  getUpdatedEntityContactServicesStatus(
    formArrayName: string,
    targetControlName: string,
    index: number
  ) {
    this.updatedEntityContactServicesStatus.emit({
      formArrayName,
      targetControlName,
      index,
    });
  }
}
