@if (entityService !== undefined) {
  <div class="p-4">
    <div class="flex justify-between items-center py-4">
      <div class="flex gap-6">
        <p>
          Start Date :
          <strong>
            {{ entityService.startDate || '--' }}
          </strong>
        </p>
        <p>
          Service Periodicity :
          <strong>
            {{ entityService.servicePeriodicity || '--' }}
          </strong>
        </p>

        @if (isNonActiveEntityService) {
          <p>
            End Date :
            <strong>
              {{ entityService.endDate || '--' }}
            </strong>
          </p>
        }
      </div>

      <div class="flex gap-5">
        @if (!isNonActiveEntityService) {
          <app-icon-button
            iconClass="icon-hand-stop-o"
            title="Stop Service"
            background="bg-red-500"
            additionalClass="hover:bg-red-600 hover:text-white"
            textColor="text-white"
            (click)="onStopEntityService()">
          </app-icon-button>
          <app-icon-button
            iconClass="icon-plus"
            title="Add Service Task"
            background="bg-blueDS-600"
            additionalClass="hover:bg-blueDS-500 hover:text-white"
            textColor="text-white"
            (click)="onAddEntityServiceTask(entityService)">
          </app-icon-button>
        }
      </div>
    </div>

    @if (serviceTasks !== undefined) {
      <table-basic
        [editAction]="false"
        [deleteAction]="!isNonActiveEntityService"
        [columnsToDisplay]="columnsToDisplay"
        [tableData]="serviceTasks"
        (onDeleteClick)="onDeleteEntityServiceTask($event)"></table-basic>
    } @else {
      <div class="flex justify-center">
        <app-loading />
      </div>
    }

    @if (stopEntityServiceModalOpen) {
      <modal [modalAreaWidth]="'auto'">
        <div step-content class="flex flex-col gap-6">
          <h2 class="font-medium text-gray-900 text-lg mb-2">
            Select Service End Date
          </h2>
          <app-service-start-date-form [control]="endDateFormControl" />
          <div class="pt-4">
            <div class="flex justify-end">
              <button
                type="button"
                class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
                (click)="stopEntityServiceModalOpen = false">
                Cancel
              </button>
              <button
                type="submit"
                [disabled]="endDateForm.invalid"
                [class.cursor-not-allowed]="endDateForm.invalid"
                class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blueDS-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blueDS-700 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
                [ngClass]="{ 'bg-blueDS-300': endDateForm.invalid }"
                (click)="stopEntityService()">
                Stop service
              </button>
            </div>
          </div>
        </div>
      </modal>
    }

    @if (addEntityServiceTaskModalOpen) {
      <modal [modalAreaWidth]="'auto'">
        <div step-content class="flex flex-col gap-6">
          <h2 class="font-medium text-gray-900 text-lg mb-2">
            Select Service task
          </h2>
          <app-service-tasks-form
            [tasks]="tasks"
            [tasksArrayForm]="tasksArrayForm" />
          <div class="pt-4">
            <div class="flex justify-end">
              <button
                type="button"
                class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
                (click)="cancelAddServiceTask()">
                Cancel
              </button>
              <button
                type="submit"
                class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blueDS-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blueDS-700 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
                [class.cursor-not-allowed]="selectedServiceTasks.length === 0"
                [class.bg-blueDS-300]="selectedServiceTasks.length === 0"
                [disabled]="selectedServiceTasks.length === 0"
                (click)="addEntityServiceTask()">
                Add service task
              </button>
            </div>
          </div>
        </div>
      </modal>
    }

    @if (deleteEntityServiceModalOpen) {
      <confirmation-modal
        title="Delete Task"
        [propertyToDisplay]="'name'"
        (emitCancel)="deleteEntityServiceModalOpen = false"
        (emitDelete)="deleteEntityServiceTask($event)" />
    }
  </div>
} @else {
  <div class="flex justify-center">
    <app-loading />
  </div>
}
