import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'table-with-input',
  templateUrl: './table-with-input.component.html',
  styleUrls: ['./table-with-input.component.css'],
})
export class TableWithInputComponent implements OnInit, OnDestroy {
  @Input()
  set tableData(data: any[]) {
    this.originalData = data;
    this.filteredData = data;
  }
  @Input() isRowClickable: boolean = false;
  @Input() itemsPerPage: number = 25;
  @Input() editAction: boolean = false;
  @Input() deleteAction: boolean = false;
  @Input()
  set setWidth(value: number) {
    if (value) this.colWidth = value;
  }
  @Input() setTimeColumns: any[] = [];
  @Input() setNumericColumns: any[] = [];
  @Input() hiddenColumns: any[] = [];
  @Input() setStatusColor: boolean = false;
  @Input() columnsToDisplay: any[] = [];
  @Input() dropdownOptions: any[] = [];
  @Input() actionColumns: any[] = [];
  @Input() isCustomField: boolean = false;
  @Input() useTrashCanIcon: boolean = false;
  @Input() upperCaseSecondColumn: boolean = false;
  @Input() isInputsReadonly: boolean = false;

  @Output()
  private onEditClick: EventEmitter<any> = new EventEmitter();
  @Output()
  private onDeleteClick: EventEmitter<any> = new EventEmitter();
  @Output()
  private onActionClick: EventEmitter<{ name: string; data: any }> =
    new EventEmitter();
  @Output()
  private onChangeEmit: EventEmitter<any> = new EventEmitter();

  colWidth: number = 48;
  originalData: any[] = [];
  filteredData: any[] = [];
  customFieldOption: boolean[] = [true, false];

  current: number = 0;
  startSlice: number = 0;
  endSlice: number = 0 + this.itemsPerPage;
  private _valueChanged = new Subject<string>();
  private _subscriptions = new Subscription();

  public setItemsDisplay(event: any) {
    this.startSlice = event.start;
    this.endSlice = event.end;
  }

  public rowEdit(data: any) {
    this.onEditClick.emit(data);
  }

  public rowDelete(data: any) {
    this.onDeleteClick.emit(data);
  }

  public customAction(columnName: string, rowData: any) {
    this.onActionClick.emit({ name: columnName, data: rowData });
  }

  public unsortKeyValue() {
    return 0;
  }

  private isAscending: boolean = false;

  public sortColumns(list: any[] | undefined, column: string) {
    this.isAscending = !this.isAscending;
    let sortedArray = (list || []).sort((a, b) => {
      if (a[column] > b[column]) {
        return this.isAscending ? 1 : -1;
      }
      if (a[column] < b[column]) {
        return this.isAscending ? -1 : 1;
      }
      return 0;
    });
    this.filteredData = sortedArray;
  }

  public updateChange(data: any) {
    this._valueChanged.next(data);
  }

  public setFilteredData(filteredData: any) {
    this.filteredData = filteredData;
  }

  ngOnInit(): void {
    this._subscriptions.add(
      this._valueChanged
        .pipe(debounceTime(1000))
        .subscribe(changeValue => this.onChangeEmit.emit(changeValue))
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
