<div class="flex flex-col px-2">
  <app-icon-button
    (click)="openModal('add')"
    iconClass="icon-plus"
    title="Add Officer"
    class="self-end"></app-icon-button>
  <table-basic
    *ngIf="officerData.length; else emptyOficcerData"
    [editAction]="true"
    [deleteAction]="true"
    [tableData]="officerData"
    [columnsToDisplay]="columnsToDisplay"
    (onDeleteClick)="openModal('delete', $event)"
    (onEditClick)="openEditModal($event)"></table-basic>
  <ng-template #emptyOficcerData>
    <app-not-found class="mt-8" label="No officers found"></app-not-found>
  </ng-template>
</div>

<confirmation-modal
  [rowData]="selectedOfficer"
  title="Delete Officer"
  [propertyToDisplay]="'firstname'"
  (emitCancel)="modalState = 'none'"
  (emitDelete)="deleteOfficer($event)"
  *ngIf="modalState === 'delete'"></confirmation-modal>

<modal
  *ngIf="modalState === 'add' || modalState === 'edit'"
  [modalAreaWidth]="'60%'">
  <span class="font-medium text-gray-900 text-lg mb-2"
    >{{ modalState === 'add' ? 'Create' : 'Update' }} Officer</span
  >
  <div class="border-t-2 border-gray-200 my-4"></div>
  <form [formGroup]="officerForm" (ngSubmit)="onSubmit()">
    <div class="flex justify-between items-center">
      <div
        class="w-1/2 flex flex-col gap-6 mb-8 px-6 border-r-2 border-gray-200">
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'First Name', controlName: 'firstname' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'Middle Name', controlName: 'middlename' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'Last Name', controlName: 'lastname' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'Title', controlName: 'title' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            dateInputTemplate;
            context: { label: 'Date of Birth', controlName: 'date_of_birth' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'SSN', controlName: 'ssn' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'DLN', controlName: 'dln' }
          "></ng-container>
      </div>

      <div class="w-1/2 flex flex-col gap-6 mb-8 pl-6">
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'Phone', controlName: 'phone' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'Email', controlName: 'email' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'Address', controlName: 'address' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'City', controlName: 'city' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'State', controlName: 'state' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'ZIP', controlName: 'zip' }
          "></ng-container>
        <ng-container
          *ngTemplateOutlet="
            inputTemplate;
            context: { label: 'Country', controlName: 'country' }
          "></ng-container>
      </div>
    </div>

    <ng-template #inputTemplate let-label="label" let-controlName="controlName">
      <div class="flex flex-row justify-between items-center">
        <label
          [for]="controlName"
          class="block text-sm font-medium leading-6 text-gray-900 w-[200px]"
          >{{ label }}
          <span *ngIf="isRequired(controlName)" [class]="'text-red-500'"
            >*</span
          ></label
        >
        <div class="flex-grow ml-4 w-3/4">
          <input
            [formControlName]="controlName"
            type="text"
            [name]="controlName"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6"
            placeholder="Enter {{ label }} ..." />
        </div>
      </div>
    </ng-template>

    <ng-template
      #dateInputTemplate
      let-label="label"
      let-controlName="controlName">
      <div class="flex flex-row justify-between items-center">
        <label
          [for]="controlName"
          class="block text-sm font-medium leading-6 text-gray-900 w-[200px]"
          >{{ label }}</label
        >
        <div class="flex-grow ml-4 w-3/4">
          <input
            [formControlName]="controlName"
            type="date"
            [name]="controlName"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6"
            placeholder="Enter {{ label }} ..." />
        </div>
      </div>
    </ng-template>

    <div class="border-t-2 border-gray-200"></div>

    <div class="flex flex-row w-full mt-8">
      <button
        [disabled]="officerForm.invalid"
        class="ml-auto w-[120px] h-[38px] bg-blueDS-500 text-white text-sm rounded-md">
        {{ modalState === 'edit' ? 'Save Changes' : 'Add Officer' }}
      </button>
      <button
        (click)="modalState = 'none'"
        class="w-[120px] h-[38px] text-sm text-gray-500 border-2 border-gray-300 rounded-md ml-4">
        Cancel
      </button>
    </div>
  </form>
</modal>
