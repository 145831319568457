<div class="space-y-4 divide-y divide-gray-200 flex flex-col">
  <h1 class="text-lg font-medium leading-6 text-gray-900">
    Add Existing Contact
  </h1>
  @if (existingContacts !== undefined) {
    <div class="flex flex-col gap-4 pt-4">
      @for (existingContact of existingContacts; track $index) {
        <div class="flex gap-3">
          <p class="w-[220px] text-sm">
            {{
              existingContact?.firstName + ' ' + existingContact?.lastName
                | titlecase
            }}
          </p>
          <button
            class="text-blueDS-600 text-sm"
            (click)="onAdd(existingContact?.id)">
            + Add contact
          </button>
        </div>
      } @empty {
        <div class="flex justify-start text-gray-500">
          <p>No contact available.</p>
        </div>
      }
    </div>
  } @else {
    <div class="flex justify-center">
      <app-loading />
    </div>
  }
  <div class="w-full flex justify-end pt-4">
    <button
      type="button"
      class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
      (click)="onCancel()">
      Close
    </button>
  </div>
</div>
