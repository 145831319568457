import { environment } from 'src/environments/environment';

export const settingsUrl = {
  entityTypes: `${environment.baseApiUrl}entity-types`,
  customFields: `${environment.baseApiUrl}properties`,
  general: `${environment.baseApiUrl}settings`,
  services: `${environment.baseApiUrl}services`,
  serviceTasks: `${environment.baseApiUrl}service-tasks`,
  base: environment.baseApiUrl,
  notifications: `${environment.baseApiUrl}notification-templates`,
};

export interface EntityType {
  id: number;
  name: string;
  ownership_type: string;
  created_at: Date;
  updated_at: Date;
  updated_by: number;
  deleted_at: Date;
  groups: any[];
}

export enum customFieldTypeEnum {
  text = 'text',
  longtext = 'longtext',
  select = 'select',
  multiselect = 'multiselect',
  calendar = 'calendar',
  numeric = 'numeric',
  datatext = 'datetext',
  ssn = 'ssn',
  email = 'email',
  ein = 'ein',
  'shared-upload' = 'shared-upload',
}

export interface CustomField {
  id: number;
  name: string;
  input_type: string;
  upload_support: boolean;
  gr_tag: string;
  default_value: null;
  created_at: Date;
  updated_at: Date;
  updated_by: number;
  deleted_at: Date;
  options?: CustomFieldOption[];
}

export interface CustomFieldOption {
  gr_property_id: number;
  select_option: string;
  updated_at: Date;
  created_at: Date;
  id: number;
  list_order?: number;
}
