<div
  (click)="openUploadModel(entityGeneralUploads)"
  class="mt-6 bg-white w-[98%] mx-auto cursor-pointer p-8 border-2 border-gray-300 rounded-lg border-dashed flex items-center flex-col">
  <img src="../../../../../assets/upload-image.PNG" class="h-16 w-16 m-3" />
  <div class="self-center flex items-center flex-col">
    <div>
      <span class="text-blueDS-500 font-[500]">Upload a file</span>
      <span class="text-gray-600 font-[500]"> or drag and drop</span>
    </div>
    <span class="text-gray-500 font-[500] uppercase"
      >PNG, JPG, GIF up to 100MB</span
    >
  </div>
</div>

@if (uploadModalStatus === 'open') {
  <modal [modalAreaWidth]="'860px'">
    <upload-files
      [title]="uploadModalTitle"
      [headerTitles]="headerTitles"
      [files]="files"
      [hasLoading]="hasLoading"
      (deleteFile)="openDeleteModal($event)"
      (uploadFiles)="onFileSelected($event)"
      (updateFileStatus)="updateFileStatus($event)"
      (cancel)="cancel($event)"
      (downloadFile)="downloadFile($event)" />
  </modal>
}

@if (deleteModalStatus === 'open') {
  <confirmation-modal
    [title]="deleteModalTitle"
    (emitCancel)="cancel($event, true)"
    (emitDelete)="deleteFile()" />
}
