export const ENTITY_CONTACT_FORM_ARRAYS = {
  EMAILS: 'emails',
  SMS: 'sms',
  PHONES: 'phones',
} as const;

export const ENTITY_CONTACT_SERVICES_CONFORMATION_MESSAGES = {
  EMAIL_ON:
    'Are you sure you want to activate this email address? This address will start receiving system notifications.',
  EMAIL_OFF:
    'Are you sure you want to deactivate this email address? This address will stop receiving system notifications.',
  SMS_ON:
    'Are you sure you want to activate this SMS number? This number will start receiving text system notifications.',
  SMS_OFF:
    'Are you sure you want to deactivate this SMS number? This number will stop receiving text system notifications.',
  PHONES: 'Are you sure you want to update the phone number?',
} as const;
