<div class="flex flex-col gap-5">
  <div class="flex flex-col gap-3">
    @for (periodicity of periodicities; track $index) {
      <span class="flex items-center gap-2">
        <input
          type="radio"
          name="servicePeriodicity"
          [formControl]="control"
          [value]="periodicity.toLocaleUpperCase()"
          class="h-4 w-4 border-gray-300 text-blueDS-600 focus:ring-blueDS-600" />
        {{ periodicity | titlecase }}</span
      >
    }
  </div>
</div>
