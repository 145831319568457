<div class="flex mb-4">
  <app-switch
    [isActive]="isSendNotificationActive"
    (toggle)="toggleSendNotification()" />
  Send notifications for this task
</div>

@if (!isSMS) {
  <div class="flex items-center gap-4 my-8">
    <input
      type="text"
      [formControl]="emailSubjectFormControl"
      class="w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6" />
  </div>
}
