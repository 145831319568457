<form
  [formGroup]="formGroup"
  class="space-y-4 divide-y divide-gray-200 flex flex-col">
  <div>
    <h1 class="text-lg font-medium leading-6 text-gray-900">
      {{ isEditMode ? 'Edit' : 'Add' }} Entity Contact
    </h1>
  </div>
  <div class="flex flex-row items-start w-full">
    <div class="flex flex-col gap-3 w-full">
      <div class="flex flex-row gap-3 mt-6 w-full justify-between items-center">
        <div class="block w-[220px]">
          <app-required-asterisk>
            <label
              class="block text-sm font-medium leading-6 text-gray-900"
              for="ip"
              >First Name</label
            >
          </app-required-asterisk>
        </div>
        <div class="block w-full">
          <input
            type="text"
            name="firstName"
            formControlName="firstName"
            placeholder="Enter first name"
            class="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6"
            [ngClass]="{
              'border-red-500': errorMessage(getFormControlByName('firstName')),
            }" />

          <!--
          <p class="text-red-500 text-sm">
            {{ errorMessage(getFormControlByName('firstName')) }}
          </p>
          -->
        </div>
      </div>
      <div class="flex flex-row gap-3 w-full justify-between items-center">
        <div class="block w-[220px]">
          <app-required-asterisk>
            <label
              class="block text-sm font-medium leading-6 text-gray-900"
              for="lastName"
              >Last Name</label
            >
          </app-required-asterisk>
        </div>
        <div class="block w-full">
          <input
            type="text"
            name="lastName"
            formControlName="lastName"
            placeholder="Enter last name"
            class="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6"
            [ngClass]="{
              'border-red-500': errorMessage(getFormControlByName('lastName')),
            }" />

          <!--
          <p class="text-red-500 text-sm">
            {{ errorMessage(getFormControlByName('lastName')) }}
          </p>
          -->
        </div>
      </div>
      <div class="flex flex-row gap-3 w-full justify-between items-center">
        <label
          class="w-[220px] block text-sm font-medium leading-6 text-gray-900"
          for="alias"
          >Alias</label
        >
        <input
          type="text"
          name="alias"
          formControlName="alias"
          placeholder="Enter contact alias"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6" />
      </div>
      <div class="flex flex-row gap-3 w-full justify-between items-center">
        <label
          class="w-[220px] block text-sm font-medium leading-6 text-gray-900"
          for="billingAddress"
          >Street Address</label
        >
        <input
          type="text"
          name="billingAddress"
          formControlName="billingAddress"
          placeholder="Enter street address"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6" />
      </div>
      <div class="flex flex-row gap-3 w-full justify-between items-center">
        <label
          class="w-[220px] block text-sm font-medium leading-6 text-gray-900"
          for="billingCity"
          >City</label
        >
        <input
          type="text"
          name="billingCity"
          placeholder="Enter city"
          formControlName="billingCity"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6" />
      </div>
      <div class="flex flex-row gap-3 w-full justify-between items-center">
        <label
          class="w-[220px] block text-sm font-medium leading-6 text-gray-900"
          for="billingState"
          >State</label
        >
        <input
          type="text"
          name="billingState"
          placeholder="Enter state or province"
          formControlName="billingState"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6" />
      </div>
      <div class="flex flex-row gap-3 w-full justify-between items-center">
        <label
          class="w-[220px] block text-sm font-medium leading-6 text-gray-900"
          for="billingZip"
          >Zip Code</label
        >
        <input
          type="number"
          name="billingZip"
          placeholder="Enter zip or postal code"
          formControlName="billingZip"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blueDS-500 sm:text-sm sm:leading-6" />
      </div>
    </div>
  </div>

  <div class="flex justify-end pt-4">
    @if (!isEditMode) {
      <button
        type="button"
        class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2"
        (click)="onCancel()">
        Cancel
      </button>
    }
    <button
      type="button"
      (click)="onSubmit()"
      [disabled]="formGroup.invalid"
      [class.cursor-not-allowed]="formGroup.invalid"
      class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blueDS-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blueDS-700 focus:outline-none focus:ring-2 focus:ring-blueDS-500 focus:ring-offset-2">
      Save Changes
    </button>
  </div>
</form>
