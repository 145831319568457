<div class="h-96 overflow-y-auto">
  @if (staticTags && staticTags.length) {
    <div class="flex flex-col gap-3 mt-3 h-auto">
      @for (staticTag of staticTags; track $index) {
        <div class="flex mr-2">
          <div
            class="text-gray-600 bg-gray-100 rounded-s-md p-2 cursor-pointer"
            (dblclick)="tagClicked(staticTag)">
            {{ staticTag.name }} {{ staticTag.tag }}
          </div>
        </div>
      }
    </div>
  }
  @if (hasNotificationSettings) {
    <h3 class="mb-3 mt-6">
      Custom fields
      <app-tooltip
        class="tooltip"
        [title]="customFieldsTooltipText"
        icon="&#9432;" />
    </h3>

    @if (customFields && customFields.length && hasNotificationSettings) {
      <select
        [formControl]="customFieldControl"
        class="w-full border-slate-300 rounded-md placeholder-slate-400 focus:border-blueDS-400 focus:ring-1 focus:ring-blueDS-500 bg-white">
        <option selected disabled value="">Select custom field</option>
        @for (customField of customFields; track $index) {
          <option [value]="customField?.id">
            {{ customField?.name | titlecase }}
          </option>
        }
      </select>
    } @else {
      <app-loading />
    }

    @if (tags) {
      <div class="flex flex-col gap-3 mt-3 h-80 overflow-y-auto">
        @for (tag of tags; track $index) {
          <div class="flex mr-2">
            <div
              class="text-gray-600 bg-gray-100 rounded-s-md p-2 cursor-pointer"
              (dblclick)="tagClicked(tag)">
              {{ tag.name }} {{ tag.tag }}
            </div>
            <button
              class="text-gray-400 bg-gray-100 rounded-e-md p-2"
              (click)="deleteTag(tag)">
              &#10005;
            </button>
          </div>
        } @empty {
          <div class="flex justify-center p-6">
            <p>No result.</p>
          </div>
        }
      </div>
    } @else {
      <app-loading />
    }
  }
</div>
