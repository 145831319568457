export function getYears(): number[] {
  let currentYear: number = new Date().getFullYear();
  const EARLIEST_YEAR: number = 1970;
  const years: number[] = [];

  while (currentYear >= EARLIEST_YEAR) {
    years.push(currentYear);
    currentYear -= 1;
  }

  return years;
}
