import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { StepComponent } from './step/step.component';

@Component({
  standalone: true,
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
})
export class StepperComponent {
  @Input() counter: number = 1;
  @Output() counterChange: EventEmitter<number> = new EventEmitter<number>();

  private _currentStep: number = 1;
  private _internalSteps!: QueryList<StepComponent>;

  ngOnChanges() {
    this._currentStep = this.counter;

    if (this._internalSteps) {
      this._setChildSteps();
    }
  }

  @ContentChildren(StepComponent)
  set stepsContent(steps: QueryList<StepComponent>) {
    if (steps) {
      this._internalSteps = steps;
      this._internalSteps.last.isLast = true;
      this._internalSteps.first.isFirst = true;
      this._registerSteps();
    }
  }

  nextStep() {
    this.counter++;
    this.counterChange.emit(this.counter);
  }

  previousStep() {
    this.counter--;
    this.counterChange.emit(this.counter);
  }

  setStep(step: any) {
    this.counterChange.emit(step);
  }

  private _registerSteps(): void {
    this._internalSteps.forEach((step: StepComponent, index: number) => {
      step.currentStep = this._currentStep;
      step.stepNumber = index + 1;
    });
  }

  private _setChildSteps(): void {
    this._internalSteps.forEach((step: StepComponent) => {
      step.currentStep = this._currentStep;
    });
  }

  get currentStep(): number {
    return this._currentStep;
  }
}
