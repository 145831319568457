import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billing-log',
  templateUrl: './billing-log.component.html',
  styleUrls: ['./billing-log.component.css'],
})
export class BillingLogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
