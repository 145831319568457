import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverseIncludesFilterByKey',
})
export class ReverseIncludesFilterByKeyPipe implements PipeTransform {
  transform(items: any[], itemsToRemove: any[], keyToUse?: string): any[] {
    let filteredItems: any[] = [];

    itemsToRemove.forEach(itemToRemove => {
      items.forEach(item => {
        if (item.id !== itemToRemove.keyToUse) {
          filteredItems.push(item);
        }
      });
    });

    return filteredItems;
  }
}
