import {
  Component,
  DestroyRef,
  ErrorHandler,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { PAGE_TYPE } from 'src/app/components/shared/upload-files/enums/PageType.enum';
import { FileManagement } from 'src/app/components/shared/upload-files/helper/file-management';
import { PageType } from 'src/app/components/shared/upload-files/types/PageType';
import { MODAL_STATUS } from 'src/app/core/enums/Modal.enum';
import { MONTH_BY_NUMBER } from 'src/app/core/enums/Months.enum';
import { QUARTER_BY_NUMBER } from 'src/app/core/enums/Quarter.enum';
import {
  EntityServiceLog,
  EntityServiceLogInfo,
} from 'src/app/core/types/EntityService.type';
import { ModalStatus } from 'src/app/core/types/Modal.type';
import { Month } from 'src/app/core/types/shared/Month.type';
import { Quarter } from 'src/app/core/types/shared/Quarter.type';
import { SERVICE_LOG_STATUS } from './enums/ServiceLogStatus.enum';
import { ServiceLogStatus } from './types/ServiceLogStatus.type';

@Component({
  selector: 'entity-service-log',
  templateUrl: './entity-service-log.component.html',
  styleUrls: ['./entity-service-log.component.css'],
})
export class EntityServiceLogComponent
  extends FileManagement
  implements OnInit
{
  private readonly _router: Router = inject(Router);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);
  private readonly _errorHandler: ErrorHandler = inject(ErrorHandler);

  entityServiceLogStatus: ServiceLogStatus[] =
    Object.values(SERVICE_LOG_STATUS);
  isOpen: boolean = true;
  selectedYear: number = 0;
  navYears: any;
  serviceLogs!: EntityServiceLog;
  serviceLogInfo!: EntityServiceLogInfo;
  serviceLogDisplayStatus!: { [year: string]: boolean }[];
  entityId!: string;
  entityServiceId!: string;
  entityServiceLogForm!: FormGroup;
  addPeriodModalStatus: ModalStatus = MODAL_STATUS.CLOSE;
  addPeriodForm!: FormGroup;
  monthByNumber: Month[] = Object.values(MONTH_BY_NUMBER);
  quarterByNumber: Quarter[] = Object.values(QUARTER_BY_NUMBER);
  override pageType: PageType = PAGE_TYPE.SERVICE_LOG;

  ngOnInit(): void {
    this.entityServiceId = this._router.url.split('/').at(-2) ?? '';
    this.entityId = this._router.url.split('/').at(-4) ?? '';
    this.entityServiceLogForm = this._initEntityServiceLogForm();

    this._getServiceLog().subscribe({
      next: serviceLog => {
        this.serviceLogs = serviceLog;

        this.serviceLogDisplayStatus = Object.keys(this.serviceLogs).map(
          year => ({
            [year]: false,
          })
        );
      },
    });
  }

  toggle(isYearly: boolean, year: any, index: number) {
    if (isYearly) {
      this.openServiceLog(false, isYearly, {
        monthOrQuarterOrYear: year,
        year: year,
      });
    }
    this.serviceLogDisplayStatus[index][year] =
      !this.serviceLogDisplayStatus[index][year];
  }

  openServiceLog(
    isMonth: boolean,
    isYearly: boolean,
    info: { monthOrQuarterOrYear: number; year: number }
  ) {
    const monthOrQuarterOrYearKey: string = isMonth
      ? 'month'
      : isYearly
        ? 'year'
        : 'quarter';

    this._getServiceLogByInfo({
      [monthOrQuarterOrYearKey]: info.monthOrQuarterOrYear,
      year: info.year,
    }).subscribe({
      next: serviceLogInfo => {
        (this.entityServiceLogForm.get('status') as FormArray).clear();

        this.serviceLogInfo = serviceLogInfo;
        serviceLogInfo.services.forEach(serviceLog => {
          (this.entityServiceLogForm.get('status') as FormArray).push(
            new FormGroup({
              [serviceLog?.id]: new FormControl(serviceLog.taskStatus),
            })
          );
        });
      },
    });
  }

  getMonthByNumber(isMonthly: boolean, number: any) {
    if (!(1 <= number && number <= 12)) return;

    return isMonthly
      ? MONTH_BY_NUMBER[number as keyof typeof MONTH_BY_NUMBER]
      : QUARTER_BY_NUMBER[number as keyof typeof QUARTER_BY_NUMBER];
  }

  entityServiceLogStatusControlById(
    index: number,
    entityServiceLogId: number
  ): FormControl {
    return (this.entityServiceLogForm.get('status') as FormArray)?.controls?.[
      index
    ]?.get(String(entityServiceLogId)) as FormControl;
  }

  updateEntityServiceLogStatus(
    index: number,
    entityServiceLogId: number
  ): void {
    const selectedServiceLog = (
      this.entityServiceLogForm.get('status') as FormArray
    )?.controls?.[index]?.get(String(entityServiceLogId));

    this._entitiesService
      .updateEntityServiceLog(entityServiceLogId, selectedServiceLog?.value)
      .subscribe();
  }

  addPeriod() {
    const { monthOrQuarter, year } = this.addPeriodForm.value;

    const periodicity = this.isMonthly
      ? 'monthly'
      : this.isYearly
        ? 'yearly'
        : 'quarterly';

    const startDate = this.isYearly
      ? new Date(`01/02/${+year}`)
      : new Date(`${this.isMonthly ? +monthOrQuarter : '01'}/02/${+year}`);

    const additionalFields = this.isMonthly ? {} : { quarter: +monthOrQuarter };

    const payload: Parameters<
      typeof this._entitiesService.addServiceLogPeriod
    >[0] = {
      entity: this.entityId,
      entity_service: +this.entityServiceId,
      service_periodicity: periodicity,
      start_date: startDate,
      ...additionalFields,
    };

    this._entitiesService
      .addServiceLogPeriod(payload)
      .pipe(switchMap(() => this._getServiceLog()))
      .subscribe({
        next: serviceLog => {
          this.serviceLogs = serviceLog;

          this.serviceLogDisplayStatus = Object.keys(this.serviceLogs).map(
            year => ({
              [year]: false,
            })
          );

          this.addPeriodModalStatus = MODAL_STATUS.CLOSE;
        },
      });
  }

  openAddPeriodModal() {
    this.addPeriodModalStatus = MODAL_STATUS.OPEN;

    this._initAddPeriodForm();
  }

  cancelAddPeriod() {
    this.addPeriodModalStatus = 'close';
  }

  override _completeUploadAndRefresh(observable$: Observable<any>) {
    this.deleteModalStatus = 'close';
    this.fileToDelete = null;

    return observable$
      .pipe(
        switchMap(() => {
          return this._getServiceLog();
        }),
        switchMap(_ => {
          if (!this._selectedItem?.year) return of();

          return this._getServiceLogByInfo({
            [this._selectedItem.month ? 'month' : 'quarter']:
              this._selectedItem.month ?? this._selectedItem.quarter,
            year: this._selectedItem.year,
          });
        })
      )
      .subscribe({
        next: serviceLogInfo => {
          this.serviceLogInfo = serviceLogInfo;

          const selectedItem = this.serviceLogInfo.services.find(
            (property: any) =>
              property.id === this._selectedItem?.property_id &&
              property.zone_id === this._selectedItem?.zone_id
          );

          if (!selectedItem) throw new Error('Undefined selected item');

          this._selectedItem = {
            property_id: selectedItem.id,
            zone_id: selectedItem.zoneId,
            uploader_id: String(selectedItem.uploaderId),
            shared_upload: +selectedItem.sharedUpload,
            month: selectedItem.month,
            quarter: selectedItem.quarter,
            year: selectedItem.year,
          };

          this.files = selectedItem.gr_uploads;
          this.hasLoading = false;
        },
        error: error => {
          this.hasLoading = false;
          this._errorHandler.handleError(error);
        },
      });
  }

  private _getServiceLog() {
    return this._entitiesService
      .getServiceLog(this.entityServiceId)
      .pipe(takeUntilDestroyed(this._destroyRef));
  }

  private _getServiceLogByInfo(
    logInfo: Parameters<typeof this._entitiesService.getServiceLogByInfo>[1]
  ) {
    return this._entitiesService
      .getServiceLogByInfo(this.entityServiceId, logInfo)
      .pipe(takeUntilDestroyed(this._destroyRef));
  }

  private _initEntityServiceLogForm() {
    return new FormGroup({
      status: new FormArray([]),
    });
  }

  private _initAddPeriodForm() {
    this.addPeriodForm = new FormGroup({
      ...(!this.isYearly && {
        monthOrQuarter: new FormControl(null, Validators.required),
      }),
      year: new FormControl(null, Validators.required),
    });
  }

  get isMonthly(): boolean {
    return !!Object.values(this.serviceLogs)
      .map(({ months, quarters }) => {
        return quarters.at(0) === 0 && months.at(0) !== 0;
      })
      .at(0);
  }

  get monthOrQuarterByNumber() {
    return this.isMonthly ? this.monthByNumber : this.quarterByNumber;
  }

  get isYearly(): boolean {
    return !!Object.values(this.serviceLogs)
      .map(({ months, quarters }) => {
        return months.at(0) === 0 && quarters.at(0) === 0;
      })
      .at(0);
  }
}
