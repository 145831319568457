import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequiredAsteriskComponent } from 'src/app/components/shared/required-asterisk/required-asterisk.component';
import { EditorComponent } from '../../../../editor/editor.component';
import { SwitchComponet } from '../../../../switch/switch.component';

@Component({
  standalone: true,
  selector: 'app-notification-form',
  templateUrl: './notification-form.component.html',
  imports: [
    EditorComponent,
    FormsModule,
    NgIf,
    SwitchComponet,
    ReactiveFormsModule,
    RequiredAsteriskComponent,
  ],
})
export class NotificationFormComponent implements OnInit {
  @Output() onEditorClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input() formGroup!: FormGroup;

  isSendNotificationActive: boolean = false;
  isExpectResponseActive: boolean = false;

  ngOnInit(): void {
    this.formGroup
      .get('sendNotification')
      ?.valueChanges.subscribe(sendNotification => {
        this.isSendNotificationActive = sendNotification;
      });

    this.isExpectResponseActive =
      !!!this.formGroup?.get('expectResponse')?.value;
  }

  editorClicked() {
    this.onEditorClicked.emit(true);
  }

  toggleSendNotification() {
    this.isSendNotificationActive = !this.isSendNotificationActive;
    this.formGroup
      .get('sendNotification')
      ?.setValue(this.isSendNotificationActive);
  }

  toggleExpectResponse() {
    this.isExpectResponseActive = !this.isExpectResponseActive;
    this.formGroup.get('expectResponse')?.setValue(this.isExpectResponseActive);
  }
}
