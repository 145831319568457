import {
  Component,
  DestroyRef,
  ErrorHandler,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, debounceTime, switchMap } from 'rxjs';
import { PAGE_TYPE } from 'src/app/components/shared/upload-files/enums/PageType.enum';
import { FileManagement } from 'src/app/components/shared/upload-files/helper/file-management';
import { PageType } from 'src/app/components/shared/upload-files/types/PageType';

@Component({
  selector: 'app-entity-service-properties',
  templateUrl: './entity-service-properties.component.html',
  styleUrls: ['./entity-service-properties.component.css'],
})
export class EntityServicePropertiesComponent
  extends FileManagement
  implements OnInit
{
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);
  private readonly _router: Router = inject(Router);
  private readonly _fb: FormBuilder = inject(FormBuilder);
  private readonly _errorHandler: ErrorHandler = inject(ErrorHandler);

  entityForm!: FormGroup;
  propertiesForm!: FormGroup;
  propertiesGroupe: any;
  private readonly _entityId: string =
    this._router.url.split('/')?.at(-4) ?? '';
  private readonly _entityServiceId: string =
    this._router.url.split('/')?.at(-2) ?? '';
  override pageType: PageType = PAGE_TYPE.PROPERTY_SERVICE;

  ngOnInit(): void {
    this._getEntityServiceCustomFields().subscribe({
      next: (entityService: any) => {
        this.propertiesGroupe = this.groupList(entityService);
      },
    });
  }

  getClass(propertiesCount: number, propertyIndex: number) {
    if (propertiesCount > 4) return 'w-1/2';
    return propertiesCount === 2 && propertyIndex === 0 ? 'w-2/3' : 'w-1/3';
  }

  groupList(data: any) {
    if (!data && !data.length) return [];

    const output: any = [];

    this.propertiesForm = this._fb.group({});

    data.data.forEach((item: any) => {
      let groupName = item.name;
      let group = output.find((group: any) => group.groupName === groupName);

      if (!group) {
        group = { groupName: groupName, propertiesList: [] };
        output.push(group);
      }

      item.gr_properties_to_services.forEach((prop: any) => {
        this.initPropertyForm(prop);
        let grProperty = prop.gr_property;
        let propertyInfo = {
          id: grProperty?.id,
          name: grProperty?.name,
          input_type: grProperty?.input_type,
          default_value: grProperty?.default_value,
          gr_entity_type_id: prop?.gr_entity_type_id,
          upload_support: grProperty?.upload_support,
          options: this.formatOptions(grProperty?.options),
          zone_id: prop.zone_id,
          uploader_id: prop.id,
          shared_upload: 0,
          gr_uploads: prop.gr_uploads,
          property_entity: prop.id,
        };

        group.propertiesList.push(propertyInfo);
      });
    });

    return output;
  }

  formatOptions(options: any) {
    return options?.map((option: any) => {
      return { id: option?.id, name: option?.select_option };
    });
  }

  initPropertyForm(property: any) {
    const control = this._fb.control(
      property?.gr_entity_service_properties[0]?.value || ''
    );
    let grProperty = property.gr_property;
    this.propertiesForm.addControl(grProperty?.name, control);
    control.valueChanges.pipe(debounceTime(500)).subscribe(val => {
      const change = {
        entity_id: this._entityId,
        property_id: grProperty?.id,
        prope_entity_type: property.id,
        value: val,
      };

      this.updateProperty(change);
    });
  }

  updateProperty(changes: any) {
    this._entitiesService
      .updateServicePropertyValue({
        ...changes,
        entity_service_id: this._entityServiceId,
      })
      .subscribe();
  }

  override _completeUploadAndRefresh(observable$: Observable<any>) {
    this.deleteModalStatus = 'close';
    this.fileToDelete = null;

    return observable$
      .pipe(
        switchMap(() => {
          return this._getEntityServiceCustomFields();
        })
      )
      .subscribe({
        next: groups => {
          this.propertiesGroupe = this.groupList(groups);

          const selectedItem = this.propertiesGroupe
            .flatMap((group: any) => group.propertiesList)
            .find(
              (property: any) =>
                property.id === this._selectedItem?.property_id &&
                property.zone_id === this._selectedItem?.zone_id
            );

          this._selectedItem = {
            property_id: selectedItem.id,
            zone_id: selectedItem?.zone_id || selectedItem.zoneId,
            uploader_id: String(
              selectedItem.uploader_id || selectedItem.uploaderId
            ),
            shared_upload:
              selectedItem.shared_upload ?? selectedItem.sharedUpload,
          };

          this.files = selectedItem?.gr_uploads;

          this.hasLoading = false;
        },
        error: error => {
          this.hasLoading = false;
          this._errorHandler.handleError(error);
        },
      });
  }

  private _getEntityServiceCustomFields() {
    const entityServiceId: number = +(this._router.url.split('/')?.at(-2) ?? 0);

    return this._entitiesService
      .getEntityServiceCustomFields(entityServiceId, this._entityId)
      .pipe(takeUntilDestroyed(this._destroyRef));
  }
}
