<div class="flex flex-col">
  <search
    [originalData]="activeData"
    [filteredData]="filteredData"
    [columnsToDisplay]="columnsToDisplay"
    (emitFilteredData)="setFilteredData($event)"></search>

  <table-basic
    (onEditClick)="navigateToEntity($event)"
    [editAction]="true"
    [tableData]="filteredData"
    [columnsToDisplay]="columnsToDisplay"></table-basic>
</div>
