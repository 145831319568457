<form [formGroup]="formGroup">
  <div [class]="'flex flex-' + flexDirection + ' gap-4'">
    <div
      *ngIf="!isYearly"
      [class]="'flex items-center flex-' + (flexDirection === 'row' && 'col')">
      <p class="w-[150px]">
        {{ isMonthly ? 'Month' : 'Quarter' }}
      </p>

      <select
        formControlName="monthOrQuarter"
        class="w-full border-slate-300 rounded-md placeholder-slate-400 focus:border-blueDS-400 focus:ring-1 focus:ring-blueDS-500 bg-white">
        <option selected disabled [value]="null">
          Select {{ isMonthly ? 'Month' : 'Quarter' }}
        </option>

        @for (monthOrQuarter of monthOrQuarter; track $index) {
          <option [value]="$index + 1">
            {{ monthOrQuarter }}
          </option>
        }
      </select>
    </div>

    <div
      [class]="'flex items-center flex-' + (flexDirection === 'row' && 'col')">
      <p class="w-[150px]">Year</p>

      <select
        formControlName="year"
        class="w-full border-slate-300 rounded-md placeholder-slate-400 focus:border-blueDS-400 focus:ring-1 focus:ring-blueDS-500 bg-white">
        <option selected disabled [value]="null">Select year</option>

        @for (year of years; track $index) {
          <option [value]="year">
            {{ year }}
          </option>
        }
      </select>
    </div>
  </div>
</form>
