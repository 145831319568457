import { Component, OnInit, inject } from '@angular/core';
import { Toast } from 'src/app/core/types/Toast.type';
import { ToasterComponent } from '../toaster-item/toaster.component';
import { ToasterService } from '../toaster-item/toaster.service';

@Component({
  standalone: true,
  selector: 'app-toaster-container',
  templateUrl: './toaster-container.component.html',
  imports: [ToasterComponent],
})
export class ToasterContainerComponent implements OnInit {
  private readonly _toastsService: ToasterService = inject(ToasterService);
  toasts: Toast[] = [];

  ngOnInit() {
    this._toastsService.toast$.subscribe(toast => {
      if (!toast) return;

      this.toasts = [toast, ...this.toasts];
      setTimeout(() => this.toasts.pop(), toast.delay ?? 6000);
    });
  }

  remove(index: number) {
    this.toasts = this.toasts.filter((_, toastsIndex) => toastsIndex !== index);
  }
}
