import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SwitchComponet } from '../../../../switch/switch.component';

@Component({
  standalone: true,
  selector: 'app-communications-notification-form',
  templateUrl: './communications-notification-form.component.html',
  imports: [SwitchComponet, ReactiveFormsModule],
})
export class CommunicationsFormComponent implements OnChanges {
  @Input() formGroup!: FormGroup;
  @Input() isSMS!: boolean;

  isSendNotificationActive: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    const isSMSChange = changes?.['isSMS']?.currentValue;

    if (!isSMSChange) {
      const emailControl = this.formGroup.get('sendNotificationAsEmail');
      if (emailControl?.value) {
        this.isSendNotificationActive = emailControl.value;
      } else {
        emailControl?.valueChanges.subscribe(value => {
          this.isSendNotificationActive = value;
        });
      }
    } else {
      const controlKey = isSMSChange
        ? 'sendNotificationAsSMS'
        : 'sendNotificationAsEmail';
      this.isSendNotificationActive = this.formGroup.get(controlKey)?.value;
    }
  }

  toggleSendNotification() {
    this.isSendNotificationActive = !this.isSendNotificationActive;
    this.formGroup
      .get(this.isSMS ? 'sendNotificationAsSMS' : 'sendNotificationAsEmail')
      ?.setValue(this.isSendNotificationActive);
  }

  get emailSubjectFormControl() {
    return this.formGroup.get('emailSubject') as FormControl;
  }
}
