<div aria-label="Progress">
  <ol role="list" class="flex items-center justify-center p-3">
    @for (step of steps; track $index) {
      <div>
        <li
          [class]="
            ($index !== steps.length - 1 ? 'pr-8 sm:pr-20' : '') + ' relative'
          ">
          @if (step.status === 'done') {
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="h-0.5 w-full bg-blueDS-600"></div>
            </div>
            <div
              class="relative flex h-8 w-8 items-center justify-center rounded-full bg-blueDS-600 hover:bg-blueDS-900">
              <span
                class="h-2.5 w-2.5 rounded-full bg-white"
                aria-hidden="true"></span>
              <span class="sr-only">{{ step.stepNumber }}</span>
            </div>
          } @else if (step.status === 'current') {
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="h-0.5 w-full bg-gray-200"></div>
            </div>
            <div
              class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-blueDS-600 bg-white"
              aria-current="step">
              <span
                class="h-2.5 w-2.5 rounded-full bg-blueDS-600"
                aria-hidden="true"></span>
              <span class="sr-only">{{ step.stepNumber }}</span>
            </div>
          } @else {
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="h-0.5 w-full bg-gray-200"></div>
            </div>
            <div
              class="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
              <span
                class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                aria-hidden="true"></span>
              <span class="sr-only">{{ step.stepNumber }}</span>
            </div>
          }
        </li>
        <p class="text-gray-500 mt-2 -ml-1">{{ step.title }}</p>
      </div>
    }
  </ol>
</div>
