@for (entityNote of entityNotes; track $index) {
  <div
    class="mb-3 p-3 rounded-xl font-light"
    [ngClass]="{
      'bg-blueDS-500 text-gray-50': isFromConnectedUser(entityNote),
      'bg-gray-100 text-gray-700': !isFromConnectedUser(entityNote),
    }">
    <h3 class="flex justify-between">
      <div>
        <strong
          >{{ entityNote.added_by.first_name }}
          {{ entityNote.added_by.last_name }}</strong
        >
        <span
          class="ml-1 text-sm"
          [ngClass]="{
            'text-gray-200': isFromConnectedUser(entityNote),
            'text-gray-400': !isFromConnectedUser(entityNote),
          }">
          {{ entityNote.created_at | date: 'MMM dd, yyyy' }}
        </span>
      </div>
      @if (isFromConnectedUser(entityNote)) {
        <div class="flex gap-3">
          <app-icon-button
            iconClass="icon-pencil "
            [hasPadding]="false"
            textColor="text-gray-100"
            (click)="editNote(entityNote)" />
          <app-icon-button
            iconClass="icon-trash "
            [hasPadding]="false"
            textColor="text-gray-100"
            (click)="deteleNote(entityNote)" />
        </div>
      }
    </h3>
    <p
      class="truncate"
      [ngClass]="{
        'text-gray-50': isFromConnectedUser(entityNote),
        'text-gray-600': !isFromConnectedUser(entityNote),
      }">
      {{ entityNote.content }}
    </p>
  </div>
} @empty {
  <app-not-found class="mt-8" label="No result."></app-not-found>
}
